import { AgGridReact } from 'ag-grid-react'
import React, { useEffect, useState } from 'react'
import { veiwPayer } from './configuration/PayerTableConfiguration';
import { failedApiReq } from '../../utils/reusableFunctions';
import { get_payersdata } from '../../services/PayerService';
import { Box } from '@mui/material';
import { sharedStyles } from '../../sharedStyles';
import UseWidth from '../../custom-hooks/UseWidth';



const PayersDetail = ({filterSelection}) => {
    const [noDataFound, setNoDataFound] = useState(false);
    const { width } = UseWidth();

    const columnDefs = () => {
        return [
            { headerName: 'Payer Code', field: 'Payer Code', width: 130, filter: true, resizable: false },
            { headerName: 'Payer Name', field: 'Payer Name', width: 251, filter: true , resizable: false},
            { headerName: 'Type', field: 'Type', width: 100, filter: true, resizable: false },
            { headerName: 'Claim Status', field: 'Claim Status', width: 150, filter: true, resizable: false },
            { headerName: 'Column 1', field: 'Column 1', width: 150, filter: true, resizable: false },
            { headerName: 'Eligibility', field: 'Eligibility', width: 150, filter: true, resizable: false },
            { headerName: 'Action', field: 'Action',flex:1, minWidth: 143, cellRenderer: veiwPayer, resizable: false },
        ];
    };
    const [gridApi, setGridApi] = useState(null);
    const perPage = 10;
  
    const onGridReady = (params) => {
      setGridApi(params.api);
    };
  
    useEffect(() => {
      if (gridApi) {
        setNoDataFound(false)
        const dataSource = {
          getRows: async(params) => {
            const page = params.endRow / perPage;
            try{
              gridApi.showLoadingOverlay();
            const dataArray = await get_payersdata(page,perPage, filterSelection);
            console.log(dataArray)
            if(dataArray){
                const rowData = dataArray?.data?.length && dataArray?.data?.map(({ payerCode, type, eligibility, claimStatus, column1, payerName }) => ({
                'Payer Code': payerCode || 'N/A',
                'Payer Name': payerName || 'N/A',
                'Type': type || 'N/A',
                Eligibility: eligibility || 'N/A',
                "Claim Status": claimStatus || 'N/A',
                "Column 1": column1 || 'N/A',
                Action: ''
            }))
            setNoDataFound(false)
            params.successCallback(rowData, dataArray.totalCount);
            }
            else{
              setNoDataFound(true)
                // toast.error(noDataInTableStr)
            }
            }
            catch(e){
              params.successCallback([], 0);
              failedApiReq(e)
            }
            finally{
              gridApi.hideOverlay();
            }
          }
        }
  
        gridApi.setDatasource(dataSource);
      }
    }, [gridApi, filterSelection]);

    const gridOptions = {
      pagination: true,
      paginationPageSize: width <= 900 ? 20 : 10,
      domLayout: 'paginationPrevNext,paginationPageSize,paginationFirstLast',
  };

    return (
        <Box className="ag-theme-quartz"
            sx={sharedStyles?.responsiveTableHeight}>
             {
             noDataFound ? 
             <div>0 records found</div> 
             :
             <AgGridReact
                    pagination={true}
                    rowModelType={'infinite'}
                    paginationPageSize={perPage}
                    cacheBlockSize={perPage}
                    onGridReady={onGridReady}
                    rowHeight={60}
                    gridOptions={gridOptions}
                    columnDefs={columnDefs()}
                    overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Loading...</span>'}
                />}
        </Box>
    )
}

export default PayersDetail;
