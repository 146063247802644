import { AgGridReact } from 'ag-grid-react'
import React, { useEffect, useState } from 'react'
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { sharedStyles } from '../../sharedStyles';
import ScheduledActions from './GridCellsRenderers/ScheduledActions';
import { getAllScheduledTransactionsDetailsClaims, processClaimsTransaction } from '../../services/ClaimService';
import { failedApiReq } from '../../utils/reusableFunctions';
import { dayJsMdyFormat } from '../../utils/DateFunctions';
import { processPatientsClaimsRecords } from './ClaimFunctions';
import { useClaimsTable } from '../../utils/contexts/ClaimsTableContext';
import PatientInsuranceStatus from '../../components/cellRenderers/PatientInsuranceStatus';

const ScheduledTransactionsTableUnPaginated = ({ action, cancelTask, isChecking, checkTaskNow, taskToBeHandled, setTaskToBeHandled, isCancelling, refetchApi, setNoDataInTable }) => {

  const [gridApi, setGridApi] = useState(null);
  const [scheduledTransactions, setScheduledTransactions] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [runProcessing, setRunProcessing] = useState(false)

  const { setRefetchClaimsTable } = useClaimsTable();

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    if (gridApi) {
      const dataSource = {
        getRows: async (params) => {
          try {
            gridApi.showLoadingOverlay();
            const data = await getAllScheduledTransactionsDetailsClaims();
            if (data?.data?.length) {
              const dataArray = data?.data?.map(({
                scheduleTaskData: { scheduleDate, status, id, requestParameters },
                patientData: { firstName, lastName, phone, id: patientId }
              }) => {
                return (
                  {
                    scheduleDate: dayJsMdyFormat(scheduleDate) ?? '---',
                    patient: firstName + ' ' + lastName,
                    phone,
                    status,
                    id,
                    patientId,
                    requestParameters
                  }
                )
              })
              params.successCallback(dataArray, data.count);
              setScheduledTransactions(dataArray)
              setRunProcessing(true)
              setNoDataInTable(false)
            }
            else {
              params.successCallback([], 0);
              setNoDataInTable(true)
            }
          }
          catch (e) {
            params.successCallback([], 0);
            failedApiReq(e)
          }
          finally {
            gridApi.hideOverlay();
          }
        }
      }

      gridApi.setDatasource(dataSource);
    }
  }, [gridApi, refetchApi]);

  useEffect(() => {
    if (!gridApi) return;
    const filteredTransactions =
      scheduledTransactions?.length > 0
        ? scheduledTransactions.filter((elem) => elem?.processStatus !== 'Completed')
        : [];
    const dataSource = {
      getRows: async (params) => {
        const { startRow, endRow } = params;

        try {
          gridApi.showLoadingOverlay();

          const pageData = filteredTransactions?.slice(startRow, endRow);
          params.successCallback(pageData, filteredTransactions.length);
          setNoDataInTable(filteredTransactions?.length ? false : true)
        } catch (e) {
          setNoDataInTable(true)
          params.successCallback([], 0);
          failedApiReq(e);
        } finally {
          gridApi.hideOverlay();
        }
      },
    };

    gridApi.setDatasource(dataSource);
  }, [scheduledTransactions, gridApi]);

  useEffect(() => {
    runProcessing && processPatientsClaimsRecords(
      scheduledTransactions,
      setScheduledTransactions,
      setIsLoading,
      () => { setRefetchClaimsTable(prev => prev + 1) },
      setRefetchClaimsTable
    )
  }, [runProcessing])

  const columnDefs = () => {
    return [
      { headerName: 'Schedule Date', field: 'scheduleDate', width: 130, resizable: false },
      { headerName: 'Patient', field: 'patient', sortable: true, filter: true, width: 236, resizable: false },
      { headerName: 'Phone', field: 'phone', sortable: true, filter: true, width: 200, resizable: false },
      {
        headerName: 'Status', field: 'status', sortable: true, filter: true, width: 102, resizable: false,
        cellRenderer: (params) => {
          if (params?.data) {
            return (
              PatientInsuranceStatus({
                data:
                  scheduledTransactions[params?.rowIndex]
              },
                scheduledTransactions)
            )

          }
          else return <div></div>

        }
      },
      {
        headerName: 'Actions', field: 'actions', resizable: false,
        flex: 1, minWidth: 143, cellRenderer: (params) => {
          if (params?.data) {
            return (
              ScheduledActions(params, () => action(params), () => cancelTask(params), isChecking, () => checkTaskNow(params), setTaskToBeHandled, taskToBeHandled, isCancelling)
            )

          }
          else return <div></div>
        }
      },
    ]
  }
  return (
     <div className="ag-theme-quartz" style={sharedStyles?.h500}  >
      <AgGridReact
        pagination={true}
        rowModelType={'infinite'}
        onGridReady={onGridReady}
        rowHeight={60}
        defaultColDef={{ flex: 1, minWidth: 170 }}
        columnDefs={columnDefs()}
        overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Loading...</span>'}
      />

    </div>
  )
}

export default ScheduledTransactionsTableUnPaginated