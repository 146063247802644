import React, { useEffect, useState } from 'react';
import {
    Grid,
    Paper,
    Typography,
    Box,
} from '@mui/material';
import toast from 'react-hot-toast';
import PageLoader from '../../../../loader/PageLoader';
import CommonNextButton from '../../components/CommonNextButton';
import ServiceInfoList from '../../components/ServicesInfoList';
import CptTable from '../../../../table/CptTable';
import {styles} from '../../Aetna/styles'
import { sharedStyles } from '../../../../../sharedStyles';

const DefaultAetnaExtendedProceduresForm = ({
    title,
    fetchData,
    selectedData,
    setSelectedData,
    maxSelections = 5,
    onNext,
    setSelectedTab,
    onceCompleted,
    errors,
    showDates,
    showServices,
    showQualifierCode,
    notReqFields,
    showTimeFields,
    quantityTypeOps,
    helperTexts,
    defaultMinDate,
    defaultMaxDate,
    qualifierCodes,
    regexFieldNames
}) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
  

    // useEffect(() => {
    //     const fetchCodes = async () => {
    //         try {
    //             setIsLoading(true);
    //             const result = await fetchData();
    //             if (result) {
    //                 setData(result);
    //             } else {
    //                 setData([]);
    //             }
    //         } catch (error) {
    //             console.error(error);
    //         } finally {
    //             setIsLoading(false);
    //         }
    //     };

    //     fetchCodes();
    // }, []);

    const handleRowSelection = (rowData) => {
        setSelectedData((prev) => {
            const isDuplicate = prev.some((item) => item.id === rowData.id);
            if (isDuplicate) {
                toast.error('This item is already selected');
                return prev;
            }

            if (prev.length < maxSelections) {
                return [...prev, rowData];
            } else {
                toast.error(`Select only up to ${maxSelections} items`);
                return prev;
            }
        });
    };

    const handleRemove = (idToRemove) => {
        setSelectedData((prev) => {
            const updated = prev.filter((item) => item.id !== idToRemove);
            if (updated.length !== prev.length) {
            }
            return updated;
        });
    };

    return (
        <Paper elevation={3} sx={styles?.parentPaperContainer}>
            <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
                {title}
            </Typography>

            <Box sx={styles?.commonPaperContainer}>
                {isLoading ? (
                    <PageLoader />
                ) : (
                    <>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <CptTable 
                                // data={data}
                                 onRowSelected={handleRowSelection} />
                            </Grid>
                        </Grid>

                        {selectedData?.length ? (
                            <ServiceInfoList
                                procedure
                                serviceInfoArray={selectedData}
                                onRemove={handleRemove}
                                errors={errors}
                                setServiceInfoArray={setSelectedData}
                                showDates={showDates}
                                showServices={showServices}
                                showQualifierCode={showQualifierCode}
                                notReqFields={notReqFields}
                                showTimeFields={showTimeFields}
                                quantityTypeOps={quantityTypeOps}
                                helperTexts={helperTexts}
                                defaultMinDate={defaultMinDate}
                                defaultMaxDate={defaultMaxDate}
                                qualifierCodes={qualifierCodes}
                                regexFieldNames={regexFieldNames}
                            />
                        ) : null}
                    </>
                )}
            </Box>

            <CommonNextButton handleSubmit={onNext} setSelectedTab={setSelectedTab} onceCompleted={onceCompleted}/>
        </Paper>
    );
};

export default DefaultAetnaExtendedProceduresForm;
