import React, { useContext, useState } from 'react'
import ImpDatesForm from '../../components/CommonDatesForms.jsx/ImpDatesForm'
import { AnthemGaHsContext } from '../../../../../context/priorAuthorizationForms/AnthemGaHsContext'
import { bcbsServiceTypes, impServiceTypesForBcbsHomeHealthDate, paFormErrors } from '../../../../../utils/staticData'
import dayjs from 'dayjs'
import { BcbsIllinoisHsContext } from '../../../../../context/priorAuthorizationForms/BcbsIllinoisContext'

const DatesInfoBcbsIllinoisHsForm = () => {
    const today = dayjs();
    const maxDate = today.add(365, 'day');
    const minDate = today.subtract(7,'day')
    const minHomeHealthDate = dayjs('01/01/2002')
    const maxHomeHealthDate= today.add(18,'month')
    const [dateLimits] = useState({ min: minDate, max: maxDate,minHomeHealthDate ,maxHomeHealthDate})
    const { datesInfo: formData, setDatesInfo: setFormData, setSelectedTab, onceCompleted,serviceType } = useContext(BcbsIllinoisHsContext)
    const [errors, setErrors] = useState({ fromDate: false, toDate: false })
    const servicTypeCode = bcbsServiceTypes?.find(e => e?.value === serviceType?.serviceType)?.code
    const showHomeHealthDate =  impServiceTypesForBcbsHomeHealthDate?.find(e => e === servicTypeCode)

    const handleSubmit = (submit) => {
        const { toDate, fromDate, homeHealthStartDate } = formData
        if (!toDate || !fromDate || (showHomeHealthDate && !homeHealthStartDate)) {
            setErrors({
                fromDate: fromDate ? false : paFormErrors?.reqField,
                toDate: toDate ? false : paFormErrors?.reqField,
                homeHealthStartDate: showHomeHealthDate && !homeHealthStartDate ? paFormErrors?.reqField : false
            })
            return
        }
        else {
            setErrors({
                fromDate: false,
                toDate: false,
                homeHealthStartDate: false
            })
            submit && onceCompleted ? setSelectedTab(onceCompleted) : setSelectedTab((prev) => prev + 1)
        }

    }

    return (
        <ImpDatesForm
            handleSubmit={handleSubmit}
            dateLimits={dateLimits}
            formData={formData}
            setFormData={setFormData}
            errors={errors}
            onceCompleted={onceCompleted}
            setSelectedTab={setSelectedTab}
            dateLabels={{toDate:'To Date',fromDate:'From Date'}}
            showHomeHealthDate={showHomeHealthDate}
        />
    )
}

export default DatesInfoBcbsIllinoisHsForm