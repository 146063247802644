
import React, { useEffect, useState } from 'react'
import BoldText from '../../components/Texts/BoldText'
import TableHeader from '../../components/TableHeader';
import { checkScheduleTaskNow, filterRowData, getScheduledTransactions, handleAction, handleCancelTask } from './ClaimFunctions';
import ScheduledTransactionsTable from './ScheduledTransactionsTable';
import dayjs from 'dayjs';
import FieldEditModal from '../../components/modals/FieldEditModal'
import { sharedStyles } from '../../sharedStyles';
import { Box, Button } from '@mui/material';
import HorizontalBox from '../../components/shared/HorizontalBox';
import ScheduledTransactionsTableUnPaginated from './ScheduledTransactionsTableUnPaginated';

const ClaimScheduledTransactions = () => {
  const [rowData, setRowData] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false)
  const [newScheduleDate, setNewScheduleDate] = useState('')
  const [defaultScheduleDate, setDefaultScheduleDate] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [idToEdit, setIdToEdit] = useState('')
  const [isEditing, setIsEditing] = useState(false)
  const [isChecking, setIsChecking] = useState(false)
  const [taskToBeHandled, setTaskToBeHandled] = useState('')
  const [isCancelling, setIsCancelling] = useState(false)
  const [filterPatient, setFilterPatient] = useState('')
  const [refetchApi,setRefetchApi] = useState(false)
  const [noDataInTable, setNoDataInTable] = useState(false)
  const [showAllDataTable,setShowAllDataTable] = useState(false)

  return (
    <div>
      <Box sx={sharedStyles?.bm1em}>
      <BoldText text={`Scheduled Transactions ${noDataInTable ? '(0 records found)' : ''}`}/>
      </Box>
      <TableHeader setFilterPatient={setFilterPatient}/>
      {
        !showAllDataTable ? 
        <ScheduledTransactionsTable
       action={({data})=>{
         setIdToEdit(data?.id)
         setShowEditModal(true)
         setNewScheduleDate(dayjs(data?.scheduleDate))
         setDefaultScheduleDate(dayjs(data?.scheduleDate))
       }}
       cancelTask={async({data}) => await handleCancelTask(data?.id,setRowData,setIsCancelling,setRefetchApi,refetchApi)}
       checkTaskNow={async({data}) => await checkScheduleTaskNow(data?.patientId,setIsChecking,setRowData,setIsLoading,data?.requestParameters,setRefetchApi,refetchApi)}
       isLoading={isLoading}
       isChecking={isChecking}
       setTaskToBeHandled={setTaskToBeHandled}
       taskToBeHandled={taskToBeHandled}
       isCancelling={isCancelling}
       refetchApi={refetchApi}
       setNoDataInTable={setNoDataInTable}
      />
      :
       <ScheduledTransactionsTableUnPaginated
       action={({data})=>{
         setIdToEdit(data?.id)
         setShowEditModal(true)
         setNewScheduleDate(dayjs(data?.scheduleDate))
         setDefaultScheduleDate(dayjs(data?.scheduleDate))
       }}
       cancelTask={async({data}) => await handleCancelTask(data?.id,setRowData,setIsCancelling,setRefetchApi,refetchApi)}
       checkTaskNow={async({data}) => await checkScheduleTaskNow(data?.patientId,setIsChecking,setRowData,setIsLoading,data?.requestParameters,setRefetchApi,refetchApi)}
       isLoading={isLoading}
       isChecking={isChecking}
       setTaskToBeHandled={setTaskToBeHandled}
       taskToBeHandled={taskToBeHandled}
       isCancelling={isCancelling}
       refetchApi={refetchApi}
       setNoDataInTable={setNoDataInTable}
      />}
      <FieldEditModal
      open={showEditModal}
      handleClose={()=>setShowEditModal(false)}
      fiedlName={'Schedule Date'}
      action={async()=> await handleAction(setShowEditModal,setNewScheduleDate,setDefaultScheduleDate,setRowData,idToEdit,newScheduleDate,setIsLoading,setIsEditing,setRefetchApi,refetchApi)}
      value={newScheduleDate}
      handleChange={(value)=>{ setNewScheduleDate(value)}}
      defaultValue={defaultScheduleDate}
      isLoading={isEditing}
      calendar
      /> 
      {
         !noDataInTable ?
            <HorizontalBox sx={sharedStyles?.justifyEnd}>
              <Button
            variant='contained'
            color='success'
            sx={sharedStyles?.mt10px}
            disabled={isLoading}
            onClick={() => setShowAllDataTable(true)}
          >
            PROCESS
          </Button>
            </HorizontalBox>
          :
          null
          }
    </div>
  )
}

export default ClaimScheduledTransactions