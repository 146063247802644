import toast from "react-hot-toast"
import { cancelScheduledTask, checkScheduleTask, downloadCsvTemplate, editScheduleDate, get_GetAllInsuranceDiscoveryDataPaginated, getAllScheduledTransactionsDetails, getNpiState, post_discoverysearchdata, processInsuranceRecord, readDiscoveryCsv, refreshDiscoveryDetails, validateDiscoveryCsvFile } from "../../services/registration"
import { succesfullReqStr, succesfulScheduleDateUpdationStr, successfullyDeletedTaskStr } from "../../utils/staticData"
import { get_GetAllPatientsDataAsync } from "../../services/addPatient";
import { getLocalData, setLocalData } from "../../utils/localStorageFunctions";
import {get_GetInusuranceDiscoveryByPatientId, get_GetPatientInsuranceDetailsById} from '../../services/registration'
import dayjs from "dayjs";
import { dayJsMdyFormat, ydmDateFormat, ymdDateFormat } from "../../utils/DateFunctions";
import { capitalizedFirstLetter, checkAllVals, checkAllValsIfEqual, DownloadCSV, failedApiReq, failedGetApi, parseCSVDataFromBase64 } from "../../utils/reusableFunctions";

const npiInfo = getLocalData('selectedNpi');

export const fetchSearchData = async (setSuggestions,setIsSuggestionsLoading,setInput) => {
    try {
      setIsSuggestionsLoading(true)
      const patientdata = await get_GetAllPatientsDataAsync(npiInfo?.id);
      if(patientdata?.data?.length) {
      const flattenedDataObjList = patientdata?.data?.map(
        ({firstName,lastName,dob,id, middleName}) =>{
          return ({
          firstName,
          lastName,
          dob,
          id,
          middleName
        })}
      );
      setSuggestions(flattenedDataObjList)
      setInput('')
    }
    } catch (error) {
      console.error("Error fetching patient data:", error);
    }
    finally{
      setIsSuggestionsLoading(false)
    }
  };

export const handleSuggestionToShow = (event,value, setSuggestionsToShow,suggestions,setInput) => {
  setInput(value)
  const results = suggestions?.filter((patient) => {
    return (
      value &&
      patient?.firstName &&
      patient?.lastName &&
      patient?.dob &&
      (patient?.firstName?.toLowerCase()?.includes(value?.toLowerCase()) ||
        patient?.lastName?.toLowerCase()?.includes(value?.toLowerCase()) ||
        patient?.dob?.toLowerCase()?.includes(value?.toLowerCase())
      )
      
        // `${patient?.firstName?.toLowerCase()} ${patient?.lastName?.toLowerCase()} - ${patient?.dob?.toLowerCase()}`?.includes(value?.toLowerCase())
    );
  });
  setSuggestionsToShow(results);
}

export const handleScheduleTask = async (newValue,setUserDetails) => {
  if (newValue) {
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      scheduleDate: newValue,
    }));
  }
};

export const fetchPatientData = async (rowData,setDetailData,setIsLoading) => {
  try {
    const idToUse = rowData?.PatientId || rowData?.ID
    if (rowData && idToUse) {
      setIsLoading(true)
      const data = await get_GetPatientInsuranceDetailsById(idToUse);
      setDetailData(data);
      setIsLoading(false)
    }
  } catch (error) {
    setIsLoading(false)
    console.error('Error fetching data:', error);
  }
};

export const getTabNameLabel = (tabname) => {
  switch (tabname) {
    case "detail":
      return "Plan Details";
    case "coverage":
      return "Coverage Details";
    case "DemographicInfo":
      return "DemographicInfo Details";
    case "Innetwork":
      return "In Network";
    case "Outofnetwork":
      return "Out of Network";
    case "Speciality":
      return "Speciality";
    default:
      return "";
  }
};

export const formatPatientInsuranceData = (patientInsuranceTabData) => {
  const searchStatus = patientInsuranceTabData?.status &&  patientInsuranceTabData?.status?.toString()?.toLowerCase() === 'active coverage found' ? patientInsuranceTabData?.status :  patientInsuranceTabData?.status && patientInsuranceTabData?.status !== 'active coverage found' ? `Saved - ${patientInsuranceTabData?.status}` : 'N/A'
  const arrayToList = [
    { heading: 'Status', text: searchStatus },
    { heading: 'Effective Date', text: dayJsMdyFormat(patientInsuranceTabData?.effectiveDate ) || 'N/A' },
    { heading: 'Expiry Date', text: dayJsMdyFormat(patientInsuranceTabData?.expiryDate) || 'N/A' },
    { heading: 'Plan Name', text: patientInsuranceTabData?.planName || 'N/A' },
    { heading: 'Policy Type', text: patientInsuranceTabData?.policyType || 'N/A' },
    { heading: 'Group Number', text: patientInsuranceTabData?.groupNumber || 'N/A' },
    { heading: 'Plan Network ID', text: patientInsuranceTabData?.planNetworkID || 'N/A' }
  ]
  return arrayToList
}

export const formatPatientCoverageData = (patientInsuranceDetailTabData) => {
  return [
    { heading: 'In Net HBPC Authorization Info', text: patientInsuranceDetailTabData?.inNetHBPCAuthorizationInfo || 'N/A' },
    { heading: 'Primary Care Provider Name', text: patientInsuranceDetailTabData?.primaryCareProviderName || 'N/A' },
    { heading: 'Primary Care Provider Phone Number', text: patientInsuranceDetailTabData?.primaryCareProviderPhoneNumber || 'N/A' },
    { heading: 'Out Net HBPC Authorization Info', text: patientInsuranceDetailTabData?.outNetHBPCAuthorizationInfo || 'N/A' }
  ];
};

export const formatDemographicInfo = (patientInsuranceDetailTabData) => {
  const demographicInfo = patientInsuranceDetailTabData
  return [
    { heading: 'Policy Type', text: demographicInfo?.policyType || 'N/A' },
    { heading: 'Authorization/Certification Required', text: demographicInfo?.authorizationOrCertificationRequired || 'N/A' },
    { heading: 'Plan Network ID Number', text: demographicInfo?.planNetworkID || 'N/A' },
    { heading: 'Full Name', text: demographicInfo?.fullName || 'N/A' },
    { heading: 'Address', text: demographicInfo?.address1 || 'N/A' },
    { heading: 'City', text: demographicInfo?.city || 'N/A' },
    { heading: 'State', text: demographicInfo?.state || 'N/A' },
    { heading: 'Zip', text: demographicInfo?.zip || 'N/A' },
    { heading: 'Uniform Resource Locator (URL)', text: demographicInfo?.detailsURL || 'N/A' },
    { heading: 'Payor ID', text: demographicInfo?.payerId || 'N/A' }
  ];
};

export const formatInNetworkDetails = (patientInsuranceDetailTabData) => {
  const inNetworkDetails = patientInsuranceDetailTabData;

  return [
    { heading: 'Individual Deductible In-Net', text: inNetworkDetails?.individualDeductibleInNet?.value || inNetworkDetails?.individualDeductibleInNet || 'N/A' },
    { heading: 'Individual Deductible Remaining In-Net', text: inNetworkDetails?.individualDeductibleRemainingInNet?.value || inNetworkDetails?.individualDeductibleRemainingInNet || 'N/A' },
    { heading: 'Family Deductible In-Net', text: inNetworkDetails?.familyDeductibleInNet?.value || inNetworkDetails?.familyDeductibleInNet || 'N/A' },
    { heading: 'Family Deductible Remaining In-Net', text: inNetworkDetails?.familyDeductibleRemainingInNet?.value || inNetworkDetails?.familyDeductibleRemainingInNet || 'N/A' },
    { heading: 'Individual OOP In-Net', text: inNetworkDetails?.individualOOP_InNet?.value || inNetworkDetails?.individualOOP_InNet || 'N/A' },
    { heading: 'Individual OOP Remaining In-Net', text: inNetworkDetails?.individualOOPRemainingInNet?.value || inNetworkDetails?.individualOOPRemainingInNet || 'N/A' },
    { heading: 'Family OOP In-Net', text: inNetworkDetails?.familyOOPInNet?.value || inNetworkDetails?.familyOOPInNet || 'N/A' },
    { heading: 'Family OOP Remaining In-Net', text: inNetworkDetails?.familyOOPRemainingInNet?.value || inNetworkDetails?.familyOOPRemainingInNet || 'N/A' }
  ];
};

export const formatOutOfNetworkDetails = (patientInsuranceDetailTabData) => {
  const outOfNetworkDetails = patientInsuranceDetailTabData
  return [
    { heading: 'Individual Deductible out-Net', text: outOfNetworkDetails?.individualDeductibleOutNet?.value || outOfNetworkDetails?.individualDeductibleOutNet || 'N/A' },
    { heading: 'Individual Deductible Remaining out-Net', text: outOfNetworkDetails?.individualDeductibleRemainingOutNet?.value || outOfNetworkDetails?.individualDeductibleRemainingOutNet || 'N/A' },
    { heading: 'Family Deductible out-Net', text: outOfNetworkDetails?.familyDeductibleOutNet?.value || outOfNetworkDetails?.familyDeductibleOutNet || 'N/A' },
    { heading: 'Family Deductible Remaining out-Net', text: outOfNetworkDetails?.familyDeductibleRemainingOutNet?.value || outOfNetworkDetails?.familyDeductibleRemainingOutNet || 'N/A' },
    { heading: 'Individual OOP out-Net', text: outOfNetworkDetails?.individualOOP_OutNet?.value || outOfNetworkDetails?.individualOOP_OutNet || 'N/A' },
    { heading: 'Individual OOP Remaining out-Net', text: outOfNetworkDetails?.individualOOPRemainingOutNet?.value || outOfNetworkDetails?.individualOOPRemainingOutNet || 'N/A' },
    { heading: 'Family OOP out-Net', text: outOfNetworkDetails?.familyOOPOutNet?.value || outOfNetworkDetails?.familyOOPOutNet || 'N/A' },
    { heading: 'Family OOP Remaining out-Net', text: outOfNetworkDetails?.familyOOPRemainingOutNet?.value || outOfNetworkDetails?.familyOOPRemainingOutNet || 'N/A' }
  ];
};

export const getFilteredDetails = (eligibilityDetails, type) => {
  if (!eligibilityDetails?.length) return [];
  return eligibilityDetails.filter(detail => {
    if (type === 'inPlan') {
      return detail?.planNetworkIndicator === 'Yes';
    } else if (type === 'outPlan') {
      return detail?.planNetworkIndicator === 'No';
    } else if (type === 'both') {
      return detail?.planNetworkIndicator === 'Both';
    }
    return false;
  });
};

export const fetchDiscoveryDetails = async (rowData,setsearchdata,setTabData,setDiscoveryLoading) => {
  try {
    setDiscoveryLoading(true)
    const idToUse = rowData?.TableId || rowData?.ID
    if (rowData && idToUse) {
      let Patient_id = idToUse;
      const data = await get_GetInusuranceDiscoveryByPatientId(Patient_id);
      let planSummaryData = [];
      planSummaryData = data?.insuranceDiscoveryDto.planCoverageSummary;
      const deductibleData = data?.insuranceDiscoveryDto?.hbpC_Deductible_OOP_Summary?.individualDeductibleRemainingInNet?.value || data?.insuranceDiscoveryDto?.hbpC_Deductible_OOP_Summary?.individualDeductibleRemainingInNet;
      const requestId = data?.insuranceDiscoveryDto?.requestID ;
      const searchStatus = data?.insuranceDiscoveryDto?.searchStatus &&  data?.insuranceDiscoveryDto?.searchStatus?.toString()?.toLowerCase() === 'active coverage found' ? data?.insuranceDiscoveryDto?.searchStatus :  data?.insuranceDiscoveryDto?.searchStatus && data?.insuranceDiscoveryDto?.searchStatus !== 'active coverage found' ? `Saved - ${data?.insuranceDiscoveryDto?.searchStatus}` : 'Saved - No Status Found'
      setsearchdata({
        ...planSummaryData, deductibleData,requestId,
        memberId: data?.insuranceDiscoveryDto?.demographicInfo?.subscriber?.identification?.find(elem => elem?.type === 'Member ID')?.code,
        otherPayerInfo:data?.insuranceDiscoveryDto?.otherPayerInfo?.payer  || 'No Insurance Found',
        firstNameToDisplay:checkAllValsIfEqual(data?.insuranceDiscoveryDto?.demographicInfo?.subscriber?.firstname,data?.insuranceDiscoveryDto?.patientData?.firstName),
        lastNameToDisplay:data?.insuranceDiscoveryDto?.patientData?.lastName,
        searchStatus:searchStatus,
        isRefreshable:data?.insuranceDiscoveryDto?.isRefreshable,
        payerName:data?.insuranceDiscoveryDto?.payerName,
        status:data?.insuranceDiscoveryDto?.status
        
      });
      setTabData({...data,rowDataPatientId:rowData?.ID})
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
  finally{
    setDiscoveryLoading(false)
  }
};

export const getScheduledTransactions = async (setRowData,setIsLoading) => {
  try {
    setIsLoading && setIsLoading(true)
    const transactionsData = await getAllScheduledTransactionsDetails()
    if (transactionsData?.data?.length) {
      const dataNeeded = transactionsData?.data?.map(({
        scheduleTaskData: { scheduleDate, status, id },
        patientData: { firstName, lastName, phone,id:patientId }
      }) => {
        return (
          {
            scheduleDate,
            patient: firstName + ' ' + lastName,
            phone,
            status,
            id,
            patientId
          }
        )
      })
      setRowData(dataNeeded)
    }
  }
  catch (e) {
    console.log(e)
  }
  finally {
    setIsLoading && setIsLoading(false)
  }
}

export const handleAction = async(setShowEditModal,setNewScheduleDate,setDefaultScheduleDate,id,newScheduleDate,setIsEditing,setRefetchApi,refetchApi) => {
   try{ 
    setIsEditing(true)
    const editData = await editScheduleDate(id,ymdDateFormat(dayjs(newScheduleDate))?.toString())
    if(editData?.isSuccessful){
      toast?.success(succesfulScheduleDateUpdationStr)
      clearData(setShowEditModal,setNewScheduleDate,setDefaultScheduleDate,setRefetchApi,refetchApi)
    }}
    catch(e){
      console.log(e)
    }
    finally{
      setIsEditing(false)
    }
  }


const clearData = (setShowEditModal,setNewScheduleDate,setDefaultScheduleDate,setRefetchApi,refetchApi) => {
    setShowEditModal(false)
    setNewScheduleDate('')
    setDefaultScheduleDate('')
    setRefetchApi(!refetchApi)
}

export const handleCancelTask = async (id, setIsCancelling,setRefetchApi,refetchApi) => {
  try {
    setIsCancelling(true)
    const cancellingTask = await cancelScheduledTask(id)
    if (cancellingTask?.isSuccessful) {
      toast?.success(successfullyDeletedTaskStr)
      setRefetchApi(!refetchApi)
    }
  }
  catch (e) {
    console.log(e)
  }
  finally {
    setIsCancelling(false)
  }
}

export const checkScheduleTaskNow = async (id,setIsChecking,setRefetchApi,refetchApi) => {
  try{ 
    setIsChecking &&  setIsChecking(true)
    const request = await checkScheduleTask(id)
    if(request){
      toast?.success(succesfullReqStr)
      setRefetchApi(!refetchApi)
    }}
    catch(e){
      console.log(e)
    }
    finally{
      setIsChecking &&  setIsChecking(false)
    }
}

export const getDefaultNpiState = async(setDefaultNpiState,setUserDetails,userDetails,firstName,lastName,dob,setUseDateFromParam,appt) => {
  try{
      const npiState = await getNpiState()
      // if(npiState?.pG_NpiData?.address?.length){
        const { state: patientState } = (npiState?.pG_NpiData?.address?.length > 0 
          ? npiState.pG_NpiData.address.find((elem) => elem?.type === 'Mailing') 
          : {}) ?? { state: '' };
        
        setDefaultNpiState(patientState)
        setUserDetails({
          ...userDetails,
          patientState,
          patientLastName: lastName ?? userDetails?.patientLastName,
          patientFirstName: firstName ?? userDetails?.patientFirstName,
          patientDOB: dob ? ymdDateFormat(dob) : userDetails?.patientDOB,
          dos_startdate: appt && dayjs(appt).isBefore(dayjs()) ? dayjs(appt) : dayjs().subtract(5, 'days'),
          dos_enddate: appt && dayjs(appt).isAfter(dayjs()) ?  dayjs(appt) : dayjs().add(5, 'days'),
        })
        if(firstName && lastName && dob){
          setUseDateFromParam(true)
        }
      // }
      // else  failedApiReq()
  }
  catch(e){
    failedApiReq(e)
  }
}

export const getDiscoveryDetails = async(id,setIsLoading) => {
  try{
    setIsLoading &&  setIsLoading(true)
    const data = await get_GetInusuranceDiscoveryByPatientId(id)
    const statuses = data?.insuranceDiscoveryDto?.comboPayerResponses?.map(response => response?.status || 'N/A') || [];
    const payerNames = data?.insuranceDiscoveryDto?.comboPayerResponses?.map(response => response?.payerName || 'N/A') || [];
    const formattedData = {
      ID: data?.insuranceDiscoveryDto?.patientId,
      Transactiondate: data?.insuranceDiscoveryDto?.transactionDate || 'N/A',
      Patient: `${capitalizedFirstLetter(data?.insuranceDiscoveryDto?.patientData?.firstName)} ${capitalizedFirstLetter(data?.insuranceDiscoveryDto?.patientData?.lastName)}`,
      Phone: data?.insuranceDiscoveryDto?.patientData?.phone || 'N/A',
      Insurance: payerNames.length > 0 ? payerNames.join(' / ') : data?.insuranceDiscoveryDto?.payerName || 'N/A',
      Status: statuses.length > 0 ? statuses.join(' / ') : (data?.insuranceDiscoveryDto?.planCoverageSummary?.status || 'N/A'),
      Requestid: data?.insuranceDiscoveryDto?.requestID || 'N/A',
      completeData: { ...data?.insuranceDiscoveryDto?.patientData, 
        payerName: statuses.length > 0 ? statuses.join(' / ') : (data?.insuranceDiscoveryDto?.planCoverageSummary?.status || 'N/A')
       }
    };
    const encodedData = encodeURIComponent(JSON.stringify(formattedData));
    const newUrl = `/Discoverydetails1?rowData=${encodedData}`;
    window.location.href = newUrl; 
  }
  catch(e){
    console.log('error:',e)
    setIsLoading && setIsLoading(false)
  }
}

export const getDiscoveryDOS = (val) => {
  return val ? new Date(val)?.toISOString()?.split('T')?.[0] : new Date()?.toISOString()?.split('T')?.[0]
}

export const downloadDiscoveryCsvTemplate = async() => {
  try {
    const response = await downloadCsvTemplate();
    if (response?.csvFileByteArray) {
      DownloadCSV(response?.csvFileByteArray, "Insurance Bulk Upload.csv");
    } else {
      toast.error('Error while getting empty csv file');
    }
  } catch (error) {
    console.error('Error generating CSV file:', error);
  }
};


export const handleImportFileChange = async (file,setLoading,navigate,setCsvToDownload,handleCsvToShow) => {
  // const file = event.target.files[0];
  if (file) {
    try {
      setLoading(true)
      const validationResult = await validateDiscoveryCsvFile(file);
      if (validationResult === "CSV headers are valid.") {
        toast.success('File validated successfully');
        const readCsv = await readDiscoveryCsv(file)
        if(readCsv?.csvFileByteArray){
            const parsedData =  parseCSVDataFromBase64(readCsv?.csvFileByteArray)
                    handleCsvToShow && handleCsvToShow(parsedData)
                    setCsvToDownload && setCsvToDownload(readCsv?.csvFileByteArray)
        }
      } else {
        toast.error(`File validation failed`);
      }
    } catch (error) {
      failedApiReq(error);
    }
    finally {setLoading(false)}
  } else {
    console.log("No file detected.");
  }
  
};

export const processPatientInsuranceRecords = async (rowData, setRowDataCopy, setIsLoading, success, setRefetchScheduleTable) => {
  // Track the updated row data outside the loop
  const updatedRowData = [...rowData];
  
  // Set loading to true before starting the process
  setIsLoading(true);

  for (let i = 0; i < rowData.length; i++) {
    const currentRecord = rowData[i];
    
    try {
      // Set process status to "Processing" for the current record, others get "Queued"
      updatedRowData[i].processStatus = 'Processing';
      
      // Update status for all previous records to retain their current status
      for (let j = 0; j < i; j++) {
        if (!updatedRowData[j].processStatus) {
          updatedRowData[j].processStatus = 'Failed'; 
        }
      }

      // For all records after the current one, set status to "Queued"
      for (let k = i + 1; k < rowData.length; k++) {
        if (!updatedRowData[k].processStatus) {
          updatedRowData[k].processStatus = 'Queued';
        }
      }

      // Perform the API call for the current record
      const { patientLastName, patientFirstName, patientDOB, doS_StartDate: dos_startdate, doS_EndDate: dos_enddate, transactionDate, patientState, patientMiddleName } = currentRecord?.requestParameters;
      
      const result = await processInsuranceRecord({
        patientLastName,
        patientFirstName,
        patientDOB: ymdDateFormat(patientDOB ?? new Date()),
        dos_startdate: ymdDateFormat(dos_startdate ?? new Date()),
        dos_enddate: ymdDateFormat(dos_enddate),
        transactionDate: ymdDateFormat(transactionDate),
        patientState,
        patientMiddleName,
        requestNpi: JSON.parse(localStorage?.getItem('selectedNpi'))?.npiNumber,
      });
      
      // Check the result of the API call
      if (result?.data?.insuranceDiscoveryDtosList?.[0]?.searchStatus) {
        updatedRowData[i].processStatus = 'Completed';
        setRefetchScheduleTable(prev => prev + 1);
        if (success) success();
      } else {
        updatedRowData[i].processStatus = 'Failed';
        updatedRowData[i].postsearchdata = result;
      }

    } catch (error) {
      console.log("Error processing insurance record:", error);
      updatedRowData[i].processStatus = 'Failed'; 
    }
  }

  setRowDataCopy(updatedRowData);

  setIsLoading(false);

};

export const handleDiscoveryRefresh = async(id,setLoaders,setRefetchDiscovery) => {
  try{
    setLoaders(prev => ({...prev,refreshing:true}))
   const refetchDetails = await refreshDiscoveryDetails(id)
   if(refetchDetails){
    setRefetchDiscovery(prev => !prev)
   }
  }
  catch(e){
    console.log(e)
  }
  finally{
    setLoaders(prev => ({...prev,refreshing:false}))
  }
}