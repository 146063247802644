import { Box, Button, Paper, Typography } from '@mui/material'
import React from 'react'
import { colors } from '../../utils/colors'
import GroupIcon from '@mui/icons-material/Group';
import { Upload } from '@mui/icons-material';
import { sharedStyles } from '../../sharedStyles';
import { styles } from './styles';

const OtherCrmUploader = () => {
  return (
    <Paper
    sx={styles?.commonPaperContainer}
>
    <Box 
               display={'flex'}
               flexDirection={'column'}
               alignItems={'center'}
               gap={2}
               >
        <Box 
                       width={48}
                       height={48}
                       borderRadius={'50%'}
                       bgcolor={colors?.bgGray}
                       display={'flex'}
                       alignItems={'center'}
                       justifyContent={'center'}
                       >
            <GroupIcon sx={sharedStyles?.greenColor}/>
        </Box>

        <Box>
            <Typography variant="h6" gutterBottom>
               Upload from other PMS
            </Typography>
        </Box>

        <Button
            color='success'
            component="label"
            variant="contained"
            startIcon={<Upload />}
            disabled={true}
        >
            Coming Soon
            <input
                type="file"
                hidden
                accept=".csv"
                disabled
            />
        </Button>

        <Box sx={sharedStyles?.mt2px}>
            <Typography sx={sharedStyles?.greenColor}>
                Which Patient Management System are you coming from ?
            </Typography>

        </Box>

        <Typography variant="body2" color="text.secondary" sx={{ mt: 4 }}>
           Choose a PMS which you would like to import. importing data from other Patient Management Systems is just a click away 
        </Typography>
    </Box>
</Paper>
  )
}

export default OtherCrmUploader