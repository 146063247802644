import { Box } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import React, { useEffect, useState } from 'react'
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { sharedStyles } from '../../../sharedStyles';
import PageLoader from '../../../components/loader/PageLoader';
import { getAllClaimsForPatient } from '../../../services/ClaimService';
import { capitalizedFirstLetter, failedApiReq } from '../../../utils/reusableFunctions';
import UseWidth from '../../../custom-hooks/UseWidth';
import { useNavigate } from 'react-router-dom';
import { formatClaimSearchGridArray } from '../ClaimFunctions';
import ViewDetailsButton from '../../../components/buttons/ViewDetailsButton';

const ClaimSearchResultsTable = ({ id }) => {
  const [patientName, setPatientName] = useState('')
  const [gridApi, setGridApi] = useState(null);
  const { width } = UseWidth();
  const perPage = width <= 900 ? 20 : 10;

  const onGridReady = (params) => {
    setGridApi(params.api);
  };


  useEffect(() => {
    if (gridApi) {
      const dataSource = {
        getRows: async (params) => {
          const page = params.endRow / perPage;
          try {
            gridApi.showLoadingOverlay();
            const allClaims = await getAllClaimsForPatient(id, page, perPage);
            if (allClaims?.data) {
              if (!patientName) {
                setPatientName(`${capitalizedFirstLetter(allClaims?.data?.[0]?.patientData?.firstName || '')} ${capitalizedFirstLetter(allClaims?.data?.[0]?.patientData?.middleName || '')} ${capitalizedFirstLetter(allClaims?.data?.[0]?.patientData?.lastname || '')}`)
              }
              const dataArray = formatClaimSearchGridArray(allClaims?.data)
              params.successCallback(dataArray, allClaims?.totalCount);
            }

          }
          catch (e) {
            params.successCallback([], 0);
            failedApiReq(e)
          }
          finally {
            gridApi.hideOverlay();
          }
        }
      }

      gridApi.setDatasource(dataSource);
    }
  }, [gridApi]);

  const columnDefs = () => {
    return [
      { headerName: 'Transaction Date', field: 'date', width: 190, resizable: false },
      { headerName: 'Patient', field: 'patient', width: 232, resizable: false },
      { headerName: 'Vendor', field: 'vendor', width: 170, resizable: false },
      { headerName: 'Phone', field: 'phone', width: 150, resizable: false },
      { headerName: 'Insurance Carrier', field: 'insurance', width: 227, minWidth: 227, resizable: false,},
      { headerName: 'Status', field: 'status', filter: true, width: 226 ,flex:1},
     { headerName: 'Details', field: '', 
      cellRenderer: (params) => params?.data && ViewDetailsButton(`/claimDetails1?id=${params?.data?.id}&searchResult=true`), 
      flex: 1, minWidth: 140, resizable: false },
    ];
  };
  return (
    <Box>
          <Box sx={sharedStyles?.tabsBox}>
            <Box sx={sharedStyles?.w25percent}>
              <p>{patientName} </p>
            </Box>
          </Box>
          <Box sx={sharedStyles?.mt2em}>
          </Box>
          <Box className="ag-theme-quartz" sx={sharedStyles?.responsiveTableHeight}>
            <AgGridReact
              pagination={true}
              rowModelType={'infinite'}
              paginationPageSize={perPage}
              cacheBlockSize={perPage}
              onGridReady={onGridReady}
              rowHeight={60}
              columnDefs={columnDefs()}
              overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Loading...</span>'}
            />
          </Box>
    </Box>
  )
}

export default ClaimSearchResultsTable