import React, { useContext } from 'react'
import DefaultAmerigroupRequestingProviders from '../../components/Amerigroup/DefaultAmerigroupRequestingProvider'
import { AmerigroupContext } from '../../../../../context/priorAuthorizationForms/AmerigroupArContext'

const AmerigroupRequestingProvider = () => {
    const { requestingProvider, setRequestingProvider, setSelectedTab, onceCompleted  } = useContext(AmerigroupContext);

  return (
    <DefaultAmerigroupRequestingProviders
        onceCompleted={onceCompleted}
        requestingProvider={requestingProvider}
        setRequestingProvider={setRequestingProvider}
        setSelectedTab={setSelectedTab}
    />
  )
}

export default AmerigroupRequestingProvider
