import React, { useState } from 'react';
import { IconButton, Typography, Box, Grid, TextField, FormControl, MenuItem, Select, InputLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styles } from './styles';
import { sharedStyles } from '../../../../sharedStyles';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { handleChangePaRegexs } from '../handleChangeRegexes';
import { aetnaBasicProceduresQualifierCodes, anthemTimeFrequencyQualifers, anthemTimeQuantityQualifiers, formHelperTexts, smplyHsQuantityTypes } from '../../../../utils/staticData';

const ServiceInfoList = ({ 
    serviceInfoArray, 
    onRemove, 
    procedure, 
    errors, 
    setServiceInfoArray, 
    showDates, 
    showServices, 
    showQualifierCode, 
    defaultMinDate, 
    notReqFields, 
    showTimeFields,
    externalShowAllFields, 
    setExternalShowAllFields,
    quantityTypeOps,
    qualifierCodes,
    helperTexts,
    defaultMaxDate,
    regexFieldNames

 }) => {
    const [internalShowAllFields, setInternalShowAllFields] = useState(false);
    const showAllFields = externalShowAllFields ?? internalShowAllFields;
    const setShowAllFields = setExternalShowAllFields ?? setInternalShowAllFields;

    const today = dayjs();
    const minDate = defaultMinDate ?? today.subtract(180, 'days');
    const maxDate = defaultMaxDate ?? today.add(180, 'days');

    const handleDateChange = (date, index, field) => {
        setServiceInfoArray(serviceInfoArray?.map((e, i) => {
            if (i === index) {
                return { ...e, [field]: date }
            }
            else {
                return e
            }
        }))
    }

    const handleChange = (event, index, field, fieldRegexName) => {
        const { value } = event.target;
        const regex = handleChangePaRegexs?.aetna?.[fieldRegexName || field];
        if (regex && value !== "" && !regex.test(value)) {
            return;
        }
        else {
            setServiceInfoArray(serviceInfoArray?.map((e, i) => {
                if (i === index) {
                    return { ...e, [field]: value }
                }
                else {
                    return e
                }
            }))
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <>
                <RadioGroup
                sx={sharedStyles?.mt5px}
                    value={showAllFields}
                    onClick={() => setShowAllFields(!showAllFields)}
                >
                    <FormControlLabel value={false} control={<Radio sx={sharedStyles?.muiCheckedThemeColor} />} label="Required Fields Only" />
                </RadioGroup>
            </>
            <div>
                {serviceInfoArray?.length && serviceInfoArray?.map((info, index) => {
                    return (
                        <Box
                            key={index}
                            sx={{
                                ...sharedStyles?.mt10px,
                                ...styles?.serviceInfoListContainer
                            }}
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    {!procedure ?
                                        <Typography variant="body1" sx={{ flex: 1 }}>
                                            <strong>{info.heading || 'Selected Diagnosis'}:</strong> {info?.icdCode}
                                        </Typography> :
                                        <Typography variant="body1" sx={{ flex: 1 }}>
                                            <strong>{info.heading || 'Selected Procedure'}:</strong> {info?.CptCode}
                                        </Typography>
                                    }
                                </Grid>
                                {((showQualifierCode &&  !notReqFields?.includes('qualifierCode'))|| (showAllFields && showQualifierCode)) &&
                                    <Grid item xs={12} >
                                        <FormControl fullWidth required={!notReqFields?.includes('qualifierCode')}
                                            error={errors?.[index]?.qualifierCode}
                                        >
                                            <InputLabel>Qualifier Code</InputLabel>
                                            <Select
                                                value={info?.qualifierCode}
                                                label="Qualifier Code"
                                                onChange={(e) => handleChange(e, index, 'qualifierCode')}
                                                error={errors?.[index]?.qualifierCode}
                                            >
                                                {(qualifierCodes ?? aetnaBasicProceduresQualifierCodes)?.map((item) => (
                                                    <MenuItem key={item.code} value={item.value}>
                                                        {item.value}
                                                    </MenuItem>
                                                ))}

                                            </Select>
                                        </FormControl>
                                    </Grid>
                                }
                                {((showDates?.date && !notReqFields?.includes('date')) || (showAllFields && showDates?.date))  && <Grid item xs={12} >
                                    <DatePicker
                                        label="Date"
                                        sx={errors?.[index]?.date ? sharedStyles?.errorDatePickerStyles : sharedStyles?.defaultDatePickerStyles}
                                        value={info.date}
                                        onChange={(date) => handleDateChange(date, index, 'date')}
                                        minDate={minDate}
                                        maxDate={maxDate}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                inputProps: { readOnly: true },
                                                required: notReqFields?.includes('date') ? false : true,
                                            }
                                        }}
                                    />
                                </Grid>}
                                {((showDates?.fromDate && !notReqFields?.includes('fromDate')) || (showAllFields && showDates?.fromDate)) 
                                    && <Grid item xs={12}>
                                        <DatePicker
                                            label="Procedure From Date"
                                            sx={errors?.[index]?.fromDate ? sharedStyles?.errorDatePickerStyles : sharedStyles?.defaultDatePickerStyles}
                                            value={info.fromDate}
                                            onChange={(date) => handleDateChange(date, index, 'fromDate')}
                                            minDate={minDate}
                                            maxDate={maxDate}
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    required: notReqFields?.includes('fromDate') ? false : true,
                                                    inputProps: { readOnly: true },
                                                }
                                            }}
                                        />
                                    </Grid>}
                                {((showDates?.toDate && !notReqFields?.includes('toDate')) || (showAllFields && showDates?.toDate)) && <Grid item xs={12}>
                                    <DatePicker
                                        label="Procedure To Date"
                                        value={info.toDate}
                                        onChange={(date) => handleDateChange(date, index, 'toDate')}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                inputProps: { readOnly: true },
                                                required: notReqFields?.includes('toDate') ? false : true,
                                            }
                                        }}
                                        {...(defaultMinDate ? { minDate } : {})}
                                        {...(defaultMaxDate ? { maxDate } : {})}
                                    />
                                </Grid>}
                                {((showServices?.serviceQuantity && !notReqFields?.includes('quantity')) || (showAllFields && showServices?.serviceQuantity )) && <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Procedure Service Quantity"
                                        value={info?.quantity ?? ''}
                                        onChange={(e) => handleChange(e, index, 'quantity',regexFieldNames?.quantity)}
                                        error={errors?.[index]?.quantity}
                                        required={notReqFields?.includes('quantity') ? false : true}
                                        helperText={helperTexts?.quantity || formHelperTexts?.onlyNums}
                                    />
                                </Grid>
                                }
                                {
                                    ((showServices?.serviceQuantityType && !notReqFields?.includes('quantityTypeCode')) || (showAllFields && showServices?.serviceQuantityType)) && <Grid item xs={12}>
                                        <FormControl fullWidth
                                            required={notReqFields?.includes('quantityTypeCode') ? false : true}
                                            error={errors?.[index]?.quantityTypeCode}
                                        >
                                            <InputLabel>Quantity Type</InputLabel>
                                            <Select
                                                value={info?.quantityTypeCode ?? ''}
                                                label="Quantity Type"
                                                onChange={(e) => handleChange(e, index, 'quantityTypeCode')}
                                                error={errors?.[index]?.quantityTypeCode}
                                            >
                                                {(quantityTypeOps ?? smplyHsQuantityTypes)?.map((item) => (
                                                    <MenuItem key={item.code} value={item.value}>
                                                        {item.value}
                                                    </MenuItem>
                                                ))}

                                            </Select>
                                        </FormControl>
                                    </Grid>
                                }
                                {((showTimeFields?.timePeriodQuantity && !notReqFields?.includes('timePeriodQuantity') ) || (showAllFields && showTimeFields?.timePeriodQuantity)) && <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Time Period Quantity"
                                        value={info?.timePeriodQuantity ?? ''}
                                        onChange={(e) => handleChange(e, index, 'timePeriodQuantity')}
                                        error={errors?.[index]?.timePeriodQuantity}
                                        required={notReqFields?.includes('timePeriodQuantity') ? false : true}
                                        helperText={formHelperTexts?.onlyNums}
                                    />
                                </Grid>
                                }
                                 {
                                    ((showTimeFields?.timePeriodQuantityQualifier && !notReqFields?.includes('timePeriodQuantityQualifier')) ||  (showAllFields && showTimeFields?.timePeriodQuantityQualifier)) && <Grid item xs={12}>
                                        <FormControl fullWidth
                                            required={notReqFields?.includes('timePeriodQuantityQualifier') ? false : true}
                                            error={errors?.[index]?.timePeriodQuantityQualifier}
                                        >
                                            <InputLabel>Time Period Quantity Qualifier</InputLabel>
                                            <Select
                                                value={info?.timePeriodQuantityQualifier}
                                                label="Time Period Quantity Qualifier"
                                                onChange={(e) => handleChange(e, index, 'timePeriodQuantityQualifier')}
                                                error={errors?.[index]?.timePeriodQuantityQualifier}
                                            >
                                                {anthemTimeQuantityQualifiers?.map((item) => (
                                                    <MenuItem key={item.code} value={item.value}>
                                                        {item.value}
                                                    </MenuItem>
                                                ))}

                                            </Select>
                                        </FormControl>
                                    </Grid>
                                }
                                {((showTimeFields?.timeframeFrequency && !notReqFields?.includes('timeframeFrequency')) || (showAllFields && showTimeFields?.timeframeFrequency)) && <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Time Frame Frequency"
                                        value={info?.timeframeFrequency ?? ''}
                                        onChange={(e) => handleChange(e, index, 'timeframeFrequency')}
                                        error={errors?.[index]?.timeframeFrequency}
                                        required={notReqFields?.includes('timeframeFrequency') ? false : true}
                                        helperText={formHelperTexts?.onlyNums}
                                    />
                                </Grid>
                                }
                               
                                {
                                    ((showTimeFields?.timeFrameFrequencyQualifier && !notReqFields?.includes('timeFrameFrequencyQualifier')) || (showAllFields && showTimeFields?.timeFrameFrequencyQualifier)) && <Grid item xs={12}>
                                        <FormControl fullWidth
                                            required={notReqFields?.includes('timeFrameFrequencyQualifier') ? false : true}
                                            error={errors?.[index]?.timeFrameFrequencyQualifier}
                                        >
                                            <InputLabel>Time Frame Frequency Qualifier</InputLabel>
                                            <Select
                                                value={info?.timeFrameFrequencyQualifier}
                                                label="Time Frame Frequency Qualifier"
                                                onChange={(e) => handleChange(e, index, 'timeFrameFrequencyQualifier')}
                                                error={errors?.[index]?.timeFrameFrequencyQualifier}
                                            >
                                                {anthemTimeFrequencyQualifers?.map((item) => (
                                                    <MenuItem key={item.code} value={item.value}>
                                                        {item.value}
                                                    </MenuItem>
                                                ))}

                                            </Select>
                                        </FormControl>
                                    </Grid>
                                }


                            </Grid >

                            <IconButton
                                color="error"
                                onClick={() => onRemove(info.id)}
                                aria-label="Remove"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    )
                })}
            </div>
        </LocalizationProvider>
    );
};

export default ServiceInfoList;
