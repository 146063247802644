import { Box, Grid, Paper, TextField, Typography, Button } from '@mui/material';
import React, { useState } from 'react'
import { styles } from '../../Aetna/styles';
import { sharedStyles } from '../../../../../sharedStyles';
import CommonNextButton from '../CommonNextButton';
import { handleChangePaRegexs } from '../../handleChangeRegexes';

const DefaultProviderNotesForm = ({ setSelectedTab, providerNotes, setProviderNotes, onceCompleted }) => {
    const [notes, setNotes] = useState(providerNotes); // Initialize with the providerNotes passed as a prop

    const handleChange = (event, index) => {
        const { value } = event.target;
        const updatedNotes = [...notes];
        updatedNotes[index] = value;

        const regex = handleChangePaRegexs?.aetna?.message;
        if (regex && value !== "" && !regex.test(value)) {
            return;
        }

        setNotes(updatedNotes); // Update the state with the modified note
    };

    

    const addNewNote = () => {
        setNotes([...notes, ""]); // Add a new empty note
    };

    const handleSubmit = (submit) => {
        submit ? setSelectedTab(onceCompleted) : setSelectedTab((prev) => prev + 1);
        setProviderNotes(notes); // Update the providerNotes state with the notes
    };

    return (
        <>
            <Paper elevation={3} sx={styles?.parentPaperContainer}>
                <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
                    Provider Notes
                </Typography>

                <Box sx={styles?.commonPaperContainer}>
                    <Grid container spacing={3}>
                        {notes.map((note, index) => (
                            <Grid item xs={12} key={index}>
                                <TextField
                                    fullWidth
                                    label={`Provider Note ${index + 1}`}
                                    value={note}
                                    onChange={(event) => handleChange(event, index)}
                                />
                            </Grid>
                        ))}
                    </Grid>

                    <Box mt={2}>
                        <Button variant="contained" color="primary" onClick={addNewNote}>
                            Add New Provider Note
                        </Button>
                    </Box>
                </Box>

                <CommonNextButton handleSubmit={handleSubmit} setSelectedTab={setSelectedTab} onceCompleted={onceCompleted} />
            </Paper>
        </>
    );
};

export default DefaultProviderNotesForm;
