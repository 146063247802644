import React, { useState } from 'react';
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Paper,
    Typography,
    Box,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormHelperText,
} from '@mui/material';
import toast from 'react-hot-toast';
import { handleChangePaRegexs } from '../../handleChangeRegexes';
import CommonNextButton from '../CommonNextButton';
import { styles } from '../../Aetna/styles';
import { sharedStyles } from '../../../../../sharedStyles';
import { aetnaSrDefaultData, bcbsIllionoisHsRoleCodes, paFormErrors, smplyHsRpErrorsDefault } from '../../../../../utils/staticData';

const DefaultAmerigroupRequestingProviders = ({ requestingProvider, setRequestingProvider, setSelectedTab, onceCompleted, onComplete }) => {
    const [errors, setErrors] = useState(smplyHsRpErrorsDefault);

    const [showAllFields, setShowAllFields] = useState(false);


    const handleChange = (field) => (event) => {
        const { value } = event.target;
        const regex = handleChangePaRegexs?.aetna?.[field];
        if (regex && value !== "" && !regex.test(value)) {
            return;
        }
        setRequestingProvider((prevrequestingProvider) => ({
            ...prevrequestingProvider,
            [field]: value,
        }));
    };


    const checkRequiredFields = () => {
        const requiredFields = ['roleCode', 'phone', 'contactName', 'npi', 'fax'];
        let hasErrors = false;
        const errors = requiredFields.reduce((acc, field) => {
            if (!requestingProvider?.[field]) {
                acc[field] = true;
                hasErrors = true;
            } else {
                acc[field] = false;
            }
            return acc;
        }, {});
        return { hasErrors, errors };
    };

    const validateAddressFields = () => {
        const errors = {};
        const { addressLine1, addressLine2, npi, city, zipCode, phone, fax } = requestingProvider;

        if (addressLine1 && addressLine1?.length < 2) {
            errors.addressLine1 = 'Enter a valid mailing address from 2 to 55 characters in length. (optional)';
        } else {
            errors.addressLine1 = false;
        }

        if (addressLine2 && addressLine2.length < 2) {
            toast.error('Enter a valid mailing address from 2 to 55 characters in length.');
            errors.addressLine2 = 'Enter a valid mailing address from 2 to 55 characters in length. (optional)';
        } else {
            errors.addressLine2 = false;
        }

        if (npi?.length < 10) {
            errors.npi = 'Enter a valid Requesting Provider National Provider Identifier (NPI) containing 10 numeric digits and beginning with a 1, 2, 3, or 4. (optional)'
        }
        else {
            errors.npi = false
        }

        if (fax?.length < 10) {
            errors.fax = 'Enter a valid fax number containing 10 numeric digits including area code.'
        }
        else {
            errors.fax = false
        }

        if (city && city.length < 2) {
            errors.city = 'Enter a valid city name from 2 to 30 characters in length. (optional)';
        } else {
            errors.city = false;
        }


        if (zipCode) {
            if (zipCode?.length < 5 || (zipCode?.length > 5 && zipCode?.length < 9)) {
                errors.zipCode = "Enter a valid ZIP code containing five numeric digits. The four-digit extension is optional and must also be numeric. (optional)"
            }
            else {
                errors.zipCode = false
            }
        }
        else {
            errors.zipCode = false
        }


        if (phone?.length < 10) {
            errors.phone = 'Enter a valid phone number containing 10 numeric digits including area code.';
        } else {
            errors.phone = false;
        }

        return errors;
    };

    const handleSubmit = (submit) => {

        const { hasErrors: missingRequiredFields, errors: requiredErrors } = checkRequiredFields();
        setErrors((prev) => ({ ...prev, ...requiredErrors }));

        if (missingRequiredFields) {
            toast.error('Please enter all important information');
            return;
        }

        const addressErrors = validateAddressFields();
        setErrors((prev) => ({ ...prev, ...addressErrors }));

        if (!Object.values({ ...requiredErrors, ...addressErrors }).some(Boolean)) {
            submit ? setSelectedTab(onceCompleted) : setSelectedTab((prev) => prev + 1)
            onComplete && onComplete()
        }
    };





    return (
        <Paper elevation={3} sx={styles?.parentPaperContainer}>
            <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
                Requesting Provider Information
            </Typography>
            <RadioGroup
                value={showAllFields}
                onClick={(event) => setShowAllFields(!showAllFields)}
            >
                <FormControlLabel value={false} control={<Radio sx={sharedStyles?.muiCheckedThemeColor} />} label="Required Fields Only" />
            </RadioGroup>
            <Box sx={styles?.commonPaperContainer}>
                <Grid container spacing={3}>




                    {showAllFields &&
                        <>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    label=" First Name"
                                    value={requestingProvider.firstName}
                                    onChange={handleChange('firstName')}
                                    error={errors?.firstName}
                                    helperText={errors?.firstName && paFormErrors?.reqField}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    label=" Last Name"
                                    value={requestingProvider.lastName}
                                    onChange={handleChange('lastName')}
                                    error={errors?.lastName}
                                    helperText={errors?.lastName && paFormErrors?.reqField}

                                />
                            </Grid>
                        </>

                    }
                    <Grid item xs={12} md={showAllFields ? 4 : 6}>
                        <TextField
                            required
                            fullWidth
                            label=" Contact Name"
                            value={requestingProvider.contactName}
                            onChange={handleChange('contactName')}
                            error={errors?.contactName}
                            helperText={errors?.contactName && paFormErrors?.reqField}

                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            required
                            fullWidth
                            label=" NPI"
                            value={requestingProvider.npi}
                            onChange={handleChange('npi')}
                            helperText='Enter a valid  National Provider Identifier (NPI) containing 10 numeric digits and beginning with a 1, 2, 3, or 4.'
                            error={errors?.npi}

                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            required
                            fullWidth
                            label=" Contact Phone"
                            value={requestingProvider.phone}
                            onChange={handleChange('phone')}
                            error={errors?.phone}
                            helperText={errors?.phone && paFormErrors?.reqField}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            required
                            fullWidth
                            label=" Fax"
                            value={requestingProvider.fax}
                            onChange={handleChange('fax')}
                            error={errors?.fax}
                            helperText={errors?.fax}
                        />
                    </Grid>

                    <Grid item xs={12} md={showAllFields ? 6 : 12}>
                        <FormControl fullWidth required
                            error={errors?.roleCode}
                        >
                            <InputLabel>Provider Type</InputLabel>
                            <Select
                                value={requestingProvider.roleCode}
                                label="Provider Type"
                                onChange={handleChange('roleCode')}
                                error={errors?.roleCode}
                            >
                                {bcbsIllionoisHsRoleCodes?.map(({ value, code }) => (
                                    <MenuItem key={value} value={value}>
                                        {value}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText sx={sharedStyles?.errorText}>
                                {errors?.roleCode && paFormErrors?.reqField}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    {showAllFields &&
                        <>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth
                                    error={errors?.stateCode}
                                >
                                    <InputLabel> State</InputLabel>
                                    <Select
                                        value={requestingProvider.stateCode}
                                        label=" State"
                                        onChange={handleChange('stateCode')}
                                    >
                                        {aetnaSrDefaultData?.requestingProviderStates?.map(({ value, code }) => (
                                            <MenuItem key={value} value={code}>
                                                {value}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText sx={sharedStyles?.errorText}>
                                        {errors?.stateCode && paFormErrors?.reqField}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label=" City"
                                    value={requestingProvider.city}
                                    onChange={handleChange('city')}
                                    error={errors?.city}
                                    helperText={errors?.city}

                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label=" Address Line 1"
                                    value={requestingProvider.addressLine1}
                                    onChange={handleChange('addressLine1')}
                                    error={errors?.addressLine1}
                                    helperText={errors?.addressLine1}

                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label=" Address Line 2"
                                    value={requestingProvider.addressLine2}
                                    onChange={handleChange('addressLine2')}
                                    error={errors?.addressLine2}
                                    helperText={errors?.addressLine2}

                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label=" ZIP Code"
                                    value={requestingProvider.zipCode}
                                    onChange={handleChange('zipCode')}
                                    error={errors?.zipCode}
                                    helperText={errors?.zipCode && paFormErrors?.reqField}

                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label=" Tax Id"
                                    value={requestingProvider.taxId}
                                    onChange={handleChange('taxId')}
                                    error={errors?.taxId}
                                    helperText={errors?.taxId}
                                />
                            </Grid>



                            <Grid item xs={12} >
                                <TextField
                                    fullWidth
                                    label=" Contact Extension"
                                    value={requestingProvider.extension}
                                    onChange={handleChange('extension')}
                                    error={errors?.extension}
                                    helperText={errors?.extension}
                                />
                            </Grid>

                        </>
                    }



                </Grid>
            </Box>

            <CommonNextButton handleSubmit={handleSubmit} setSelectedTab={setSelectedTab} onceCompleted={onceCompleted} />
        </Paper>
    );
};

export default DefaultAmerigroupRequestingProviders;
