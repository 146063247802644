import React, { useContext, useState } from 'react';
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Paper,
    Typography,
    Box,
    FormHelperText,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@mui/material';
import { handleChangePaRegexs } from '../../handleChangeRegexes';
import { sharedStyles } from '../../../../../sharedStyles';
import { bcbsServiceLevels, formHelperTexts, paFormErrors, smplyHsQuantityTypes } from '../../../../../utils/staticData';
import { styles } from '../../components/styles';
import CommonNextButton from '../../components/CommonNextButton';

const ServicesInfoBcbsForm = ({ errors, setErrors, setSelectedTab, serviceInfo, setServiceInfo, onceCompleted, placeOfServices }) => {
    const [showAllFields, setShowAllFields] = useState(false)


    const handleChange = (event, field) => {
        const { value } = event.target;
        if (field === 'quantity') {
            const regex = handleChangePaRegexs?.aetna?.['bcbsQuantity'];
            if (regex && value !== "" && !regex.test(value)) {
                return;
            }
            setServiceInfo((prevFormData) => ({
                ...prevFormData,
                [field]: value,
            }));
        }
        else {
            setServiceInfo((perv) => ({
                ...perv,
                [field]: value,
            }));
        }
    };

    const handleSubmit = (submit) => {

        if (
            !serviceInfo?.quantity ||
            !serviceInfo?.placeOfServiceCode ||
            !serviceInfo?.quantityTypeCode
        ) {

            setErrors({
                quantity: !serviceInfo?.quantity ? paFormErrors?.reqField : false,
                placeOfServiceCode: !serviceInfo?.placeOfServiceCode ? paFormErrors?.reqField : false,
                quantityTypeCode: !serviceInfo?.quantityTypeCode ? paFormErrors?.reqField : false,
            });
            return
        }

        else {
            setErrors({
                quantity: false,
                placeOfServiceCode: false,
                quantityTypeCode: false
            });
            submit ? setSelectedTab(onceCompleted) : setSelectedTab((prev) => prev + 1)
        }
    };

    return (
        <Paper elevation={3} sx={styles?.parentPaperContainer}>
            <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
                Service & Quantity Type Information
            </Typography>


            <Box sx={styles?.commonPaperContainer}>
                <RadioGroup
                    value={showAllFields}
                    onClick={() => setShowAllFields(!showAllFields)}
                >
                    <FormControlLabel value={false} control={<Radio sx={sharedStyles?.muiCheckedThemeColor} />} label="Required Fields Only" />
                </RadioGroup>
                <Grid container spacing={3} >

                    <Grid item xs={12} md={showAllFields ? 6 : 12}>
                        <FormControl fullWidth required
                            error={errors?.placeOfServiceCode}
                        >
                            <InputLabel>Place Of Service</InputLabel>
                            <Select
                                value={serviceInfo?.placeOfServiceCode}
                                label="Place Of Service"
                                onChange={(e) => handleChange(e, 'placeOfServiceCode')}
                            >
                                {placeOfServices?.map((item) => (
                                    <MenuItem key={item.code} value={item.value}>
                                        {item.value}
                                    </MenuItem>
                                ))}

                            </Select>
                            <FormHelperText sx={sharedStyles?.errorText}>
                                {errors?.placeOfServiceCode && errors?.placeOfServiceCode}
                            </FormHelperText>
                        </FormControl>
                    </Grid>

                    {showAllFields && <Grid item xs={12} md={6}>
                        <FormControl fullWidth
                            error={errors?.serviceLevel}
                        >
                            <InputLabel>Service Level</InputLabel>
                            <Select
                                value={serviceInfo?.serviceLevel}
                                label="Service Level"
                                onChange={(e) => handleChange(e, 'serviceLevel')}
                            >
                                {bcbsServiceLevels?.map((item) => (
                                    <MenuItem key={item.code} value={item.value}>
                                        {item.value}
                                    </MenuItem>
                                ))}

                            </Select>
                            <FormHelperText sx={sharedStyles?.errorText}>
                                {errors?.serviceLevel && errors?.serviceLevel}
                            </FormHelperText>
                        </FormControl>
                    </Grid>}


                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Quantity"
                            value={serviceInfo?.quantity}
                            onChange={(e) => handleChange(e, 'quantity')}
                            error={errors?.quantity}
                            helperText={formHelperTexts?.numberUnder369}
                            required
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth required
                            error={errors?.quantityTypeCode}
                        >
                            <InputLabel>Quantity Type</InputLabel>
                            <Select
                                value={serviceInfo?.quantityTypeCode}
                                label="Quantity Type"
                                onChange={(e) => handleChange(e, 'quantityTypeCode')}
                                error={errors?.quantityTypeCode}
                            >
                                <MenuItem value={'Visits'}>
                                    Visits
                                </MenuItem>

                            </Select>
                        </FormControl>
                    </Grid>

                </Grid>
            </Box>

            <CommonNextButton handleSubmit={handleSubmit} setSelectedTab={setSelectedTab} onceCompleted={onceCompleted} />
        </Paper>
    );
};

export default ServicesInfoBcbsForm;
