import React, { useContext } from 'react'
import { AmerigroupContext } from '../../../../../context/priorAuthorizationForms/AmerigroupArContext'
import DefaultAmerigroupAdmissionInfo from '../../components/Amerigroup/DefaultAmerigroupAdmissionInfo'

const AmerigroupAdmissionForm = () => {
    const { admissionInfo, setAdmissionInfo, setSelectedTab, onceCompleted } = useContext(AmerigroupContext)
  return (
    <DefaultAmerigroupAdmissionInfo
        admissionInfo={admissionInfo}
        onceCompleted={onceCompleted}
        setAdmissionInfo={setAdmissionInfo}
        setSelectedTab={setSelectedTab}
    />
  )
}

export default AmerigroupAdmissionForm
