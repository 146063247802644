import React, { useContext } from 'react'
import DefaultBcbsRequestingProviders from '../../components/Bcbs/DefaultBcbsRequestingProvider'
import { BcbsIllinoisHsContext } from '../../../../../context/priorAuthorizationForms/BcbsIllinoisContext'

const RequestingProviderBcbsbIllinoisHsForm = () => {
    const { requestingProvider: formData, setRequestingProvider: setFormData, setSelectedTab, onceCompleted,setOnceCompleted } = useContext(BcbsIllinoisHsContext)
  return (
  <DefaultBcbsRequestingProviders
  formData={formData}
  setFormData={setFormData}
  setSelectedTab={setSelectedTab}
  onceCompleted={onceCompleted}
  onComplete={()=>setOnceCompleted(10)}
  />
  )
}

export default RequestingProviderBcbsbIllinoisHsForm