import React, { useState } from 'react'
import Dropdown from './Dropdown'
import {Search,Dashboard,TravelExplore } from '@mui/icons-material'

const PriorAuthDropdown = ({ verifiedUserNpi, active }) => {
    const [tabs] = useState([
        {
            label: "Table ", path: "/priorAuth?preSelectedTab=0", icon: <Dashboard size={15} />
        },
        {
            label: "Submit Prior Authorization", path: "/priorAuth?preSelectedTab=1", icon: <TravelExplore fontSize='small' />
        },
        {
            label: "Search Prior Authorization", path: "/priorAuth?preSelectedTab=2", icon: <Search fontSize='small' />
        },
    ])
    return (
        <Dropdown
            tabs={tabs}
            verifiedUserNpi={verifiedUserNpi}
            heading={'Prior Authorization'}
            path={'/priorAuth'}
            active={active}
        />
    )
}

export default PriorAuthDropdown