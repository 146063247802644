import { createContext, useEffect, useState } from "react";
import { aetnaDefaultData, bcbsIllinoisHsDatesDefault, defaultPatientSmplyHs, hsSubscriberDefaultData, smplHsServiceDefault, smplyHsRpDefault, } from "../../utils/staticData";



const BcbsIllinoisHsContext = createContext();


const BcbsIllinoisContextProvider = ({ children }) => {

    const defaultRenderingProvider = [{
        fax:'',
        npi:'',
        city:'',
        taxId:'',
        zipCode:'',
        lastName:'',
        roleCode:'',
        firstName:'',
        stateCode:'',
        addressLine1:'',
        addressLine2:'',

     }]

    const [selectedTab, setSelectedTab] = useState(0)
    const [patient, setPatient] = useState(defaultPatientSmplyHs)
    const [datesInfo, setDatesInfo] = useState(bcbsIllinoisHsDatesDefault);
    const [onceCompleted, setOnceCompleted] = useState(false)
    const [diagnosesInfo, setDiagnosesInfo] = useState([])
    const [proceduresInfo, setProceduresInfo] = useState([])
    const [subscriber, setSubscriber] = useState(hsSubscriberDefaultData)
    const [providerNotes, setProviderNotes] = useState('')
    const [serviceInfo, setServiceInfo] = useState({})
    const [requestingProvider, setRequestingProvider] = useState(smplyHsRpDefault)
    const [renderingProviders, setRenderingProviders] = useState(defaultRenderingProvider)
    const [admissionInfo, setAdmissionInfo] = useState('')
    const [serviceType, setServiceType] = useState({})


    useEffect(()=>{
setPatient({...patient,
    conditionCode:'',
    prognosisCode:''
})
setServiceInfo({
    ...serviceInfo,
    placeOfServiceCode:''
})
setDatesInfo({
    ...datesInfo,
    homeHealthStartDate:null
})
    },[serviceType])

    return (
        <BcbsIllinoisHsContext.Provider value={{
            selectedTab, setSelectedTab,
            patient, setPatient,
            datesInfo, setDatesInfo,
            onceCompleted, setOnceCompleted,
            diagnosesInfo, setDiagnosesInfo,
            proceduresInfo, setProceduresInfo,
            subscriber, setSubscriber,
            providerNotes, setProviderNotes,
            serviceInfo, setServiceInfo,
            requestingProvider, setRequestingProvider,
            renderingProviders, setRenderingProviders,
            admissionInfo, setAdmissionInfo,
            defaultRenderingProvider,
            serviceType, setServiceType
        }}>
            {children}
        </BcbsIllinoisHsContext.Provider>
    )
};


export { BcbsIllinoisContextProvider, BcbsIllinoisHsContext };