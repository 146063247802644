import React, { useContext } from 'react'
import DefaultAetnaProviderNotesSrForm from '../../components/Aetna/DefaultAetnaProviderNotes'
import { BcbsIllinoisHsContext } from '../../../../../context/priorAuthorizationForms/BcbsIllinoisContext'

const ProviderNotesBcbsIllinoisHsForm = () => {
    const {setSelectedTab, providerNotes, setProviderNotes, onceCompleted} = useContext(BcbsIllinoisHsContext)
  return (
    <DefaultAetnaProviderNotesSrForm
    setProviderNote={setProviderNotes}
    providerNote={providerNotes}
    setSelectedTab={setSelectedTab}
    onceCompleted={onceCompleted}
    />)
}

export default ProviderNotesBcbsIllinoisHsForm