import React, { useState } from 'react'
import CsvHeader from '../../components/shared/Csv/CsvHeader'
import CsvUploader from '../../components/shared/Csv/CsvUploader'
import { downloadDiscoveryCsvTemplate, handleImportFileChange } from './DiscoveryFunctions'
import PageLoader from '../../components/loader/PageLoader'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import DefaultFileUploaderCard from '../../components/fileUploaderCards/DefaultFileUploaderCard'
import OtherCrmUploader from '../../components/fileUploaderCards/OtherCrmUploader'
import ImportedPatientsModal from '../Patients/ImportedPatientsModal'
import { DownloadCSV } from '../../utils/reusableFunctions'

const ImportDiscovery = () => {
  // states
  const [loading, setLoading] = useState(false)
  const [showCsvModal, setShowCsvModal] = useState(false)
   const [completeCsvData, setCompleteCsvData] = useState([])
   const [csvToDownload, setCsvToDownload] = useState(null)

  //routing vars
  const navigate = useNavigate()

  const handleCsvToShow = (file) => {
    setCompleteCsvData(file ?? [])
    setShowCsvModal(true)
    setCsvToDownload(file)
}

const handleModalClose = (path) => {
  setCompleteCsvData([])
  setShowCsvModal(false)
  setCsvToDownload(null)
  path && navigate('/Discoverytab?preSelectedTab=1')
}

const handleCsvDownload = () => {
    csvToDownload &&  DownloadCSV(csvToDownload, `Downloaded_Response (${new Date().getUTCDate()}-${new Date().getUTCMonth() + 1}-${new Date().getUTCFullYear()})?.csv`);
    handleModalClose(true)
    navigate('/Discoverytab?preSelectedTab=1')
  }

  return (
    <Box>
    <Box
    display={'flex'}
    justifyContent={'center'}
    gap={4}
    sx={{
      flexDirection: { xs: 'column', md: 'row' },
      alignItems: { xs: 'center', md: 'normal' }
    }}
  >
    <DefaultFileUploaderCard onFileSelect={(file) => handleImportFileChange(file, setLoading, navigate,setCsvToDownload,handleCsvToShow)} downloadSample={() => downloadDiscoveryCsvTemplate()} loading={loading}/>
    <OtherCrmUploader/>
  </Box>
  <ImportedPatientsModal
        open={showCsvModal} 
        handleClose={handleModalClose} 
        data={completeCsvData}
        handleDownload={handleCsvDownload}
        path={true}
      />
</Box>

  )
}

export default ImportDiscovery