import React, { useContext } from 'react'
import DefaultHsPatientForm from '../../components/SilverSummitHealthPlanHs/DefaultHsPatientForm'
import { BcbsIllinoisHsContext } from '../../../../../context/priorAuthorizationForms/BcbsIllinoisContext'
import { bcbsIlPrognosises, bcbsServiceTypes, impServiceTypeCodesForPatientBcbsIl, impServiceTypeCodesForPatientBcbsIlPrognosis, patientConditionsForBcbsIllinois } from '../../../../../utils/staticData'

const PatientBcbsIllinoisHsForm = () => {
    const {  setSelectedTab, patient, setPatient,onceCompleted,serviceType} = useContext(BcbsIllinoisHsContext)
    const servicTypeCode = bcbsServiceTypes?.find(e => e?.value === serviceType?.serviceType)?.code
    const reqCondtionCode =  impServiceTypeCodesForPatientBcbsIl?.find(e => e === servicTypeCode)
    const reqPrognosisCode = impServiceTypeCodesForPatientBcbsIlPrognosis?.find(e => e === servicTypeCode)
  return (
    <DefaultHsPatientForm
    setSelectedTab={setSelectedTab} 
    formData={patient} 
    setFormData={setPatient} importantFields={['lastName', 'firstName', 'birthDate', 'subscriberRelationshipCode', 
      ...(reqCondtionCode ? ['conditionCode'] : []),
      ...(reqPrognosisCode ? ['prognosisCode'] : []),
    ]}
    fieldsToHide={['suffix']}
    dateLimits={{min:'1870-01-01',max:'2024-12-25'}}
    onceCompleted={onceCompleted}
    showConditionCode={reqCondtionCode}
    conditionCodes={patientConditionsForBcbsIllinois?.find(e => e?.conditionCode?.includes(servicTypeCode))?.vals}
    showPrognosisCode={reqPrognosisCode}
    prognosisCodes={bcbsIlPrognosises}
/>

  )
}

export default PatientBcbsIllinoisHsForm