import axios from 'axios';
import toast from 'react-hot-toast';
import { getLocalData } from '../utils/localStorageFunctions';
import { API_BASE_URL_NEW } from '../utils/staticData';
import { userDeleteAxios, userGetAxios, userPostAxios, userPutAxios } from './userApis';

const API_BASE_URL = process.env.REACT_APP_API_URL;

const handleResponse = (response) => response?.data;

const handleError = (error, errorMessage) => {
    console.error(errorMessage, error);
    toast.error(errorMessage);
    return null;
};

export const getAllAppointments = async () => {
    const url = new URL('/api/Appointment/GetAllAppointments', API_BASE_URL_NEW);
    const params = new URLSearchParams();
    const id = getLocalData('selectedNpi')?.id
    id && params?.append('npiId',   Number(id))


    url.search = params.toString();

    try {
        const response = await userGetAxios(url);
        return response
    } catch (error) {
        return handleError(error, 'Failed to fetch appointments');
    }
};

export const getAppointmentById = async (appointmentId) => {
    const url = `${API_BASE_URL_NEW}/api/Appointment/GetAppointmentById/${appointmentId}`;
    try {
        const response = await userGetAxios(url)
        return response
    } catch (error) {
        return handleError(error, 'Failed to fetch appointment by ID');
    }
};

export const addAppointment = async (appointment) => {
  const url = `${API_BASE_URL_NEW}/api/Appointment/AddAppointment`;
  try {
    const response = await userPostAxios(url,appointment)
    return handleResponse(response);
  } catch (error) {
    return handleError(error, 'Failed to add appointment');
  }
};

export const updateAppointment = async (appointmentId, appointment) => {
    const url = `${API_BASE_URL_NEW}/api/Appointment/UpdateAppointment/${appointmentId}`;
    try {
        const response = await userPutAxios(url,appointment)
        return handleResponse(response);
    } catch (error) {
        return handleError(error, 'Failed to update appointment');
    }
};

export const deleteAppointment = async (appointmentId) => {
    const url = `${API_BASE_URL_NEW}/api/Appointment/DeleteAppointment/${appointmentId}`;
    try {
        const response = await userDeleteAxios(url)
        return handleResponse(response);
    } catch (error) {
        return handleError(error, 'Failed to delete appointment');
    }
};