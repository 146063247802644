import React, { useContext } from 'react'
import { AmerigroupContext } from '../../../../../context/priorAuthorizationForms/AmerigroupArContext'
import DefaultAmerigroupRenderingProviders from '../../components/Amerigroup/DefaultAmerigroupRenderingProviders'

const AmerigroupRenderingProviders = () => {

    const { renderingProviders,
        setRenderingProviders,
        setSelectedTab,
        renderingProviderDefaultData, onceCompleted } = useContext(AmerigroupContext)
  return (
    <DefaultAmerigroupRenderingProviders
        renderingProviders={renderingProviders}
        setRenderingProviders={setRenderingProviders}
        setSelectedTab={setSelectedTab}
        onceCompleted={onceCompleted}    
        renderingProviderDefaultData={renderingProviderDefaultData}
    />
  )
}

export default AmerigroupRenderingProviders;
