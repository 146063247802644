import { Box, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { bulk_Claims, get_GenerateEmptyClaimsCsvTemplateAsync, validateCsvFile, readCsvRecords, validateClaimsCsvFile, readClaimsCsv } from "../../services/ClaimService.js";
import { DownloadCSV, failedApiReq, parseCSVDataFromBase64 } from "../../utils/reusableFunctions.js"
import toast from "react-hot-toast";
import styles from "../../css/importPatient.module.css"
import { useNavigate } from 'react-router-dom';
import React, { useState } from "react";
import DefaultFileUploaderCard from "../../components/fileUploaderCards/DefaultFileUploaderCard.jsx";
import OtherCrmUploader from "../../components/fileUploaderCards/OtherCrmUploader.jsx";
import ImportedPatientsModal from "../Patients/ImportedPatientsModal.jsx";

const ImportClaims = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const navigate = useNavigate();
  
  //
  const [loading, setLoading] = useState(false)
  const [showCsvModal, setShowCsvModal] = useState(false)
  const [completeCsvData, setCompleteCsvData] = useState([])
  const [csvToDownload, setCsvToDownload] = useState(null)
  

  const generateCSV = async () => {
    try {
      const response = await get_GenerateEmptyClaimsCsvTemplateAsync();
      if (response?.csvFileByteArray) {
        DownloadCSV(response.csvFileByteArray, "Upload_Claim_Template.csv");
      } else {
        toast.error('Error while getting empty CSV file');
      }
    } catch (error) {
      console.error('Error generating CSV file:', error);
      toast.error('Failed to generate CSV template.');
    }
  };

  const handleChange = async (file) => {
    if (file) {
      try {
        setLoading(true)
        const validationResult = await validateClaimsCsvFile(file);
        if (validationResult === "CSV headers are valid.") {
          toast.success('File validated successfully');
          const readCsv = await readClaimsCsv(file)
          if(readCsv?.csvFileByteArray){
              const parsedData =  parseCSVDataFromBase64(readCsv?.csvFileByteArray)
                       handleCsvToShow(parsedData)
                      setCsvToDownload(readCsv?.csvFileByteArray)
          }
        } else {
          toast.error(`File validation failed`);
        }
      } catch (error) {
        failedApiReq(error);
      }
      finally {setLoading(false)}
    } else {
      console.log("No file detected.");
    }
  };


   const handleCsvToShow = (file) => {
      setCompleteCsvData(file ?? [])
      setShowCsvModal(true)
      setCsvToDownload(file)
  }
  
  const handleModalClose = (path) => {
    setCompleteCsvData([])
    setShowCsvModal(false)
    setCsvToDownload(null)
    path && navigate('/ClaimTab?preSelectedTab=1')
  }
  
  const handleCsvDownload = () => {
      csvToDownload &&  DownloadCSV(csvToDownload, `Downloaded_Response (${new Date().getUTCDate()}-${new Date().getUTCMonth() + 1}-${new Date().getUTCFullYear()})?.csv`);
      handleModalClose(true)
      navigate('/ClaimTab?preSelectedTab=1')
    }

  return (
    <Box>
    <Box
    display={'flex'}
    justifyContent={'center'}
    gap={4}
    sx={{
      flexDirection: { xs: 'column', md: 'row' },
      alignItems: { xs: 'center', md: 'normal' }
    }}
  >
    <DefaultFileUploaderCard
    onFileSelect={(file) => handleChange(file, setLoading, navigate,setCsvToDownload,handleCsvToShow)}
    downloadSample={() => generateCSV()} 
    loading={loading}
    />
    <OtherCrmUploader/>
  </Box>
  <ImportedPatientsModal
        open={showCsvModal} 
        handleClose={handleModalClose} 
        data={completeCsvData}
        handleDownload={handleCsvDownload}
        path={true}
      />
</Box>
  );
};

export default ImportClaims;