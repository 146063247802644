import axios from "axios";
import toast from "react-hot-toast";
import { API_BASE_URL_NEW } from "../../utils/staticData";
import { userPostAxios } from "../userApis";
const API_BASE_URL = process.env.REACT_APP_API_URL;

export const getNpiDataByNpiNumber = async (requestData) => {
    try {
      const res = await userPostAxios(`${API_BASE_URL_NEW}/api/Npi/GetNpiDataByNpiNumber?npiNumber=${requestData}`,requestData)
      return res;
    } catch (err) {
      toast?.error(err?.response?.data?.detail ?? 'Failed to find details')
    }
  };
