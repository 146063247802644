import React, { useState, useEffect } from 'react';
import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import { sharedStyles } from '../../sharedStyles';

const DebouncedSearch = ({ onSearchChange, placeHolder, sx }) => {
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const handler = setTimeout(() => {
            onSearchChange(searchTerm);
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [searchTerm, onSearchChange]);

    return (
        <Box
            sx={sharedStyles?.horizonatalItems}
        >
            <TextField
                color='success'
                label="Search"
                variant="outlined"
                size="small"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                fullWidth
                placeholder={placeHolder || 'Search'}
                sx={{ ...sx }}
                InputProps={{
                    sx: { ...sharedStyles?.p1},
                    startAdornment: (
                        <InputAdornment position="start">
                            <IconButton aria-label="search">
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
            <IconButton>
                <FilterListIcon />
            </IconButton>
        </Box>
    );
};

export default DebouncedSearch