import { colors } from "../../utils/colors";

export const styles = {
    graphContainer: {
        justifyContent: 'space-between', mb: 2
    },
    cardValue: {
        fontWeight: 'bold'
    },
    cardText: { marginY: 2 },
    discoveryTablesContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
        paddingBottom:'35px'
    },
    scheduledActionsContainer: {
        gap: 2,
        justifyContent: 'flex-end',
        marginTop: 0.5
    },
    detailsButton: {
        backgroundColor: colors?.green,
        border: 'none',
        color: colors?.white,
        fontSize: 'small'
    },
    statusStyle: {
        backgroundColor: colors?.white,
        color: colors?.themeGreen,
    },
    formHelperStyles: {
        color: colors?.errorColor
    },
  
    container: {
        minHeight: '100vh',
        padding: '16px',
        '@media (min-width: 768px)': {
          padding: '24px',
        },
      },
      headerContent: {
        gap: '16px',
        backgroundColor: colors?.white,
        padding: '16px',
        borderRadius: '8px',
        boxShadow: `0px 2px 10px ${colors?.lightBlack}`,
          margin: '0 auto',
        marginBottom: '24px',
      },
      headerText: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
      },
      buttonContainer: {
        display: 'flex',
        gap: '8px',
      },
      card: {
        padding: '32px',
        backgroundColor: colors?.white,
        boxShadow: `0px 2px 10px ${colors?.lightBlack}`,
        display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '24px'
      },
      cardIconContainer: {
        width: '80px',
        height: '80px',
        borderRadius: '50%',
        backgroundColor: colors?.bgGray,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      cardIcon: {
        fontSize: '40px',
        color: colors?.shadedGray,
      },
      cardTextContainer: {
        textAlign: 'center',
        marginTop: '16px',
        marginBottom: '24px',
      },
      cardTitle: {
        fontSize: '24px',
        fontWeight: '600',
        color: colors?.bgash,
      },
      cardDescription: {
        fontSize: '16px',
        color: colors?.descriptionColor,
        maxWidth: '400px',
        margin: '0 auto',
      },
      commonPaperContainer:{ 
        mb: 4, 
        p: 2, 
        border: `1px solid ${colors?.lightGrayBorder}`, 
        borderRadius: 1 
    },
    parentPaperContainer:{ 
        p: 4, 
        width: '80%', 
        mx: 'auto', 
        my: 4
     }
      

}