export const colors = {
    themeGreen: '#5E8A75',
    lightGray: '#F8F8FF',
    gray: '#E5E5EF',
    danger: 'red',
    lightGreen: 'rgba(54, 162, 235, 0.2)',
    lightYellow: 'rgba(255, 206, 86, 0.2)',
    yellow: 'rgba(255, 206, 86, 1)',
    lightRed: 'rgba(255, 99, 132, 0.2)',
    black: '#000',
    surface: 'lightgray',
    surfaceLighter: '#eee',
    white: '#fff',
    themeBlue: '#1976d2',
    themeDarkBlue: '#0000003b0',
    themeDarkGrayishBlue: '#919EAB',
    red: '#cf3917',
    shadow: '#00000029',
    green: 'green',
    snowwhite: '#f9f9f9',
    lightBlack: 'rgba(0, 0, 0, 0.1)',
    lightGrayBorder: '#e0e0e0',
    darkGray: '#333',
    darkGreen: '#218838',
    darkerGreen: '#004d00',
    lightGrayDisabled: '#ccc',
    dangerRed: '#dc3545',
    darkDangerRed: '#c82333',
    darkText: '#444',
    mediumGrayText: '#666',
    errorRed: '#d32f2f',
    fieldGray: '#00000099',
    border: '#0000003b',
    lowOpBlack: 'rgba(0, 0, 0, 0.38)',
    bgred: 'rgba(0, 0, 0, 0.5)',
    bgblue:'#007bff',
    bglightblue:' #0056b3',
    bgwhite:'#f4f7f9',
    bgbox:'0 4px 20px rgba(0, 0, 0, 0.15)',
    bgash:'#2c3e50',
    h3color:'#34495e',
    whitebor:'1px solid #ccc',
    bluebor:'#3498db',
    errorColor: '#d32f2f',
    disabledInput :'#e9ecef',
    gray:'gray',
    grayShadow:"#f6f6f6",
    inputBorder: '#c4c4c4',
    errorFieldHelper: '#d32f2f',
    mattBlack:'#1C1C1E',
    lightMattBlack:'#2C2C2E',
    ligthGrayMatt:'#3A3A3C',
    themeLightBlue:'#3b82f6',
    blue:'#2563eb',
    shadow1: 'rgba(0,0,0,0.56)',
    mainPageDark: '#343434',
    mainPageDarkLight: '#343434',
    mainPageDarker: '#0d0d0d',
    defaultMuiTextBorder:'#ced4da',
    muiThemeBlue:'#1976d2',
    borderGray:'#cccccc',
    shadedBorderGray:'#e6e6e6',
    softGray:'#f2f2f2',
    mediumGray:'#999999',
    darkGray:'#1d1d1d',
    shadedGray:'#9e9e9e',
    bgGray:'#f1f3f5',
    descriptionColor:'#7F8C8D',
    tableGray:'#f5f5f5',
    tableHoveredGray:'#e3e3e3',
    tableHeadBg:'#4CAF50'
};

