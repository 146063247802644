
import React, { useEffect, useState } from 'react'
import BoldText from '../../components/Texts/BoldText'
import { checkScheduleTaskNow, getScheduledTransactions, handleAction, handleCancelTask } from './DiscoveryFunctions';
import ScheduledTransactionsTable from './ScheduledTransactionsTable';
import FieldEditModal from '../../components/modals/FieldEditModal';
import dayjs from 'dayjs';
import TableHeader from '../../components/TableHeader';
import { filterRowData } from '../Claims/ClaimFunctions';
import ScheduledTransactionsTableUnPaginated from './ScheduledTransactionsTableUnpaginated';
import HorizontalBox from '../../components/shared/HorizontalBox';
import { Button } from '@mui/material';
import { sharedStyles } from '../../sharedStyles';

const ScheduledTransactions = () => {
  const [rowData, setRowData] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false)
  const [newScheduleDate, setNewScheduleDate] = useState('')
  const [defaultScheduleDate, setDefaultScheduleDate] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [idToEdit, setIdToEdit] = useState('')
  const [isEditing, setIsEditing] = useState(false)
  const [isChecking, setIsChecking] = useState(false)
  const [taskToBeHandled, setTaskToBeHandled] = useState('')
  const [isCancelling, setIsCancelling] = useState(false)
  const [filterPatient, setFilterPatient] = useState('')
  const [refetchApi, setRefetchApi] = useState(false)
  const [noDataInTable, setNoDataInTable] = useState(false)
  const [showAllDataTable, setShowAllDataTable] = useState(false)

  return (
    <div>
      <BoldText text={`Scheduled Transactions ${noDataInTable ? '(0 records found)' : ''}`} />
      <TableHeader setFilterPatient={setFilterPatient} />
      {!showAllDataTable ?
        <ScheduledTransactionsTable
          action={({ data }) => {
            setIdToEdit(data?.id)
            setShowEditModal(true)
            setNewScheduleDate(dayjs(data?.scheduleDate))
            setDefaultScheduleDate(dayjs(data?.scheduleDate))
          }}
          cancelTask={async ({ data }) => await handleCancelTask(data?.id, setIsCancelling, setRefetchApi, refetchApi)}
          checkTaskNow={async ({ data }) => await checkScheduleTaskNow(data?.patientId, setIsChecking, setRefetchApi, refetchApi)}
          isLoading={isLoading}
          isChecking={isChecking}
          setTaskToBeHandled={setTaskToBeHandled}
          taskToBeHandled={taskToBeHandled}
          isCancelling={isCancelling}
          refetchApi={refetchApi}
          setNoDataInTable={setNoDataInTable}
          setRefetchApi={setRefetchApi}
        />
        :
        <ScheduledTransactionsTableUnPaginated
          action={({ data }) => {
            setIdToEdit(data?.id)
            setShowEditModal(true)
            setNewScheduleDate(dayjs(data?.scheduleDate))
            setDefaultScheduleDate(dayjs(data?.scheduleDate))
          }}
          cancelTask={async ({ data }) => await handleCancelTask(data?.id, setIsCancelling, setRefetchApi, refetchApi)}
          checkTaskNow={async ({ data }) => await checkScheduleTaskNow(data?.patientId, setIsChecking, setRefetchApi, refetchApi)}
          isLoading={isLoading}
          isChecking={isChecking}
          setTaskToBeHandled={setTaskToBeHandled}
          taskToBeHandled={taskToBeHandled}
          isCancelling={isCancelling}
          refetchApi={refetchApi}
          setNoDataInTable={setNoDataInTable}
          setRefetchApi={setRefetchApi}
        />}
      <FieldEditModal
        open={showEditModal}
        handleClose={() => setShowEditModal(false)}
        fiedlName={'Schedule Date'}
        action={async () => await handleAction(setShowEditModal, setNewScheduleDate, setDefaultScheduleDate, idToEdit, newScheduleDate, setIsEditing, setRefetchApi, refetchApi)}
        value={newScheduleDate}
        handleChange={(value) => { setNewScheduleDate(value) }}
        defaultValue={defaultScheduleDate}
        isLoading={isEditing}
        calendar
      />
      {!noDataInTable ?
            <HorizontalBox sx={sharedStyles?.justifyEnd}>
              <Button
            variant='contained'
            color='success'
            sx={sharedStyles?.mt10px}
            onClick={() =>setShowAllDataTable(true)}
            disabled={isLoading}
          >
            PROCESS
          </Button>
            </HorizontalBox>
          :
          null
          }
    </div>
  )
}

export default ScheduledTransactions