import React, { useContext } from 'react'
import { BcbsIllinoisHsContext } from '../../../../../context/priorAuthorizationForms/BcbsIllinoisContext'
import DefaultBcbsRenderingProviders from '../../components/Bcbs/defaultBcbsRenderingProviders'

const RenderingProvidersBcbsIllinoisHsForm = () => {
    const {
        renderingProviders,
        setRenderingProviders,
        setSelectedTab,
        defaultRenderingProvider,
        onceCompleted
    } = useContext(BcbsIllinoisHsContext)
    return (
        <DefaultBcbsRenderingProviders
            renderingProviders={renderingProviders}
            setRenderingProviders={setRenderingProviders}
            setSelectedTab={setSelectedTab}
            defaultRenderingProvider={defaultRenderingProvider}
            onceCompleted={onceCompleted}
        />
    )
}

export default RenderingProvidersBcbsIllinoisHsForm