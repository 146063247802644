import { useContext } from "react"
import { AmerigroupContext, AmerigroupContextProvider } from "../../../../../context/priorAuthorizationForms/AmerigroupArContext"
import AmeriGroupPatientForm from "./AmeriGroupPatientForm"
import AmerigroupSubscribersForm from "./AmerigroupSubscribersForm"
import AmerigroupDiagnosisForm from "./AmerigroupDiagnosisForm"
import AmerigroupProceduresForm from "./AmerigroupProceduresForm"
import AmerigroupAdmissionForm from "./AmerigroupAdmissionForm"
import AmerigroupProviderNotes from "./AmerigroupProviderNotes"
import AmerigroupRenderingProviders from "./AmerigroupRenderingProviders"
import AmerigroupRequestingProvider from "./AmerigroupRequestingProvider"
import FormDetailsNew from "../../components/FormDetailsNew"


const AmeriGroupForm = ({ patientDetails }) => {


    return (
        <AmerigroupContextProvider>
            <AllAmeriGroupForms />
        </AmerigroupContextProvider>
    )
}

const AllAmeriGroupForms = () => {
    const { selectedTab } = useContext(AmerigroupContext)
    const { patientInfo,
        diagnosesInfo,
        requestingProvider,
        renderingProviders,
        subscriberInfo,
        procedureInfo,
        admissionInfo,
        providerNotes,
        onceCompleted,
        handleSubmit, setSelectedTab, isLoading } = useContext(AmerigroupContext)

    const forms = [
        <AmeriGroupPatientForm />,
        <AmerigroupSubscribersForm />,
        <AmerigroupDiagnosisForm />,
        <AmerigroupProceduresForm />,
        <AmerigroupAdmissionForm />,
        <AmerigroupProviderNotes />,
        <AmerigroupRenderingProviders />,
        <AmerigroupRequestingProvider />,
        <FormDetailsNew
            data={
                {
                    patientInfo,
                    diagnosesInfo,
                    requestingProvider,
                    renderingProviders,
                    subscriberInfo,
                    procedureInfo,
                    admissionInfo,
                    providerNotes,
                    onceCompleted,
                    handleSubmit,
                }
            }
            setSelectedTab={setSelectedTab}
            isLoading={isLoading}
            onSubmit={handleSubmit}
            title={'Form Details'}


        />
    ]


    return forms[selectedTab] || null;
}


export default AmeriGroupForm