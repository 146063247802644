import React, { useContext, useState } from 'react'
import { bcbsServiceTypes } from '../../../../../utils/staticData'
import { BcbsIllinoisHsContext } from '../../../../../context/priorAuthorizationForms/BcbsIllinoisContext'
import ImpServicesType from '../../components/Bcbs/impServiceType'

const ServicesTypeBcbsIllinoisHsForm = () => {
    const {setSelectedTab, serviceType, setServiceType, onceCompleted,setOnceCompleted} = useContext(BcbsIllinoisHsContext)
     const [errors, setErrors] = useState({serviceType:false})
  return (
   <ImpServicesType
   errors={errors}
   setErrors={setErrors}
   setSelectedTab={setSelectedTab}
   serviceType={serviceType}
   setServiceType={setServiceType}
   onceCompleted={onceCompleted}
   serviceTypes={bcbsServiceTypes}
   noBack
   onComplete={() => onceCompleted && setOnceCompleted(false)}
  
   />
  )
}

export default ServicesTypeBcbsIllinoisHsForm