import React, { createContext, useContext, useState } from 'react';

const ClaimsTableContext = createContext();

export const useClaimsTable = () => {
  return useContext(ClaimsTableContext);
};

// Provider component
export const ClaimsTableProvider = ({ children }) => {
  const [refetchClaimsTable, setRefetchClaimsTable] = useState(0);


  return (
    <ClaimsTableContext.Provider value={{ refetchClaimsTable, setRefetchClaimsTable }}>
      {children}
    </ClaimsTableContext.Provider>
  );
};
