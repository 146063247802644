import React, { useContext, useState } from 'react'
import { bcbsDefPos, bcbsServiceTypes, placeOfServicesForBcbsServices, serviceInfoErrorsAnthem } from '../../../../../utils/staticData'
import ServicesInfoBcbsForm from '../../components/Bcbs/ServicesInfoBcbsForm'
import { BcbsIllinoisHsContext } from '../../../../../context/priorAuthorizationForms/BcbsIllinoisContext'

const ServicesInfoBcbsHsForm = () => {
    const {setSelectedTab, serviceInfo, setServiceInfo, onceCompleted,serviceType} = useContext(BcbsIllinoisHsContext)
    const [errors, setErrors] = useState(serviceInfoErrorsAnthem)
    const servicTypeCode = bcbsServiceTypes?.find(e => e?.value === serviceType?.serviceType)?.code
  return (
   <ServicesInfoBcbsForm
   errors={errors}
   setErrors={setErrors}
   setSelectedTab={setSelectedTab}
   serviceInfo={serviceInfo}
   setServiceInfo={setServiceInfo}
   onceCompleted={onceCompleted}
   placeOfServices={placeOfServicesForBcbsServices?.find(e => e?.conditionCode?.includes(servicTypeCode))?.vals ||  bcbsDefPos}
   />
  )
}

export default ServicesInfoBcbsHsForm