import axios from "axios";
import toast from "react-hot-toast";
import { API_BASE_URL } from "../utils/staticData.js"
import { userGetAxios } from "./userApis.js";



export const getUser = async (requestData) => {
    try {
        const res = await userGetAxios(process.env.REACT_APP_API_URL_NEW + `/api/UserRegistration/GetUserByUserId?userId=${requestData}`,true)
        return res
    } catch (error) {
        toast?.error(error?.response?.data?.detail ?? 'Failed to get User')
        console.log(error)
    }
}