import React, { useState, useCallback, useRef } from 'react';
import {
    Box,
    Typography,
    Paper,
    Link,
    Button,
    CircularProgress,
} from '@mui/material';
import { FileUpload, Upload } from '@mui/icons-material';
import toast from 'react-hot-toast';
import { colors } from '../../utils/colors';
import { sharedStyles } from '../../sharedStyles';
import { styles } from './styles';

const DefaultFileUploaderCard = ({ onFileSelect, downloadSample, loading }) => {
    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef(null);

    const handleDragOver = useCallback((e) => {
        e.preventDefault();
        setIsDragging(true);
    }, []);

    const handleDragLeave = useCallback((e) => {
        e.preventDefault();
        setIsDragging(false);
    }, []);

    const handleDrop = useCallback(
        (e) => {
            e.preventDefault();
            setIsDragging(false);

            const files = Array.from(e.dataTransfer.files);
            if (files.length > 0) {
                const file = files[0];
                const fileExtension = file.name.split(".").pop()?.toLowerCase();

                if (["csv"].includes(fileExtension || "")) {
                    onFileSelect(file);
                } else {
                    toast.error("File not uploaded")
                }
            }
        },
        [onFileSelect, toast]
    );

    const handleFileInput = useCallback(
        (e) => {
            const files = e.target.files;
            if (files && files.length > 0) {
                onFileSelect(files[0]);
                fileInputRef.current.value = '';
            }
        },
        [onFileSelect, toast]
    );

    return (
        <Paper
             sx={styles?.commonPaperContainer}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
        >
            <Box 
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            gap={2}
            >
                <Box 
                width={48}
                height={48}
                borderRadius={'50%'}
                bgcolor={colors?.bgGray}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                >
                    <FileUpload sx={sharedStyles?.greenColor} />
                </Box>

                <Box>
                    <Typography variant="h6" gutterBottom>
                        Drag and drop your file here
                    </Typography>
                    <Typography color="text.secondary" gutterBottom>
                        - or -
                    </Typography>
                </Box>

                <Button
                    color='success'
                    component="label"
                    variant="contained"
                    startIcon={<Upload />}
                    disabled={loading}
                >
                    {loading ? <CircularProgress color='inherit' size={17}/> : 'Browse'}
                    <input
                    ref={fileInputRef}
                        type="file"
                        hidden
                        accept=".csv"
                        onChange={handleFileInput}
                    />
                </Button>

                <Box sx={sharedStyles?.mt2px}>
                    <Typography>
                        Download sample file
                    </Typography>
                    <Typography variant="body2" color="text.secondary" onClick={downloadSample}
                        sx={sharedStyles?.cursorPointer}
                    >
                        CSV
                    </Typography>

                </Box>

                <Typography variant="body2" color="text.secondary" sx={{ mt: 4 }}>
                    You can import up to 5000 records through a .csv file.
                </Typography>
            </Box>
        </Paper>
    );
};

export default DefaultFileUploaderCard;