import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import DetailCard from './DetailCard';
import InfoRow from './InfoRow';
import CommonNextButton from './CommonNextButton';
import { monthDyFormat } from '../../../../utils/DateFunctions';
import { styles } from './styles';
import { capitalizedFirstLetter } from '../../../../utils/reusableFunctions';


const FormDetails = ({ data, setSelectedTab, onSubmit, isLoading, title,tabNums }) => {
  return (
    <Container maxWidth="lg" sx={styles.formDeatilsContainer}>
      <Box sx={styles?.formDeatilsMainBox}>
        <Typography
          variant="h4"
          sx={styles?.formDeatilsHeading}
        >
          {`Form Information ${title ?? ''}`}
        </Typography>
        {data?.patient && (
          <DetailCard title="Patient Details" onEdit={()=>setSelectedTab(tabNums?.patient)}>
            <InfoRow
              label="Name"
              value={`${capitalizedFirstLetter(data?.patient?.firstName) || ''} ${capitalizedFirstLetter(data?.patient?.lastName) || ''} ${data?.patient.suffix?.toUpperCase() || ''}`}
            />
            <InfoRow label="Birth Date" value={monthDyFormat(data?.patient.birthDate)} />
            <InfoRow label="Gender" value={data?.patient.genderCode} />
            <InfoRow label="Relationship" value={data?.patient.subscriberRelationshipCode} />
            {data?.patient?.conditionCode &&  <InfoRow label="Condition" value={data?.patient.conditionCode} />}
            {data?.patient?.prognosisCode &&  <InfoRow label="Prognosis" value={data?.patient.prognosisCode} />}
          </DetailCard>
        )}
        {data?.subscriberInfo && (
          <DetailCard title="Subscriber Information" onEdit={()=>setSelectedTab(tabNums?.subscriberInfo)}>
            <InfoRow
              label="Name"
              value={`${capitalizedFirstLetter(data?.subscriberInfo?.firstName) || ''} ${capitalizedFirstLetter(data?.subscriberInfo?.lastName) || ''} ${data?.subscriberInfo.suffix?.toUpperCase() || ''}`}
            />
            <InfoRow label="Member ID" value={data?.subscriberInfo.memberId} />
          </DetailCard>
        )}
        {data?.diagnose && data?.diagnose.length > 0 && (
          <DetailCard title="Diagnoses" onEdit={()=>setSelectedTab(tabNums?.diagnosesInfo)}>
            {data?.diagnose.map((diag, index) => (
              <Box key={diag.id || index} sx={{ mb: index !== data?.diagnose.length - 1 ? 2 : 0 }}>
                <InfoRow label="ICD Code" value={diag?.icdCode} />
                {diag?.qualifierCode && <InfoRow label="Diagnose's Qualifier Code" value={diag?.qualifierCode ?? '--'} />}
                <InfoRow label="Description" value={diag?.fullDescription} />
                <InfoRow label="Long Description" value={diag?.longDescription} />
                {diag?.shortDescription && <InfoRow label="Short Description" value={diag?.shortDescription} />}
                {diag?.date && <InfoRow label="Date" value={monthDyFormat(diag?.date) ?? '--'} />}
                {index !== data?.diagnose?.length - 1 && <p>---</p>}
              </Box>
            ))}
          </DetailCard>
        )}
        {data?.procedure && data?.procedure.length > 0 && (
          <DetailCard title="Procedures" onEdit={()=>setSelectedTab(tabNums?.proceduresInfo)}>
            {data?.procedure?.map((proc, index) => (
              <Box key={proc.id || index} sx={{ mb: index !== data?.procedure.length - 1 ? 2 : 0 }}>
                <InfoRow label="CPT Code" value={proc.CptCode} />
                {proc?.qualifierCode && <InfoRow label="Procedure's Qualifier Code" value={proc?.qualifierCode ?? '--'} />}
                <InfoRow label="Description" value={proc.fullDescription} />
                <InfoRow label="Long Description" value={proc?.longDescription ?? '--'} />
                {proc?.shortDescription && <InfoRow label="Short Description" value={proc?.shortDescription ?? '--'} />}
                {proc?.fromDate && <InfoRow label="From Date" value={monthDyFormat(proc?.fromDate) ?? '--'} />}
                {proc?.toDate && <InfoRow label="To Date" value={monthDyFormat(proc?.toDate) ?? '--'} />}
                {proc?.quantity && <InfoRow label="Quantity" value={proc?.quantity ?? '--'} />}
                {proc?.quantityTypeCode && <InfoRow label="Quantity Type" value={proc?.quantityTypeCode ?? '--'} />}
                {proc?.timePeriodQuantity && <InfoRow label="Time Period Quantity" value={proc?.timePeriodQuantity ?? '--'} />}
                {proc?.timePeriodQuantityQualifier && <InfoRow label="Time Period Quantity Qualifier" value={proc?.timePeriodQuantityQualifier ?? '--'} />}
                {proc?.timeframeFrequency && <InfoRow label="Time Frame Quantity" value={proc?.timeframeFrequency ?? '--'} />}
                {proc?.timeFrameFrequencyQualifier && <InfoRow label="Time Frame Quantity Qualifier" value={proc?.timeFrameFrequencyQualifier ?? '--'} />}

                {index !== data?.procedure?.length - 1 && <p>---</p>}
              </Box>
            ))}
          </DetailCard>
        )}
        {data?.serviceTypeCode && (
          <DetailCard title="Service Type Information" onEdit={()=>setSelectedTab(tabNums?.serviceTypeCode)}>
            <InfoRow label="Service Selected" value={data?.serviceTypeCode} />
          </DetailCard>
        )}
        {data?.serviceInfo && (
          <DetailCard title="Service Information" onEdit={()=>setSelectedTab(tabNums?.serviceInfo)}>
            <InfoRow label="Place of Service" value={data?.serviceInfo.placeOfServiceCode} />
            {(data?.serviceType) && <InfoRow label="Service Type" value={data?.serviceType} />}
            {data?.serviceInfo?.serviceLevel && <InfoRow label="Service Level" value={data?.serviceInfo?.serviceLevel} />}
            {data?.serviceInfo?.reportType && <InfoRow label="Report Type" value={data?.serviceInfo?.reportType} />}
            <InfoRow label="Quantity" value={data?.serviceInfo.quantity} />
            <InfoRow label="Quantity Type" value={data?.serviceInfo.quantityTypeCode} />
          </DetailCard>
        )}
        {data?.requestingProvider && (
          <DetailCard title="Requesting Provider" onEdit={()=>setSelectedTab(tabNums?.requestingProvider)}>
            <InfoRow
              label="Name"
              value={`${capitalizedFirstLetter(data?.requestingProvider?.firstName) || ''} ${capitalizedFirstLetter(data?.requestingProvider?.lastName) || ''}`}
            />
             <InfoRow
              label="Contact Name"
              value={`${capitalizedFirstLetter(data?.requestingProvider?.contactName) || ''}`}
            />
            <InfoRow label="NPI" value={data?.requestingProvider?.npi} />
            <InfoRow label="Role Code" value={data?.requestingProvider?.roleCode} />
            <InfoRow label="Phone" value={data?.requestingProvider?.phone} />
            <InfoRow
              label="Address"
              value={`${data?.requestingProvider?.addressLine1 || ''} ${data?.requestingProvider?.addressLine2 ? `(${data?.requestingProvider?.addressLine2})` : ''}`}
            />
            <InfoRow
              label="Location"
              value={`${capitalizedFirstLetter(data?.requestingProvider?.city) || ''} ${data?.requestingProvider?.stateCode ? `,${data?.requestingProvider?.stateCode}` : ''} ${data?.requestingProvider?.zipCode || ''}`}
            />
          </DetailCard>
        )}
        {data?.renderingProvider?.length &&
          data?.renderingProvider?.map(elem => {
            return (
              <DetailCard title="Rendering Provider" onEdit={()=>setSelectedTab(tabNums?.renderingProviders)}>
                <InfoRow
                  label="Name"
                  value={`${capitalizedFirstLetter(elem?.firstName) || ''} ${capitalizedFirstLetter(elem?.lastName) || ''}`}
                />
                <InfoRow
                  label="Provider Type"
                  value={`${elem?.roleCode}`}
                />
                <InfoRow label="NPI" value={elem?.npi} />
                <InfoRow label="Phone" value={elem?.phone} />
                <InfoRow
                  label="Address"
                  value={`${elem?.addressLine1 || ''} ${elem?.addressLine2 || ''}`}
                />
                <InfoRow
                  label="Location"
                  value={`${capitalizedFirstLetter(elem?.city) || ''} ${elem?.stateCode ? `,${elem?.stateCode}` : ''} ${elem?.zipCode || ''}`}
                />
              </DetailCard>
            )
          }
          )}
        <DetailCard title="Provider Notes" onEdit={()=>setSelectedTab(tabNums?.providerNotes)}>
        {data?.providerNote?.length && (
          data?.providerNote?.map((elem) => {
            return (
              
                <Typography
                  sx={styles?.formDetailsProviderNotes}
                >
                  {capitalizedFirstLetter(elem || '--')}
                </Typography>
             
            )
          })
        )}
         </DetailCard>
        {data?.additionalServices && (
          <DetailCard title="Additional Services Information" onEdit={()=>setSelectedTab(tabNums?.additionalServices)}>
            <InfoRow label="Place of Service" value={data?.additionalServices.type?.join(',')} />
            <InfoRow label="Nursing Home Residential Status" value={data?.additionalServices?.nursingCode} />
          </DetailCard>
        )}

        {data?.admissionInfo && (
          <DetailCard title="Admission Information" onEdit={()=>setSelectedTab(tabNums?.admissionInfo)}>
            <InfoRow label="Admission Type" value={data?.admissionInfo?.type} />
            <InfoRow label="Admission Source" value={data?.admissionInfo?.source} />
          </DetailCard>
        )}

        {data?.datesInfo && (
          <DetailCard title="Dates Information" onEdit={()=> setSelectedTab(tabNums?.datesInfo)}>
            <InfoRow label="From Date" value={monthDyFormat(data?.datesInfo?.fromDate)} />
            <InfoRow label="To Date" value={monthDyFormat(data?.datesInfo?.toDate)} />
            {data?.datesInfo?.homeHealthStartDate &&  <InfoRow label="Home Health Start Date" value={monthDyFormat(data?.datesInfo?.homeHealthStartDate)} />}
          </DetailCard>
        )}
        <CommonNextButton handleSubmit={() =>  onSubmit ? onSubmit() :null} setSelectedTab={setSelectedTab} nextButtonText={'Submit'} isLoading={isLoading} />
      </Box>
    </Container>
  );
};

export default FormDetails;
