import React, { useEffect, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom';
import PriorAuthTable from '../Eligibility/PriorAuthTable';
import PaForm from '../Eligibility/PaForm';
import NewSubmitAvaility from '../Eligibility/NewSubmitAvaility';
import { Box } from '@mui/material';
import MyTabs from '../../components/tabs/MyTabs';
import { sharedStyles } from '../../sharedStyles';


const PriorAuthTab = () => {
    const [searchParams] = useSearchParams();
    const preSelectedTabParam = searchParams.get('preSelectedTab');
    const [preSelectedTab, setPreSelectedTab] = useState(preSelectedTabParam ? Number(preSelectedTabParam) : 0);
    const location = useLocation()
     
    const tabs =
    [
       
        {
            label: "PA Table", value: <PriorAuthTable />,  button: 'New Search', changeTab: 1,
        },
        {
            label: "Submit PA", 
            value:<PaForm/>,
            changeTab: 0, button: 'Back',
        },
        {
            label: "Search PA", 
                value: <NewSubmitAvaility/>,
            changeTab: 0, button: 'Back',
        },
    ]


    useEffect(() => {
        setPreSelectedTab(preSelectedTabParam ? Number(preSelectedTabParam) : 0);
    }, [preSelectedTabParam]);
    return (
        <section className='Eligibility' key={location?.key}>
            <Box sx={sharedStyles?.tabWrapper}>
                <MyTabs tabs={tabs} preSelectedTab={preSelectedTab} />
            </Box>
        </section>
    )
}
export default PriorAuthTab
