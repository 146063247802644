import React, { useState } from 'react'
import DefaultFileUploaderCard from '../../components/fileUploaderCards/DefaultFileUploaderCard'
import { Box } from '@mui/material'
import { bulk_Patient, get_GenerateEmptyCSVAsync } from "../../services/addPatient";
import { DownloadCSV } from "../../utils/reusableFunctions.js"
import toast from 'react-hot-toast';
import ImportedPatientsModal from './ImportedPatientsModal.jsx';
import OtherCrmUploader from '../../components/fileUploaderCards/OtherCrmUploader.jsx';

const ImportPatient = () => {
  const [completeCsvData, setCompleteCsvData] = useState([])
  const [showCsvModal, setShowCsvModal] = useState(false)
  const [csvToDownload, setCsvToDownload] = useState(null)
  const [loader,setLoader] = useState({importing:false})

    const generateCSV = async () => {
    try {
      const response = await get_GenerateEmptyCSVAsync();
      if (response?.csvFileByteArray) {
        DownloadCSV(response?.csvFileByteArray, "Upload_Patient_Template.csv");
      } else {
        toast('Error while getting empty csv file');
      }
    } catch (error) {
      console.error('Error generating CSV file:', error);
    }
  };

    const handleChange = (file) => {
    const formData = new FormData();
    formData.append("csvFile", file);
    bulk_Patient(formData,handleCsvToShow,setCsvToDownload,setLoader);
  };

  const handleModalClose = () => {
    setCompleteCsvData([])
    setShowCsvModal(false)
    setCsvToDownload(null)
  }

  const handleCsvToShow = (file) => {
          setCompleteCsvData(file ?? [])
          setShowCsvModal(true)
          setCsvToDownload(file)
  }

  const handleCsvDownload = () => {
    csvToDownload &&  DownloadCSV(csvToDownload, `Downloaded_Response (${new Date().getUTCDate()}-${new Date().getUTCMonth() + 1}-${new Date().getUTCFullYear()})?.csv`);
    handleModalClose()
  }
  
  return (
    
    <Box>
        <Box
        display={'flex'}
        justifyContent={'center'}
        gap={4}
        sx={{
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: { xs: 'center', md: 'normal' }
        }}
      >
        <DefaultFileUploaderCard onFileSelect={handleChange} downloadSample={generateCSV} loading={loader?.importing}/>
        <OtherCrmUploader/>
      </Box>
       <ImportedPatientsModal
        open={showCsvModal} 
        handleClose={handleModalClose} 
        data={completeCsvData}
        handleDownload={handleCsvDownload}
      />
    </Box>
  )
}

export default ImportPatient