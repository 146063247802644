import axios from 'axios';
import { toast } from 'react-hot-toast';
import { getLocalData } from "../utils/localStorageFunctions";
import { API_BASE_URL_NEW } from '../utils/staticData';
import { userGetAxios, userPostAxios } from './userApis';
const API_BASE_URL = process.env.REACT_APP_API_URL;
const doctorId = getLocalData('selectedNpi')?.npiNumber ?? '';

export const fetchSchedule = async () => {
    try {
        const response = await userGetAxios(`${API_BASE_URL_NEW}/api/Doctor/GetDoctorById/${doctorId}`)
        return response;
    } catch (error) {
        console.error('Error fetching schedule:', error);
        // toast.error('Failed to fetch schedule');
    }
};

export const saveSchedule = async (schedule) => {
    try {
        const response = await userPostAxios(`${API_BASE_URL_NEW}/api/Doctor/AddDoctor`, schedule)
        return response.data;
    } catch (error) {
        console.error('Error saving schedule:', error);
        toast.error('Failed to save schedule');
    }
};

export const fetchDoctorInfo = async () => {
    try {
        const response = await userGetAxios(`${API_BASE_URL_NEW}/api/Doctor/GetDoctorById/${doctorId}`)
        return response;
    } catch (error) {
        console.error('Error fetching doctor information:', error);
        // toast.error('Failed to fetch doctor information');
    }
};