import { colors } from "./utils/colors";

export const sharedStyles = {
    cursorPointer: { cursor: 'pointer', marginRight: '2em' },
    bm1em: { marginBottom: '1em' },
    horizontalCenteredFlex: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
    filterButton: { color: colors?.themeGreen, border: `1px solid ${colors?.themeGreen}` },
    themeBtn: { background: colors?.themeGreen, color: colors?.white, '&:hover': { background: colors?.themeGreen }, borderRadius: '10px' },
    fullWidth: { width: '100%' },
    justifyBetween: { justifyContent: 'space-between' },
    justifyCenter: { justifyContent: 'center' },
    alignItemsCenter: { alignItems: 'center' },
    mt5px: { marginTop: '5px' },
    mv10px: { marginTop: '10px', marginBottom: '10px' },
    mb2: {
        marginBottom: '2em'
    },
    pshalf: {
        paddingLeft: '0.5em'
    },
    h500: {
        height: '500px'
    },
    primaryButtonStyles: { backgroundColor: 'green', border: 'none', color: 'white', fontSize: 'small' },
    mt2em: {
        marginTop: '2em'
    },
    tabWrapper: {
        boxShadow: '0 0 2px 0 #919EAB',
        borderRadius: '12px'
    },
    mr_3: {
        marginRight: -3
    },
    mr1: {
        marginRight: 1
    },
    ml5: {
        marginLeft: 5
    },
    mr2: {
        marginRight: '1.5em'
    },
    mb1: {
        marginBottom: '1em'
    },
    whiteBtn: {
        background: colors?.white, color: colors?.black, boxShadow: 0, border: `1px solid ${colors?.border}`, '&:hover': {
            backgroundColor: colors?.surface,
            color: colors?.black,
        },
    },
    pv10: {
        paddingBottom: 10,
        paddingTop: 10
    },
    resetButton: {
        color: colors?.white,
        backgroundColor: colors?.red,
        marginRight: 3,
        '&:hover': {
            backgroundColor: colors?.red,
            color: colors?.white,
        },
    },
    fs08: {
        fontSize: '0.8em'
    },
    minWidth120: {
        minWidth: '120px'
    },
    h100vh: {
        height: '100vh'
    },
    backgroundDark: {
        backgroundColor: colors?.mainPageDarker
    },
    backgroundDarkLight: {
        backgroundColor: colors?.mainPageDarkLight
    },
    resetButtonOnly: {
        color: colors?.white,
        backgroundColor: colors?.red,
        '&:hover': {
            backgroundColor: colors?.red,
            color: colors?.white,
        },
    },
    justifyEnd: {
        justifyContent: 'flex-end'
    },
    cursorPointerOnly: { cursor: 'pointer' },
    gap2: { gap: 2 },
    gap1: { gap: 1 },
    displayFlex: { display: 'flex' },
    show: { display: 'block' },
    hide: { display: 'none' },
    flex: { display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '1em' },
    whiteBtnDisabled: {
        background: colors?.white, marginTop: '1em', color: colors?.black, boxShadow: 0, border: `1px solid ${colors?.black}`, cursor: 'not-allowed'
    },
    themeCard: {
        boxShadow: '0 0 2px 0 #919EAB',
        height: '100%',
        borderRadius: '12px',
        background: colors?.themeGreen,
        color: colors?.white,
        padding: '0.8em',
        position: 'relative',
        width: '100%',
    },
    innerThemeCard: {
        marginRight: '1em', marginTop: '1em', marginBottom: '1em'
    },
    responsivePad: {
        padding: ['0.5em', '2em', '']
    },
    badgeStyle: { position: 'absolute', top: '-19px', right: 0, background: colors?.black, borderRadius: '6px', paddingInline: '1em' },
    expiredBadge: {
        background: colors?.danger,
    },
    appHeader: {
        background: '#fff', boxShadow: `2px 2px 13px ${colors?.themeGreen}`, padding: '1em', borderRadius: '12px'
    },
    disabledSelect: {
        backgroundColor: colors?.disabledInput,
        color: colors?.gray
    },
    blackText: {
        color: colors?.black
    },
    relativePosition: {
        position: 'relative'
    },
    z2: {
        zIndex: 2
    },
    topShadow: {
        boxShadow: `0 -4px 2px -2px ${colors?.grayShadow}`
    },
    errorField: {
        border: `1px solid ${colors?.red}`
    },
    borderRadius12: {
        borderRadius: '12px'
    },
    h30: {
        height: '30px'
    },
    wFull: {
        width: '100%'
    },
    mt10px: {
        marginTop: '10px'
    },
    wh100: { width: '100px', height: '100px' },
    colorWhite: {
        color: '#fff'
    },
    mainPageCard: {
        background: colors?.mainPageDark,
        height: '300px',
        width: '500px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '0 3em',
        borderRadius: '5px',
        gap: '1em'
    },
    mainPageImg: {
        width: '150px',
        marginBottom: '20px'
    },
    gap2em: {
        gap: '2em'
    },
    borderRadius: '8px',
    textCenter: {
        textAlign: 'center'
    },
    footerText: {
        color: colors?.fieldGray,
        fontSize: '16px'
    },
    border: {
        border: `1px solid ${colors?.border}`
    },
    py1: {
        padding: '1em 0'
    },
    boxShadow: {
        boxShadow: `-2px 4px 15px 2px ${colors?.shadow1};`
    },
    mainPageBtn: {
        px: 4,
        py: 1.5,
        fontSize: "16px",
        textTransform: "none",
        borderRadius: "8px",
        marginTop: '10px'
    },
    mt4: {
        marginTop: '4em'
    },
    absoluteBottom: {
        position: 'absolute',
        bottom: 0,
        width: '100%'
    },
    footerLayout: {
        display: 'flex', alignItems: 'flex-end', justifyContent: 'center'
    },
    mainChildrenWrapper: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    mainChildren: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    layoutWrapper: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    responsiveTableHeight: {
        height: ['500px', '800px', '500px']
    },
    flexWrap: {
        flexWrap: 'wrap'
    },
    defaultDatePickerStyles: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: colors?.defaultMuiTextBorder,
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: colors?.gray,
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: colors?.muiThemeBlue
        },
        '& .MuiFormLabel-root': {
            color: colors?.gray,
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: colors?.gray,
        },
    },
    errorDatePickerStyles: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: colors?.errorRed,
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: colors?.errorRed,
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: colors?.errorRed,
        },
        '& .MuiFormLabel-root': {
            color: colors?.errorRed,
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: colors?.errorRed,
        },
    },

    errorNajamDatePickerStyles: {

        '& .MuiOutlinedInput-root': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: colors?.errorRed
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: colors?.errorRed
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: colors?.errorRed,
            },
        },
        '& .MuiFormLabel-root': {
            color: colors?.errorRed
        },
        '& .MuiInputLabel-root.Mui-focused': {
            color: colors?.errorRed,
        }

    },

mb4: {
        marginBottom: '4px'
    },
    errorText: { color: colors?.errorColor },
    muiCheckedThemeColor: {
        '&.Mui-checked': {
            color: colors?.green,
        },

    },
    iconCell: {
        borderRadius: '8px', marginBottom: '0.5em'
    },
    detaislCard: {
        boxShadow: '0px 4px 8px #5E8A75', borderRadius: 2, bgcolor: 'white', height: 130, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 2, cursor: 'pointer', px: 2
    },
    horizonatalItems: { display: 'flex', flexDirection: 'row', gap: 2 },
    p1: { padding: 1 },
    mv1: { marginTop: 1, marginBottom: 1 },
    greenColor: { color: colors?.green },
    bb0: { borderBottom: '0px' },
    mt2px: { marginTop: 2 },
    defaultGreenDatePickerStyles: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: colors?.defaultMuiTextBorder,
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: colors?.gray,
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: colors?.green
        },
        '& .MuiFormLabel-root': {
            color: colors?.gray,
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: colors?.gray,
        },
    },

    tabsBoxContainer: {
        boxShadow: `0 0 2px 0 ${colors?.themeDarkGrayishBlue}`,
        borderRadius: '12px'
    },
    w25percent: {
        width: '25%'
    },
    tabsBox: {
        display: 'flex',
        alignItems: 'center',
        gap: '1.2em',
    }
}