import React, { useContext, useState } from 'react'
import {  getAllCPTcodes } from '../../../../../services/pVerify'
import { bcbsIllinoisProceduresQualifierCodes, formHelperTexts, paFormErrors, quantityTypeOpsBcbsIllinois } from '../../../../../utils/staticData'
import toast from 'react-hot-toast'
import DefaultAetnaExtendedProceduresForm from '../../components/Aetna/DefaultAetnaExtendedProceduresForm'
import { BcbsIllinoisHsContext } from '../../../../../context/priorAuthorizationForms/BcbsIllinoisContext'
import dayjs from 'dayjs'

const ProceduresBcbsIllinoisHsForm = () => {
    const { proceduresInfo, setProceduresInfo, setSelectedTab, onceCompleted } = useContext(BcbsIllinoisHsContext)
    const [errors, setErrors] = useState([])
    const today = dayjs()

    const fetchData = async () => {
        const data = await getAllCPTcodes();
        return data?.data?.data || [];
    };

    const handleNext = (submit) => {
        if(proceduresInfo?.length ){
            const inCompleteProcedures = proceduresInfo?.filter(e =>   !e?.qualifierCode || !e?.quantityTypeCode || !e?.quantity)
            if(inCompleteProcedures?.length){
                setErrors(proceduresInfo?.map(e => {
                    return(
                        {
                            qualifierCode : e?.qualifierCode ? false : true,
                            quantityTypeCode: e?.quantityTypeCode ? false : true,
                            quantity: e?.quantity ? false : true 
                        }
                    )
                }))
                toast?.error(paFormErrors?.propInfo)
                return
            }
            submit && onceCompleted ? setSelectedTab(onceCompleted) :  setSelectedTab(prev => prev + 1)
        }
        else{
            toast?.error(paFormErrors?.proceduresImp)
        }
    };

    return (
    <DefaultAetnaExtendedProceduresForm
        title="Procedures Information"
        fetchData={fetchData}
        onRowSelected={(row) => setProceduresInfo((prev) => [...prev, row])}
        onRemove={(id) => setProceduresInfo((prev) => prev.filter((item) => item.id !== id))}
        selectedData={proceduresInfo}
        setSelectedData={setProceduresInfo}
        onNext={handleNext}
        maxSelections={16}
        setSelectedTab={setSelectedTab}
        onceCompleted={onceCompleted}
        errors={errors}
        showDates={{toDate:true,fromDate:true}}
        showServices={{serviceQuantity:true,serviceQuantityType:true}}
        showQualifierCode
        notReqFields={['toDate','fromDate']}
        quantityTypeOps={quantityTypeOpsBcbsIllinois}
        helperTexts={{quantity:formHelperTexts?.numUnder1k}}
        defaultMinDate={dayjs('01/01/2002')}
        defaultMaxDate={today.add(18,'month')}
        qualifierCodes={bcbsIllinoisProceduresQualifierCodes}
        regexFieldNames={{quantity:'fixedQuantityUnder1k'}}
    />
    )
}

export default ProceduresBcbsIllinoisHsForm