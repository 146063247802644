import React, { useContext } from 'react'
import DefaultAetnaSubscribersForm from '../../components/Aetna/DefaultAetnaSubscribersForm'
import { bcbsIllinoisSubscriberMemberIdHelperText } from '../../../../../utils/staticData'
import { BcbsIllinoisHsContext } from '../../../../../context/priorAuthorizationForms/BcbsIllinoisContext'

const SubscriberBcbsIllinoisHsForm = () => {
    const {setSelectedTab, subscriber, setSubscriber, onceCompleted} = useContext(BcbsIllinoisHsContext)
  return (
    <DefaultAetnaSubscribersForm
    subscriberInfo={subscriber}
    setSubscriberInfo={setSubscriber}
    setSelectedTab={setSelectedTab}
    noSuffix
    memberIdFieldName='bcbsIllinoisMemberId'
    onceCompleted={onceCompleted}
    helperText={bcbsIllinoisSubscriberMemberIdHelperText}
    />
  )
}

export default SubscriberBcbsIllinoisHsForm