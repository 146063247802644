import toast from "react-hot-toast";
import { getLocalData } from "./localStorageFunctions";
import { errFetchingDataStr, failedNetworkReqStr } from "./staticData";
import { dayJsMdyFormat } from "./DateFunctions";


export const capitalizeFirtLetter = (value) => {
    return value ? value?.charAt(0)?.toUpperCase() + value?.slice(1) : ''
} 

export const getLastElementFromArray = (data) => {
    return data && data[data?.length - 1];
};


export const formatDateForInput = (date) => {
    return date ? new Date(date)?.toISOString()?.slice(0, 16)?.replace('T', ' ') : '';
};

export const createAppointmentTitle = (appointment) => {
    return `${appointment?.appointmentTitle ?? ''} - ${appointment?.patientName ?? ''}`;
};

export function back() {
    window?.history?.back()
};

export const DownloadCSV = (res, fileName) => {
    const base64ToByteArray = (base64String) => {
        const binaryString = window.atob(base64String);
        const byteArray = new Uint8Array(binaryString?.length);
        for (let i = 0; i < binaryString?.length; i++) {
            byteArray[i] = binaryString?.charCodeAt(i);
        }
        return byteArray;
    };

    const isValidBase64 = (str) => {
        try {
            return btoa(atob(str)) === str;
        } catch (err) {
            return false;
        }
    };

    if (!isValidBase64(res)) {
        console.log('Invalid base64 string received');
    }

    const byteArray = base64ToByteArray(res);

    const blob = new Blob([byteArray], { type: 'text/csv' });
    const curl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = curl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};


export const base64ToByteArray = (base64String) => {
    const binaryString = base64String ?  window.atob(base64String) :''
    const byteArray = new Uint8Array(binaryString?.length);
    for (let i = 0; i < binaryString?.length; i++) {
      byteArray[i] = binaryString?.charCodeAt(i);
    }
    return byteArray;
};
  
export const isValidBase64 = (str) => {
    try {
      return btoa(atob(str)) === str;
    } catch (err) {
      return false;
    }
};

export const trimmedStr = (str, length) => {
    const trimmedVal = str && length ? str?.substring(0, length) : str
    return trimmedVal
}

export const ordinalFormat = (number) => {
    if (number === undefined || number === null) return '';
    
    const num = Number(number);
    if (isNaN(num)) return '';

    const lastDigit = num % 10;
    const lastTwoDigits = num % 100;

    if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
        return `${num}th`;
    }
    
    switch (lastDigit) {
        case 1:
            return `${num}st`;
        case 2:
            return `${num}nd`;
        case 3:
            return `${num}rd`;
        default:
            return `${num}th`;
    }
}

export const capitalizedFirstLetter = (name) => {
    const formattedName = name ? name?.split(' ')?.map(word => 
        word?.charAt(0)?.toUpperCase() + word?.slice(1).toLowerCase()
    )?.join(' ') : ''
    return formattedName
}

export const endStringWithSpace = (value) => value?.concat(' ')

export const failedGetApi = (e) => {
    toast.error(errFetchingDataStr)
    e && console.log('error:',e)
}

export const getRequestNpi = () => {
    const npi =  getLocalData('selectedNpi')?.npiNumber  ?? ''
    return npi
}

export const smallLettersStr = (str) => {
    return str ? str?.toLowerCase() : ''
}

export const failedApiReq = (e) => {
    toast.error(failedNetworkReqStr)
    e && console.log('error:',e)
}

export const getLocalRequestNpi = () => {
    const npi = JSON.parse(localStorage?.getItem('selectedNpi'))?.npiNumber?.toString() ?? ''
    return npi
}

export const getAdjacentElements = (list, target) => {

    if (list?.length) {
        const index = list.indexOf(target);
        const prevElement = index > 0 ? list[index - 1] : null;
        const nextElement = index < list.length - 1 ? list[index + 1] : null;

        // Example usage
        //   const list = ['66d9ddea6a2618dfa2c64f53', '66d9ddea6a2618dfa2c64f5a', '66e2b3b59719634b298c4218'];
        //   const targetElement = '66d9ddea6a2618dfa2c64f5a';
        return {
            before: prevElement,
            after: nextElement,
        };
    }
    else {
        return {
            before:  null,
            after:  null,
        }
    }
};
  
export const areArraysEqual = (array1, array2) => {
    if (array1?.length !== array2?.length) {
      return false;
    }
  

  // Example usage
//   const array1 = ['66d9ea90691366772a288445', '66d9ddea6a2618dfa2c64f5a'];
//   const array2 = ['66d9ea90691366772a288445', '66d9ddea6a2618dfa2c64f5a'];
  
    return array1?.every((value, index) => value === array2[index]);
  };
  
export const hasTwoSameValues = (arr) => {
    return arr && arr.length === 2 && arr[0] === arr[1]
    // Example usage:
// const exampleArray = ['October 15th', 'October 15th'];
// hasTwoSameValues(exampleArray);  
}


export const checkAllValsIfEqual = (...arr) => {
    const reducedVal = arr?.reduce((prev,curr)=>{
        return prev?.toLowerCase() !== curr?.toLowerCase() ? `${prev?.toLowerCase()} ${curr?.toLowerCase()}`?.trim() : prev
    },'')
    return capitalizeFirtLetter(reducedVal)
}

export const joinArrayWithCommas = (arr) => {
    return arr?.map((elem,index) => {return(`${index !== 0 ? ', ' : ''}${dayJsMdyFormat(elem)}`)})
}

export const logoutRedirection = () => {
    const currentUrl = window.location.href;
    const baseUrl = new URL(currentUrl).origin;
    if(baseUrl?.includes('beluga')){
      return process.env.REACT_APP_BELUGA_API_LOGOUT_URL
    }
    else {
        return process.env.REACT_APP_API_LOGOUT_URL
    }
}

export const getMatchingValues = (array1, array2) => {
    // Convert the first array to the matching format (lowercase, spaces/dashes replaced by underscores)
    const formattedArray1 = array1?.map(item => item.toLowerCase().replace(/[\s-]+/g, '_'));

    // Filter out values from the first array that match any element in the second array
    return array1.filter((_, index) => array2?.includes(formattedArray1[index]));

    // Example usage
// const firstArray = [
//     "Gastroparesis",
//     "Triglycerides over 600 at any point",
//     "Pancreatic cancer",
//     "Pancreatitis",
//     "Type 1 Diabetes",
//     "Hypoglycemia",
//     "Insulin- dependent diabetes",
//     "Thyroid cancer",
//     "Family history of thyroid cancer",
//     "Family history of Multiple Endocrine Neoplasia(MEN - 2) syndrome",
//     "Anorexia or bulimia",
//     "Current symptomatic gallstones"
// ];

// const secondArray = [
//     "gastroparesis",
//     "triglycerides_over_600"
// ];

// // Call the function
// const result = getMatchingValues(firstArray, secondArray);
};

export const filterImportantKeys = (completeObject, ignoredKeysObject) => {
    const ignoredKeys = Object.keys(ignoredKeysObject);

  return Object.keys(completeObject).filter((key) => {
    return (
      !ignoredKeys.includes(key) &&
      (completeObject[key] === null || completeObject[key] === undefined || completeObject[key] === "")
    );
  });

  }

 export const filterSupportedObjects = (inputObject) => {
    const filteredObjects = {};
  
    Object.entries(inputObject).forEach(([key, value]) => {
      if (value?.type?.toLowerCase() !== "unsupported") {
        filteredObjects[key] = value;
      }
    });
  
    return filteredObjects;
  };

  export const handlePaginationScroll = (event,setPage,page,isLoading) => {
    const listboxNode = event.currentTarget;
      const nearBottom =
        listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight - 5; 
      if (nearBottom && !isLoading) {
        setPage(page + 1);
      }
  };

  export const truncateText = (text, wordLimit) => {
    if (!text) return '';
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  };

  export const parseCSVDataFromBase64 = (base64String) => {
    const base64ToByteArray = (base64String) => {
        const binaryString = window.atob(base64String);
        const byteArray = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
            byteArray[i] = binaryString.charCodeAt(i);
        }
        return byteArray;
    };

    const isValidBase64 = (str) => {
        try {
            return btoa(atob(str)) === str;
        } catch (err) {
            return false;
        }
    };

    if (!isValidBase64(base64String)) {
        console.log('Invalid base64 string received');
        return [];
    }

    // Convert base64 to byte array
    const byteArray = base64ToByteArray(base64String);

    // Convert byte array to CSV string
    const csvData = new TextDecoder().decode(byteArray);

    // Split CSV data into rows, then split each row into columns
    const lines = csvData.split('\n'); // Split by newline for rows
    const result = lines.map(line => line.split(',')); // Split each line by commas

    return result;
};
