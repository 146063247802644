
import { Box, CircularProgress, FormControl, Grid, Autocomplete, InputLabel, MenuItem, Select, TextField, Typography, Paper, FormControlLabel, RadioGroup, FormLabel, Radio } from "@mui/material";
import { isAlphabetOnly, isAlphaNumeric } from "../../utils/regexTestFunctions";
import { alphaNumericValidationStr, defaultPatientData, MenuProps, onlyAlphabetsValidationStr, policyHolderRelationship, unitedStates } from "../../utils/staticData";
import React, { useState, useEffect } from "react";
import { addPatientStyles, styles } from "./styles";
import TableHeader from "../../components/TableHeader";
import Button from "@mui/material/Button";
import { post_Patient } from "../../services/addPatient";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { get_payersdata } from "../../services/PayerService";
import { getLocalData } from "../../utils/localStorageFunctions";
import { phoneNumberFormat } from "../../utils/valueFormatter/Formats";
import toast from "react-hot-toast";
import { failedApiReq, getRequestNpi } from "../../utils/reusableFunctions";
import { clear, fetchPatientDetails, fieldsErrCheck, optionalFieldsProvidedCheck, requiredFieldsProvidedCheck } from "./PatientFunctions";
import ReusableConfirmationModal from "../../components/modal/ReusableConfirmationModal";
import { useNavigate } from "react-router-dom";
import { mdyFormatDate } from "../../utils/DateFunctions";
import { colors } from "../../utils/colors";
import { sharedStyles } from "../../sharedStyles";
import PayersDropdown from "../../components/PayersDropdown";


const AddPatient = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [fieldsErr, setFieldsErr] = useState(false);
  const [patientDetails, setPatientDetails] = useState(defaultPatientData);
  const [selectedPatient, setSelectedPatient] = useState('')
  const [phoneNumberErr, setPhoneNumberErr] = useState(false)
  const { userId } = getLocalData('user')
  const [refetchAllPatients, setRefetchAllPatients] = useState(0);
  const [fields, setFields] = useState("Required Fields Only");
  const [showModal, setShowModal] = useState(false)


  const navigate = useNavigate()

  const handleChange = (e) => {
    if (e?.target?.name) {
      const { name, value } = e.target;
      // Validations
      if ((name === 'memberId' || name === 'groupNumber')) {
        if (!isAlphaNumeric(value)) {
          toast.error(alphaNumericValidationStr);
          return;
        }
      } else if ((name === 'patientFirstName' || name === 'patientLastName')) {
        if (!isAlphabetOnly(value)) {
          toast.error(onlyAlphabetsValidationStr);
          return;
        }
      } else if (name === 'phoneNumber') {
        let formattedValue = phoneNumberFormat(value);
        setPatientDetails((prevDetails) => ({
          ...prevDetails,
          [name]: formattedValue,
        }));
        return;
      }

      setPatientDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };


  const handleInsuranceChange = (event, newValue) => {

    if (newValue?.action === 'remove-value') {
      setPatientDetails((prevState) => {
        const filteredInsurance = prevState.insurancename.filter((item) => item?.value !== newValue.removedValue.value);
        return { ...prevState, insurancename: filteredInsurance };
      })
    } else if (newValue?.action === 'select-option') {
      setPatientDetails((prevState) => ({
        ...prevState,
        insurancename: [
          ...prevState.insurancename,
          { label: newValue.option?.label, value: newValue.option?.value }
        ],
      }));
    }
    else {
      setPatientDetails((prevState) => ({
        ...prevState,
        insurancename: [],
      }));
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    const {
      patientFirstName, patientLastName, email, patientDOB, memberId, groupNumber, appointmentDate, insurancename, phoneNumber, policyHolderRelationship, patientMiddleName, addressLine1, addressLine2, state
    } = patientDetails;

    if (fieldsErrCheck(patientFirstName, patientLastName, patientDOB, appointmentDate, state)) {
      setFieldsErr(true);
      toast.error("Please enter all required fields")
      return;
    }

    const requiredFieldsProvided = requiredFieldsProvidedCheck(patientFirstName, patientLastName, patientDOB);
    const optionalFieldsProvided = optionalFieldsProvidedCheck(memberId, groupNumber, appointmentDate, insurancename, policyHolderRelationship, phoneNumber, email, patientMiddleName, addressLine1, addressLine2, state);
    let payload = {};

    if (requiredFieldsProvided) {
      // If required fields are provided only then process this payload
      payload = {
        patientFirstName,
        patientLastName,
        patientDOB,
        requestNpi: getRequestNpi(),
      };

      // If any optional field is provided then process this payload.
      if (optionalFieldsProvided) {
        if (phoneNumber?.length < 12 && phoneNumber?.length !== 0) {
          setPhoneNumberErr(true);
          toast.error("Phone Number should not be less than 10 digits");
          return;
        }

        payload = {
          ...payload,
          patientDOB,
          memberId,
          groupNumber,
          appointmentDate,
          insurancename: insurancename?.length ? insurancename.join(", ") : null,
          phoneNumber,
          policyHolderRelationship,
          email,
          createdBy: userId,
          updatedBy: userId,
          patientMiddleName,
          addressLine1, addressLine2, state: state ? state?.name : ""
        };
      }
    }
    setIsLoading(true);
    try {
      const addPatient = await post_Patient(payload);
      if (addPatient?.data?.message) {
        toast.success(addPatient?.data?.message);
        setRefetchAllPatients(refetchAllPatients + 1)
        setFieldsErr(false);
        setShowModal(true);

      }
    } catch (err) {
      failedApiReq(err)
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedPatient?.id) {
      fetchPatientDetails(setPatientDetails, selectedPatient)
    }
    else clear(setPatientDetails, setFieldsErr)
  }, [selectedPatient])

  const handleModal = () => {
    const { patientFirstName, patientLastName, patientDOB, appointmentDate, patientMiddleName } = patientDetails
    navigate(`/discoveryTab?preSelectedTab=2&firstName=${patientFirstName || ''}&lastName=${patientLastName || ''}&dob=${mdyFormatDate(patientDOB)}&appt=${mdyFormatDate(appointmentDate)}&middleName=${patientMiddleName || ''}&advanced=true`)
    setShowModal(false)
    setPatientDetails({
      patientLastName: "",
      patientMiddleName: "",
      patientFirstName: "",
      patientDOB: null,
      phoneNumber: "",
      email: "",
      appointmentDate: null,
      insurancename: null,
      memberId: "",
      groupNumber: "",
      policyHolderRelationship: "",
      requestNpi: "",
      addressLine1: "",
      addressLine2: "",
      state: ""
    })
  }



  return (
    <div>
      <Paper sx={addPatientStyles?.parentPaperContainer}>
        <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
          Add a new patient
        </Typography>
        <TableHeader setFilterPatient={setSelectedPatient} idNeeded refetch={refetchAllPatients} />

        <Box sx={{ width: 'max-content', padding: '1em', marginBottom: '2em' }}>
          <FormControl>
            <FormLabel id='show-required-fields'>Form Type</FormLabel>
            <RadioGroup
              row
              aria-labelledby='show-required-fields'
              defaultValue={fields}
              value={fields}
              onClick={(event) => {
                setFields(event.target.value)
                setFieldsErr(false)
              }}
            >
              <FormControlLabel value={"Required Fields Only"} control={<Radio sx={sharedStyles?.muiCheckedThemeColor} />} label="Quick Add" />
              <FormControlLabel value={"Basic Form"} control={<Radio sx={sharedStyles?.muiCheckedThemeColor} />} label="Advance Add" />
            </RadioGroup>

          </FormControl>
        </Box>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={6}>
              <FormControl
                sx={{ color: "purple" }}
                fullWidth
              >
                <TextField
                  label="First Name *"
                  id="outlined-start-adornment"

                  inputProps={{
                    maxLength: 20,
                    minLength: 2,
                    style: { textTransform: 'capitalize' }
                  }}
                  // InputProps={{
                  //   startAdornment: <InputAdornment position="start"></InputAdornment>,
                  // }}
                  variant="outlined"
                  onChange={handleChange}
                  value={patientDetails.patientFirstName}
                  name="patientFirstName"
                  color="success"
                  helperText={fieldsErr && !patientDetails?.patientFirstName && 'This field is required'}
                  error={fieldsErr && !patientDetails?.patientFirstName}
                />
              </FormControl>
            </Grid>

            {
              fields === "Basic Form" && (
                <Grid item xs={12} sm={6} lg={6}>
                  <FormControl
                    sx={{ marginBottom: "2em", color: "purple" }}
                    fullWidth
                  >
                    <TextField
                      label="Middle Name"
                      id="outlined-start-adornment"
                      inputProps={{
                        maxLength: 20,
                        minLength: 2,
                        style: { textTransform: 'capitalize' }
                      }}
                      // InputProps={{
                      //   startAdornment: <InputAdornment position="start"></InputAdornment>,
                      // }}
                      variant="outlined"
                      onChange={handleChange}
                      value={patientDetails?.patientMiddleName}
                      name="patientMiddleName"
                      color="success"
                    />
                  </FormControl>
                </Grid>
              )
            }
            <Grid item xs={12} sm={6} lg={6}>
              <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                <TextField
                  // InputProps={{
                  //   startAdornment: <InputAdornment position="start"></InputAdornment>,
                  // }}
                  inputProps={{
                    maxLenght: 20,
                    minLength: 2,
                    style: { textTransform: 'capitalize' }
                  }}

                  onChange={handleChange}
                  value={patientDetails.patientLastName}
                  name="patientLastName"
                  label="Last Name *"
                  variant="outlined"
                  color="success"
                  helperText={fieldsErr && !patientDetails?.patientLastName && 'This field is required'}
                  error={fieldsErr && !patientDetails?.patientLastName}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} lg={6}>
              <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={
                      !patientDetails?.patientDOB && fieldsErr
                        ? sharedStyles?.errorNajamDatePickerStyles
                        : sharedStyles?.defaultDatePickerStyles
                    }
                    label="Date of Birth *"
                    onChange={(val) =>
                      setPatientDetails({
                        ...patientDetails,
                        patientDOB: val,
                      })
                    }
                    name={"patientDOB"}
                    value={patientDetails?.patientDOB}
                    variant="outlined"
                    color="success"
                  />
                </LocalizationProvider>
                <Typography
                  fontSize={'12px'}
                  color={
                    !patientDetails?.patientDOB && fieldsErr ? colors?.errorRed : colors?.fieldGray
                  }
                  paddingLeft={"1em"}
                  paddingTop={"0.2em"}
                >
                  {!patientDetails?.patientDOB && fieldsErr && 'The field is required'}
                </Typography>
              </FormControl>
            </Grid>


            {
              fields === "Basic Form" && (
                <Grid item xs={12} sm={6} lg={6}>
                  <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                    <TextField
                      // InputProps={{
                      //   startAdornment: <InputAdornment position="start"></InputAdornment>,
                      // }}

                      onChange={handleChange}
                      value={patientDetails?.email}
                      name="email"
                      label="Email"
                      type="email"
                      variant="outlined"
                      color="success"
                    />
                  </FormControl>
                </Grid>
              )
            }


            {
              (
                <Grid item xs={12} sm={6} lg={6}>
                  <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        sx={
                          !patientDetails?.appointmentDate && fieldsErr
                            ? sharedStyles?.errorNajamDatePickerStyles
                            : sharedStyles?.defaultDatePickerStyles
                        }
                        label="Appointment Date"
                        onChange={(val) =>
                          setPatientDetails({
                            ...patientDetails,
                            appointmentDate: val,
                          })
                        }
                        name={"appointmentDate"}
                        value={patientDetails?.appointmentDate}
                        variant="outlined"
                        color="success"
                      />
                    </LocalizationProvider>
                    <Typography fontSize={'12px'} color={!patientDetails.appointmentDate && fieldsErr ? colors?.errorRed : colors?.fieldGray} paddingLeft={"1em"} paddingTop={"0.2em"}>{!patientDetails.appointmentDate && fieldsErr && 'The field is required'} </Typography>

                  </FormControl>
                </Grid>
              )
            }


            {
              fields === "Basic Form" && (
                <Grid item xs={12} sm={6} lg={6}>
                  <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                    <InputLabel id="payer-collection">Policy Holder Relationship</InputLabel>
                    <Select
                      id="policy-holders"
                      onChange={(e) => setPatientDetails({ ...patientDetails, policyHolderRelationship: e.target.value })}
                      value={patientDetails?.policyHolderRelationship}
                      // input={<OutlinedInput label="Policy Holder Relationship" />}
                      MenuProps={MenuProps}
                      sx={{
                        '& .MuiGrid-root': {
                          height: '30px'
                        }
                      }}
                    >
                      {
                        policyHolderRelationship?.length && policyHolderRelationship?.map((item, index) => (
                          <MenuItem value={item} key={index}>
                            {item}
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
              )
            }

            {
              fields === "Basic Form" && (
                <Grid item xs={12} sm={6} lg={6}>
                  <PayersDropdown handleChange={handleInsuranceChange} isMulti={true} value={patientDetails?.insurancename} />
                </Grid>

              )
            }




            {
              fields === "Basic Form" && (
                <Grid item xs={12} sm={6} lg={6}>
                  <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                    <TextField
                      // InputProps={{
                      //   startAdornment: <InputAdornment position="start"></InputAdornment>
                      // }}
                      inputProps={{
                        maxLength: 30,
                        minLength: 2,
                        pattern: '^[a-zA-Z0-9]*$'
                      }}

                      onChange={handleChange}
                      value={patientDetails?.memberId}
                      name="memberId"
                      label="Member ID"
                      variant="outlined"
                      color="success"
                    />
                  </FormControl>
                </Grid>
              )
            }

            {
              fields === "Basic Form" && (
                <Grid item xs={12} sm={6} lg={4}>
                  <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                    <TextField
                      // InputProps={{
                      //   startAdornment: <InputAdornment position="start"></InputAdornment>,
                      // }}

                      onChange={handleChange}
                      value={patientDetails.groupNumber}
                      name="groupNumber"
                      label="Group Number"
                      variant="outlined"
                      color="success"
                    />
                  </FormControl>
                </Grid>
              )
            }


            {
              fields === "Basic Form" && (
                <Grid item xs={12} sm={6} lg={4}>
                  <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                    <TextField
                      // InputProps={{
                      //   startAdornment: <InputAdornment position="start"></InputAdornment>,
                      // }}

                      inputProps={{
                        inputMode: 'numeric'
                      }}
                      onChange={handleChange}
                      value={patientDetails?.phoneNumber}
                      name="phoneNumber"
                      label="Phone Number"
                      variant="outlined"
                      type="tel"
                      error={phoneNumberErr && patientDetails?.phoneNumber?.length < 12}
                      helperText={phoneNumberErr && patientDetails?.phoneNumber?.length < 12 ? 'Phone Number should not be less than 10 digits' : ''}
                      color="success"
                    />
                  </FormControl>
                </Grid>
              )
            }



            {fields === "Basic Form" && (
              <Grid item xs={12} sm={6} lg={4}>
                <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                  <TextField
                    onChange={handleChange}
                    name="addressLine1"
                    label="Address Line 1"
                    variant="outlined"
                    value={patientDetails?.addressLine1}
                  />
                </FormControl>
              </Grid>
            )
            }


            {
              fields === "Basic Form" && (
                <Grid item xs={12} sm={6} lg={4}>
                  <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                    <TextField
                      onChange={handleChange}
                      name="addressLine2"
                      label="Address Line 2"
                      variant="outlined"
                      value={patientDetails?.addressLine2}
                    />
                  </FormControl>
                </Grid>
              )
            }

            {
              (
                <Grid item xs={12} sm={6} lg={4}>
                  <FormControl sx={{ marginBottom: "2em" }} fullWidth error={!patientDetails.state && fieldsErr}>
                    <InputLabel id="demo-simple-select-label">State</InputLabel>
                    <Select fullWidth labelId="demo-simple-select-label" id="demo-simple-select"
                      error={!patientDetails.state && fieldsErr}

                      onChange={(event) => {
                        setPatientDetails((prev) => ({
                          ...prev,
                          state: event.target.value
                        }))
                      }}
                      value={patientDetails?.state} label="State">

                      {
                        unitedStates?.length && unitedStates?.map((item, index) => (
                          <MenuItem value={item} key={index}>
                            {item?.name}
                          </MenuItem>
                        ))
                      }
                    </Select>
                    <Typography fontSize={'12px'} color={!patientDetails.state && fieldsErr ? colors?.errorRed : colors?.fieldGray} paddingLeft={"1em"} paddingTop={"0.2em"}>{!patientDetails.state && fieldsErr && 'The field is required'} </Typography>
                  </FormControl>
                </Grid>
              )
            }





            <Grid item xs={12} lg={12}>
              <Box sx={[sharedStyles?.flex, sharedStyles?.justifyCenter, sharedStyles?.gap2em]} mt={2}>
                <Button sx={sharedStyles?.whiteBtn} onClick={() => {
                  setPatientDetails({
                    patientLastName: "",
                    patientMiddleName: "",
                    patientFirstName: "",
                    patientDOB: null,
                    phoneNumber: "",
                    email: "",
                    appointmentDate: null,
                    insurancename: [],
                    memberId: "",
                    groupNumber: "",
                    policyHolderRelationship: "",
                    requestNpi: "",
                    addressLine1: "",
                    addressLine2: "",
                    state: ""
                  })
                  setFieldsErr(false)
                  setPhoneNumberErr(false)
                }} variant="contained" disabled={isLoading} style={{ cursor: isLoading ? 'not-allowed' : 'pointer' }} size="large">
                  Clear
                </Button>
                <Button type="submit" disabled={isLoading} sx={sharedStyles?.themeBtn} variant="contained" size="large">
                  {isLoading ? <CircularProgress /> : 'Save'}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Paper>

      <ReusableConfirmationModal
        open={showModal}
        onClick={handleModal}
        onClose={() => {
          setShowModal(false)
          setPatientDetails(defaultPatientData);
        }}

        heading={'Do you want to run an insurance discovery search ?'}
      />
    </div>
  );
};

export default AddPatient;
