import React, { useEffect } from "react";
import PatientMainPage from "./PatientMainPage";
import UserMainPage from "./UserMainPage";

const MainPage = ({ userType, handleLogin }) => {
  useEffect(()=>{
    localStorage.removeItem('lastTimeTabClosed');
  },[])
  return (userType === 'patient' ?

    <PatientMainPage handleLogin={handleLogin} />

    :
    <UserMainPage handleLogin={handleLogin} />
  )
};

export default MainPage;