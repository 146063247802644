import React from 'react';
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Paper,
    Typography,
    Box,
    FormHelperText,
} from '@mui/material';
import { sharedStyles } from '../../../../../sharedStyles';
import { paFormErrors,  serviceTypeHsOptions } from '../../../../../utils/staticData';
import { styles } from '../../components/styles';
import CommonNextButton from '../../components/CommonNextButton';

const ImpServicesType = ({errors, setErrors,setSelectedTab, serviceType:serviceInfo, setServiceType:setServiceInfo, onceCompleted, serviceTypes,noBack,onComplete }) => {
  
    const handleChange = (event, field) => {
        const { value } = event.target;
            setServiceInfo((perv) => ({
                ...perv,
                [field]: value,
            }));
            onComplete && onComplete()
    };

    const handleSubmit = (submit) => {

        if (
            !serviceInfo?.serviceType 
        ) {

            setErrors({
                serviceType: !serviceInfo?.serviceType ? paFormErrors?.reqField : false,
            });
            return
        }

        else {
            setErrors({
                serviceType: false,
            });
            submit ? setSelectedTab(onceCompleted) : setSelectedTab((prev) => prev + 1)
        }
    };

    return (
        <Paper elevation={3} sx={styles?.parentPaperContainer}>
            <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
                Service Type Information
            </Typography>


            <Box sx={styles?.commonPaperContainer}>
                <Grid container spacing={3}>

                    <Grid item xs={12}>
                        <FormControl fullWidth required error={errors?.serviceType}>
                            <InputLabel>Service Type</InputLabel>
                            <Select
                                value={serviceInfo?.serviceType}
                                label="Service Type"
                                onChange={(e) => handleChange(e, 'serviceType')}
                                error={errors?.serviceType}
                            >
                                {(serviceTypes ?? serviceTypeHsOptions)?.map((item) => (
                                    <MenuItem key={item.code} value={item.value}>
                                        {item.value}
                                    </MenuItem>
                                ))}

                            </Select>
                            <FormHelperText>
                                {onceCompleted && "Information you filled in the next forms were dependent on this, if you change this service type, you will have to refill some of the information"}
                            </FormHelperText>
                            <FormHelperText sx={sharedStyles?.errorText}>
                                {errors?.serviceType && errors?.serviceType}
                            </FormHelperText>
                        </FormControl>
                    </Grid>



                </Grid>
            </Box>

            <CommonNextButton handleSubmit={handleSubmit} setSelectedTab={setSelectedTab} onceCompleted={onceCompleted} noBack={noBack}/>
        </Paper>
    );
};

export default ImpServicesType;
