import axios from "axios";
import toast from "react-hot-toast";
import { getLocalData } from "../utils/localStorageFunctions";
import { DownloadCSV, getRequestNpi, parseCSVDataFromBase64 } from "../utils/reusableFunctions.js"
import { formatDateYMD } from "../utils/DateFunctions";
import { API_BASE_URL_NEW, failedPatientUpdationStr } from "../utils/staticData.js";
import { userGetAxios, userPostAxios } from "./userApis.js";
import { setAllPatientsIds } from "../utils/globalFunctions.js";

const API_BASE_URL = process.env.REACT_APP_API_URL;
const npiInfo = getLocalData('selectedNpi')

export const post_Patient = async (requestData, updating) => {
    try {
        const res = await userPostAxios(`${API_BASE_URL_NEW}/api/Patient/AddPatient`,requestData)
        return res;
    } catch (error) {
        toast?.error(error?.response?.data?.detail ?? updating ? failedPatientUpdationStr : 'Failed to add patient');
    }
};

export const sync_Patient = async (requestData) => {
    try {
        const res = await userPostAxios(`${API_BASE_URL_NEW}/api/PatientFinderInquiry/${requestData?.firstname}/${requestData?.lastname}/${requestData?.dateOfBirth}?responsibleSystem=${requestData?.search}`)
        return res;
    } catch (err) {
        toast?.error(err?.response?.data?.detail ?? 'Failed to get Patient');
    }
};

export const bulk_Patient = async (requestData,handleCsvToShow,setCsvToDownload,setLoader) => {
    const npiNumber = JSON.parse(localStorage?.getItem('selectedNpi'))?.npiNumber;
    const url = new URL('/api/Patient/AddBulkPatients',API_BASE_URL_NEW)

    const params = new URLSearchParams();
    params?.append('requestNpi', Number(npiNumber));
    url.search = params.toString(); 
    try {
        setLoader && setLoader(prev => ({...prev,importing:true}))
        const response = await userPostAxios(url,requestData,'multipart/form-data')

        if (response && response?.data && response?.data?.message) {
            toast?.success(response?.data?.message);
        }
        if (response?.data?.csvFileByteArray) {
          const parsedData =  parseCSVDataFromBase64(response?.data?.csvFileByteArray)
          handleCsvToShow && handleCsvToShow(parsedData)
          setCsvToDownload && setCsvToDownload(response?.data?.csvFileByteArray)
        }
        return response?.data;
    } catch (error) {
        toast?.error(error?.response?.data?.errors ?? 'Failed to upload CSV');
    }
    finally{
        setLoader && setLoader(prev => ({...prev,importing:false}))
    }
};

export const get_GetAllPatientsDataAsync = async (npiId, page, perPage, searchKeyword = '') => {
    const id = JSON.parse(localStorage?.getItem('selectedNpi'))?.id;
  
    const url = new URL('/api/Patient/GetAllPatientsData', API_BASE_URL_NEW);

    // query Params
    const params = new URLSearchParams();
    page && params?.append('pageNumber', page);
    perPage && params?.append('pageSize', perPage);
    params?.append('npiId', Number(id));

    if (searchKeyword) {
        params?.append('SearchKeyword', searchKeyword);
    }
    
    url.search = params.toString();
    try {
        const response = await  userGetAxios(url)
        if (response?.data) {
            setAllPatientsIds(response?.data?.map(({ id }) => id))
        }
        return response;
    } catch (error) {
        console.log(error);
    }
};


export const get_GetPatientDataById = async (patientId) => {
    const url = `${API_BASE_URL_NEW}/api/Patient/GetPatientDataById/${patientId}`;
    try {
        const response = await userGetAxios(url)
        return response;
    } catch (error) {
        console.log(error)
    }
};

export const get_ExportPatientsRecords = async (patientData) => {

    const url = `${API_BASE_URL_NEW}/api/Patient/ExportPatientsRecords`;
    try {
        const response = await userPostAxios(url,patientData)
        return response?.data;
    } catch (error) {
        console.log(error)
    }

};

export const get_GenerateEmptyCSVAsync = async () => {
    const url = `${API_BASE_URL_NEW}/api/Patient/GenerateEmptyCsvTemplateAsync`;
    try {
        const response = await userGetAxios(url)
        return response;
    } catch (error) {
        console.log(error)
    }
};

export const get_PatientAddress = async (patientId) => {
    const url = `${API_BASE_URL_NEW}/api/Patient/GetPatientsAdressById/${patientId}`;
    try {
        const response = await userGetAxios(url);
        return response;
    } catch (error) {
        console.log(error)
    }
};

export const get_PatientAppointments = async (patientId) => {
    const url = `${API_BASE_URL}/api/Appointment/SearchByPatientId/${patientId}`;
    try {
        const response = await userGetAxios(url);
        return response;
    } catch (error) {
        console.log(error)
    }
};

export const update_PatientAddress = async (patientData, id) => {

    const url = `${API_BASE_URL}/api/Patient/UpdatePatientsAdressById?patientId=${id}`;
    const { addressLine1, addressLine2, city, state, country, zipCode, reportedDate } = patientData;

    const objToSend = {
        addressLine1, addressLine2, city: city, state: state?.name, country, zipCode, reportedDate
    };

    try {
        const response = await userPostAxios(url,objToSend)
        return response?.data;
    } catch (error) {
        console.log(error)
    }

};

export const getPatientOverviewChartDetails = async (date) => {
    const dates = date || `${formatDateYMD(new Date())}/${formatDateYMD(new Date())}`
    const npiId = JSON.parse(localStorage?.getItem("selectedNpi"))?.id ?? '';
    const url = `${API_BASE_URL_NEW}/api/Patient/GetPatientCounts/${dates}?npiId=${npiId}`;
    try {
        const response = await userGetAxios(url)
        return response?.patientGraphDetails;
    } catch (error) {
        console.log(error, 'errorr');
        toast.error(error?.response?.data ?? 'Failed to get data')
    }
}

export const getClaimDetails = async () => {
    const url = `${API_BASE_URL_NEW}/api/ClaimsStatus/ClaimDetailCard/${getLocalData('selectedNpi')?.id}`
    const response = await userGetAxios(url)
    return response
}

export const getInsurance_Details = async (data) => {
    const url = `https://dev-postgres-be.appzhealth.com/api/InsuranceDiscovery/GetAllInsuranceDiscoveryDataByPatient`;

    try {
        const response = await userPostAxios(url,data)
        return response?.data;
    } catch (error) {
        console.log(error, 'error');
    }
}