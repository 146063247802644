import React, { useContext } from 'react'
import DefaultAetnaProviderNotesSrForm from '../../components/Aetna/DefaultAetnaProviderNotes'
import { AmerigroupContext } from '../../../../../context/priorAuthorizationForms/AmerigroupArContext'
import DefaultProviderNotesForm from '../../components/Amerigroup/DefaultProviderNotesForm';

const AmerigroupProviderNotes = () => {
    const { onceCompleted, providerNotes, setProviderNotes, setSelectedTab } = useContext(AmerigroupContext);

    return (
        <DefaultProviderNotesForm
            onceCompleted={onceCompleted}
            providerNotes={providerNotes}
            setProviderNotes={setProviderNotes}
            setSelectedTab={setSelectedTab}
        />
    )
}

export default AmerigroupProviderNotes
