


import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { fetchClaimsData } from './ClaimFunctions';
import { sharedStyles } from '../../sharedStyles';
import BoldText from '../../components/Texts/BoldText';
import ClaimsPaginatedTable from './ClaimsPaginatedTable';
import { useClaimsTable } from '../../utils/contexts/ClaimsTableContext';

const Claim = () => {
    const pagename = "Claim";
    const [input, setInput] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [isSuggestionsLoading, setIsSuggestionsLoading] = useState(false)

     // contexts
  const { refetchClaimsTable } = useClaimsTable();

    return (
        <Box>
            <Box sx={sharedStyles?.bm1em}>
                <BoldText text={'Recent Transactions'} />
            </Box>
            
            <Box sx={sharedStyles?.mt2em}>
                <ClaimsPaginatedTable
                refetch={refetchClaimsTable}
                />
            </Box>
        </Box>
    );
};

export default Claim;

