import { Box } from '@mui/material';
import React from 'react'
import { useParams } from 'react-router-dom';
import ClaimSearchResultsTable from './GridCellsRenderers/ClaimSearchResultsTable';
import MyTabs from '../../components/tabs/MyTabs';
import { sharedStyles } from '../../sharedStyles';


const ClaimsSearchResults = () => {
    const { id } = useParams(); 
    const tabs =
        [
            {
                label: "Results",
                value: <ClaimSearchResultsTable id={id}/>,
                changeTab: 0,
                button: 'Back',
                goBack:true
            }
        ]

    return (
       <section className='discovery'>
                   <Box sx={sharedStyles?.tabsBoxContainer}>
                       <MyTabs tabs={tabs} />
                   </Box>
               </section>
    );
}

export default ClaimsSearchResults