import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import CheckIcon from '@mui/icons-material/Check';

const PatientInsuranceStatus = ({ data }) => {
  return (
    <Box display={'flex'} justifyContent={'start'} alignItems={'center'} gap={2}>
      {data?.processStatus || '--'}
      {
      data?.processStatus === 'Processing' ? <CircularProgress size={17} color='success' /> :
       data?.processStatus === 'Queued' ? <HourglassEmptyIcon size={17} color='success' /> :
       data?.processStatus === "Completed" ? <CheckIcon size={17} color='success'/>
     :
       data?.processStatus === 'Failed' ? <ErrorIcon color="error" /> :
       null
       }
    </Box>
  );
}

export default PatientInsuranceStatus;
