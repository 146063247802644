import React, { useContext, useState } from 'react';
import {
    Box,
    Grid,
    Paper,
    Typography,
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { styles } from '../styles';
import { sharedStyles } from '../../../../../sharedStyles';
import CommonNextButton from '../CommonNextButton';
import { paFormErrors } from '../../../../../utils/staticData';

const ImpDatesForm = ({dateLimits,errors,formData,setFormData,setSelectedTab,onceCompleted,handleSubmit,dateLabels,showHomeHealthDate}) => {


    const handleDateChange = (date, field) => {
        setFormData({
            ...formData,
            [field]: date
        });
    };


    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Paper elevation={3} sx={styles?.parentPaperContainer}>
                <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
                    Dates Information
                </Typography>
                <Box sx={styles?.commonPaperContainer}>
                    <Grid container spacing={3} mb={2}>


                        {/* From Date */}
                        <Grid item xs={12} md={6}>
                            <DatePicker
                                label={dateLabels?.fromDate || "Admission Date"}
                                value={formData.fromDate}
                                sx={errors?.fromDate ? sharedStyles?.errorDatePickerStyles : sharedStyles?.defaultDatePickerStyles}
                                onChange={(date) => handleDateChange(date, 'fromDate')}
                                minDate={dayjs(dateLimits?.min)}
                                maxDate={dayjs(dateLimits?.max)}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        inputProps: { readOnly: true },
                                        error: !!errors?.fromDate,
                                        required: true,
                                        helperText:errors?.fromDate ? paFormErrors?.reqField : '',
                                    }
                                }}
                            />
                        </Grid>

                        {/* To Date */}
                        <Grid item xs={12} md={6}>
                            <DatePicker
                                label={dateLabels?.toDate || "Discharge Date"}
                                sx={errors?.toDate ? sharedStyles?.errorDatePickerStyles : sharedStyles?.defaultDatePickerStyles}
                                value={formData.toDate}
                                onChange={(date) => handleDateChange(date, 'toDate')}
                                minDate={dayjs(dateLimits?.min)}
                                maxDate={dayjs(dateLimits?.max)}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        inputProps: { readOnly: true },
                                        error: !!errors?.toDate,
                                        required: true,
                                        helperText:errors?.toDate ? paFormErrors?.reqField : '',
                                    }
                                }}
                            />
                        </Grid>

                        {/* Home Health Date */}
                       {showHomeHealthDate && <Grid item xs={12}>
                            <DatePicker
                                label={dateLabels?.homeHealthStartDate || "Home Health Start Date"}
                                sx={errors?.homeHealthStartDate ? sharedStyles?.errorDatePickerStyles : sharedStyles?.defaultDatePickerStyles}
                                value={formData.homeHealthStartDate}
                                onChange={(date) => handleDateChange(date, 'homeHealthStartDate')}
                                minDate={dayjs(dateLimits?.minHomeHealthDate)}
                                maxDate={dayjs(dateLimits?.maxHomeHealthDate)}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        inputProps: { readOnly: true },
                                        error: !!errors?.homeHealthStartDate,
                                        required: true,
                                        helperText:errors?.homeHealthStartDate ? paFormErrors?.reqField : '',
                                    }
                                }}
                            />
                        </Grid>}




                    </Grid>
                </Box>
                <CommonNextButton handleSubmit={handleSubmit} setSelectedTab={setSelectedTab} onceCompleted={onceCompleted} />
            </Paper>

        </LocalizationProvider>
    );
};

export default ImpDatesForm;