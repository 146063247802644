import { Box } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useState } from 'react'
import UseWidth from '../../custom-hooks/UseWidth';
import { sharedStyles } from '../../sharedStyles';
import { getAllCPTcodes } from '../../services/pVerify';
import { failedApiReq } from '../../utils/reusableFunctions';
import DebouncedSearch from '../SearchFields/DebouncedSearch';


const CptTable = ({ onRowSelected }) => {
    const { width } = UseWidth();
    //
    const [gridApi, setGridApi] = useState(null);
    const [searchKeyword, setSearchKeyword] = useState('');
    const perPage = width <= 900 ? 20 : 10;
  
    const onGridReady = (params) => {
      setGridApi(params.api);
    };

    useEffect(() => {
        if (gridApi) {
          const dataSource = {
            getRows: async (params) => {
              const page = params.endRow / perPage;
              try {
                gridApi.showLoadingOverlay();
                const data = await getAllCPTcodes(page, perPage, searchKeyword); 
                if(data?.data){
                const dataArray = data?.data?.map(({ cptCode, fullDescription, longDescription, shortDescription, id }) => ({
                id: id || 'N/A',
                CptCode: cptCode,
                fullDescription: fullDescription || 'N/A',
                longDescription: longDescription || 'N/A',
                shortDescription: shortDescription || 'N/A'
            }))
            params.successCallback(dataArray, data.totalCount);
                }
               
              }
              catch (e) {
                params.successCallback([], 0);
                failedApiReq(e)
              }
              finally {
                gridApi.hideOverlay();
              }
            }
          }
    
          gridApi.setDatasource(dataSource);
        }
      }, [gridApi, searchKeyword]);

    const columnDefs = () => {
        return [
            { headerName: 'ID', field: 'id',  width: 100, resizable: true },
            { headerName: 'CPT Code', field: 'CptCode',  width: 200, resizable: true },
            {
                headerName: 'Full Description', field: 'fullDescription',  minWidth: 227, resizable: true,flex:1
            },
            ...(onRowSelected ? [] : [{
              headerName: 'Long Description', field: 'longDescription', minWidth: 227,  resizable: true, flex: 1
          }]),
            { headerName: 'Short Description', field: 'shortDescription', minWidth: 227,  resizable: true ,flex:1},
        ];
    }

    const gridOptions = {
        pagination: true,
        paginationPageSize: width <= 900 ? 20 : 10,
        domLayout: 'paginationPrevNext,paginationPageSize,paginationFirstLast',
        onRowClicked: (event) => {
            if (onRowSelected) {
                onRowSelected(event.data); 
            }
        }
    };

    return (
    <>
      <DebouncedSearch onSearchChange={setSearchKeyword}
          placeHolder={'Search by Description'}
          sx={sharedStyles?.mv1}
        />
        <Box 
        className="ag-theme-quartz"
            sx={sharedStyles?.responsiveTableHeight}>
        
             <AgGridReact
                      pagination={true}
                      rowModelType={'infinite'}
                      paginationPageSize={perPage}
                      cacheBlockSize={perPage}
                      onGridReady={onGridReady}
                      rowHeight={60}
                      columnDefs={columnDefs()}
                      overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Loading...</span>'}
                      gridOptions={gridOptions}
                    />
                     
        </Box>
    </>
    )
}

export default CptTable
