import React, { useContext, useEffect, useState } from 'react';
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Paper,
    Typography,
    Box,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { aetnaDefaultData, aetnaSrDefaultData, paFormErrors, paOptionalSuffixText, paTextFieldHelperText, paValidDateText } from '../../../../../utils/staticData';
import { sharedStyles } from '../../../../../sharedStyles';
import toast from 'react-hot-toast';
import { handleChangePaRegexs } from '../../handleChangeRegexes';
import { styles } from '../../Aetna/styles';
import CommonNextButton from '../../components/CommonNextButton';
import TableHeader from '../../../../TableHeader';
import { getPatientDetails } from '../../components/commonFunctions';
import PageLoader from '../../../../loader/PageLoader';

const DefaultHsPatientForm = ({ setSelectedTab, formData, setFormData, noBack, importantFields, fieldsToHide, dateLimits, onceCompleted,showConditionCode,conditionCodes,prognosisCodes,showPrognosisCode}) => {
    const [errors, setErrors] = useState({});
    const [showAllFields, setShowAllFields] = useState(false);
    const [patientSelected, setPatientSelected] = useState(null);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        if (patientSelected?.id) {
            getPatientDetails(patientSelected, setLoader, setFormData, formData);
        }
    }, [patientSelected]);

    const handleChange = (field) => (event) => {
        const { value } = event.target;

        const regex = handleChangePaRegexs?.aetna?.[field];
        if (regex && value !== "" && !regex.test(value)) {
            return;
        }

        setFormData((prevFormData) => ({
            ...prevFormData,
            [field]: value,
        }));
    };

    const handleDateChange = (date) => {
        setFormData({
            ...formData,
            birthDate: date,
        });
    };

    const handleSubmit = (submit) => {
        let hasErrors = false;
        let allErrors = {};

        importantFields.forEach((field) => {
            if (!formData[field]) {
                allErrors[field] = paFormErrors[field] || `This field is required`;
                hasErrors = true;
            } else {
                allErrors[field] = false;
            }
        });
        if (hasErrors) {
            toast.error('Please fill in all required fields.');
            setErrors(allErrors);
        } else {
            setErrors({});
            submit && onceCompleted ? setSelectedTab(onceCompleted) :   setSelectedTab((prev) => prev + 1);
        }
    };

    const renderField = (field, label, type = 'text', helperText = '', maxLength = 60) => {
        const isRequired = importantFields.includes(field);

        return (
            <Grid item xs={12} md={6} key={field}>
                <TextField
                    fullWidth
                    label={label}
                    value={formData[field] || ''}
                    onChange={handleChange(field)}
                    inputProps={{
                        maxLength,
                    }}
                    required={isRequired}
                    error={!!errors[field]}
                    helperText={errors[field] || helperText}
                />
            </Grid>
        );
    };

    const renderDatePicker = (field, label) => {
        const isRequired = importantFields.includes(field);

        return (
            <Grid item xs={12} md={12} key={field}>
                <DatePicker
                    label={label}
                    sx={errors?.birthDate ? sharedStyles?.errorDatePickerStyles : sharedStyles?.defaultDatePickerStyles}
                    value={formData[field]}
                    onChange={handleDateChange}
                    minDate={dayjs(dateLimits?.min)}
                    maxDate={dayjs(dateLimits?.max)}
                    slotProps={{
                        textField: {
                            fullWidth: true,
                            required: isRequired,
                            helperText: paValidDateText,
                            error: !!errors[field],
                            inputProps: { readOnly: true },
                        },
                    }}
                />
            </Grid>
        );
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Paper elevation={3} sx={styles?.parentPaperContainer}>
                <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
                    Patient Information
                </Typography>

                <div style={sharedStyles?.mt10px}>
                    <TableHeader setFilterPatient={setPatientSelected} idNeeded handleCrossValue={() => setFormData(aetnaSrDefaultData?.patientInfo)} />
                </div>

                {loader ? (
                    <PageLoader />
                ) : (
                    <>
                        <RadioGroup
                            value={showAllFields}
                            onClick={() => setShowAllFields(!showAllFields)}
                        >
                            <FormControlLabel value={false} control={<Radio sx={sharedStyles?.muiCheckedThemeColor} />} label="Required Fields Only" />
                        </RadioGroup>

                        <Box sx={styles?.commonPaperContainer}>
                            <Grid container spacing={3}>
                                {/* Last Name */}
                                {renderField('lastName', 'Patient Last Name', 'text', paTextFieldHelperText, 60)}

                                {/* First Name */}
                                {renderField('firstName', 'Patient First Name', 'text', paTextFieldHelperText, 35)}

                                {/* Suffix */}
                                {!fieldsToHide?.includes('suffix') && showAllFields && renderField('suffix', 'Patient Suffix', 'text', paOptionalSuffixText, 10)}

                               

                                {/* Gender */}
                                {(showAllFields || importantFields.includes('genderCode')) && (
                                    <Grid item xs={12}>
                                        <FormControl fullWidth
                                        error={!!errors?.genderCode}
                                        required={importantFields.includes('genderCode')}
                                        >
                                            <InputLabel>Patient Gender</InputLabel>
                                            <Select
                                                value={formData.genderCode}
                                                label="Patient Gender"
                                                onChange={handleChange('genderCode')}
                                                required={importantFields.includes('genderCode')} // Mark as required if it's in importantFields
                                                
                                            >
                                                {aetnaDefaultData?.patientGenders?.map(({ value, label }) => (
                                                    <MenuItem key={value} value={value}>
                                                        {label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                )}
                                {/* Birth Date */}
                                {renderDatePicker('birthDate', 'Patient Date of Birth')}

                                {/* Relationship to Subscriber */}
                                {(showAllFields || importantFields.includes('subscriberRelationshipCode')) && (
                                    <Grid item xs={12}>
                                        <FormControl fullWidth 
                                         error={!!errors?.subscriberRelationshipCode}
                                        required={importantFields.includes('subscriberRelationshipCode')}
                                        >
                                            <InputLabel>Patient's Relationship to Subscriber</InputLabel>
                                            <Select
                                                value={formData.subscriberRelationshipCode}
                                                label="Patient's Relationship to Subscriber"
                                                onChange={handleChange('subscriberRelationshipCode')}
                                                required={importantFields.includes('subscriberRelationshipCode')} // Mark as required if it's in importantFields
                                               
                                            >
                                                {aetnaDefaultData?.patientRelationToSubscriber?.map(({ value, label }) => (
                                                    <MenuItem key={value} value={label}>
                                                        {label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                )}

                                {/* Condition Code for Patient */}
                                {((showAllFields && showConditionCode) || importantFields.includes('conditionCode')) && (
                                    <Grid item xs={12}>
                                        <FormControl fullWidth 
                                         error={!!errors?.conditionCode}
                                        required={importantFields.includes('conditionCode')}
                                        >
                                            <InputLabel>Patient Condition</InputLabel>
                                            <Select
                                                value={formData.conditionCode}
                                                label="Patient's Condition"
                                                onChange={handleChange('conditionCode')}
                                                required={importantFields.includes('conditionCode')} 
                                               
                                            >
                                                {conditionCodes?.length && conditionCodes?.map(({ value }) => (
                                                    <MenuItem key={value} value={value}>
                                                        {value}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                )}

                                {/* Prognosis Code for Patient */}
                                {((showAllFields && showPrognosisCode) || importantFields.includes('prognosisCode')) && (
                                    <Grid item xs={12}>
                                        <FormControl fullWidth 
                                         error={!!errors?.prognosisCode}
                                        required={importantFields.includes('prognosisCode')}
                                        >
                                            <InputLabel>Prognosis</InputLabel>
                                            <Select
                                                value={formData.prognosisCode}
                                                label="Prognosis"
                                                onChange={handleChange('prognosisCode')}
                                                required={importantFields.includes('prognosisCode')} 
                                               
                                            >
                                                {prognosisCodes?.length && prognosisCodes?.map(({ value }) => (
                                                    <MenuItem key={value} value={value}>
                                                        {value}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                )}

                                 

                            </Grid>
                        </Box>

                        <CommonNextButton handleSubmit={handleSubmit} setSelectedTab={setSelectedTab} noBack={noBack} onceCompleted={onceCompleted}/>
                    </>
                )}
            </Paper>
        </LocalizationProvider>
    );
};

export default DefaultHsPatientForm;
