import { Button } from '@mui/material';
import React from 'react'
import { Link } from 'react-router-dom';
import { styles } from './styles';

const ViewDetailsButton = (path) => {
    return (
        <div>
            <Button variant="contained" size='small' color="success">
                <Link style={styles?.link} to={path}>
                    View Details
                </Link>
            </Button>
        </div>
    );
};

export default ViewDetailsButton