import React, { useEffect, useState } from 'react'
import { Autocomplete, Box, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import { styles } from '../../Aetna/styles';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { admissionTypeCodes, MenuProps, requestTypeCodes, quantityTypeCodes, placeOfServiceCodes, admissionSourceCode } from '../../../../../utils/staticData';
import CommonNextButton from '../CommonNextButton';
import { sharedStyles } from '../../../../../sharedStyles';
import toast from 'react-hot-toast';
import { colors } from '../../../../../utils/colors';
import dayjs from 'dayjs';


const DefaultAmerigroupAdmissionInfo = ({ admissionInfo, setAdmissionInfo, setSelectedTab, onceCompleted, setFormData, formData }) => {
    const [fieldsErr, setFieldsErr] = useState(false)


    const handleSubmit = (submit) => {
        const { admissionDate, quantity, requestTypeCode, admissionTypeCode, admissionSourceCode, quantityTypeCode } = admissionInfo;
        if (admissionDate === '' || quantity === '' || requestTypeCode === '' || admissionTypeCode === '' || admissionSourceCode === '' || quantityTypeCode === '') {
            setFieldsErr(true)
            toast.error('Please enter all required information to continue.');
            return;
        } else {
            setFieldsErr(false)
            submit && onceCompleted ? setSelectedTab(onceCompleted) : setSelectedTab((prev) => prev + 1)
        }
    }



    return (
        <Paper elevation={3} sx={styles?.parentPaperContainer}>
            <Typography variant="h5" sx={sharedStyles?.mb4}>Admission Information</Typography>

            <Box sx={styles?.commonPaperContainer}>
                <Grid container spacing={2}>
                    <Grid xs={12} item sm={6} md={6} lg={6}>
                        <FormControl
                            fullWidth
                            error={fieldsErr && !admissionInfo?.admissionDate}
                        >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Admission Date *"
                                    onChange={(e) => setAdmissionInfo((prev) => ({
                                        ...prev,
                                        admissionDate: e ? dayjs(e) : null, // Save the date as a dayjs object
                                    }))}
                                    name={"admissionDate"}
                                    value={admissionInfo?.admissionDate ? dayjs(admissionInfo?.admissionDate) : null}  // Convert back to dayjs object
                                    variant="outlined"
                                    color="success"
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderColor: (!admissionInfo?.admissionDate && fieldsErr) ? colors?.errorRed : undefined,
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                borderColor: (!admissionInfo?.admissionDate && fieldsErr) ? colors?.errorRed : undefined,
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                borderColor: (!admissionInfo?.admissionDate && fieldsErr) ? colors?.errorRed : undefined,
                                            },
                                        },
                                        '& .MuiFormLabel-root': {
                                            color: (!admissionInfo?.admissionDate && fieldsErr) ? colors?.errorRed : undefined,
                                        },
                                        '& .MuiInputLabel-root.Mui-focused': {
                                            color: (!admissionInfo?.admissionDate && fieldsErr) ? colors?.errorRed : undefined,
                                        },
                                    }}
                                />
                            </LocalizationProvider>
                        </FormControl>

                    </Grid>


                    <Grid xs={12} item sm={6} md={6} lg={6}>
                        <FormControl fullWidth>
                            <TextField
                                inputProps={{
                                    maxLenght: 20,
                                    minLength: 2,
                                    style: { textTransform: 'capitalize' }
                                }}
                                onChange={(e) => setAdmissionInfo((prev) => ({ ...prev, quantity: e.target.value }))}
                                value={admissionInfo?.quantity}
                                name="quanitity"
                                label="Quantity *"
                                variant="outlined"
                                color="success"
                                error={admissionInfo?.quantity === '' && fieldsErr}
                            />
                        </FormControl>

                    </Grid>
                    <Grid xs={12} item sm={6} md={6} lg={6}>
                        <FormControl fullWidth sx={sharedStyles?.mb4}>
                            <InputLabel id='admissionTypeCode'>Admission Type *</InputLabel>
                            <Select fullWidth onChange={(e) => setAdmissionInfo((prev) => ({ ...prev, admissionTypeCode: e.target.value }))}
                                value={admissionInfo?.admissionTypeCode}
                                label='Quantity Type *'
                                variant='outlined'
                                color='success'
                                error={admissionInfo?.admissionTypeCode === '' && fieldsErr}
                                MenuProps={MenuProps}
                            >
                                {
                                    admissionTypeCodes.map((code, index) => (
                                        <MenuItem key={index} value={code.code}>{code.value}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
           
                    <Grid xs={12} item sm={6} md={6} lg={6}>
                        <FormControl fullWidth sx={sharedStyles?.mb4}>
                            <InputLabel id='admissionSource'>Admission Source*</InputLabel>
                            <Select fullWidth onChange={(e) => setAdmissionInfo((prev) => ({ ...prev, admissionSourceCode: e.target.value }))}
                                value={admissionInfo?.admissionSourceCode}
                                label='Admission Source *'
                                variant='outlined'
                                disabled={!admissionInfo?.admissionTypeCode}
                                color='success'
                                error={admissionInfo?.admissionSource === '' && fieldsErr}
                                MenuProps={MenuProps}
                            >
                                {
                                    admissionSourceCode?.map(({ values, containedIn, equalTo }) =>
                                        values?.filter(({ code }) => admissionInfo?.admissionTypeCode === equalTo || (containedIn && containedIn.includes(admissionInfo?.admissionTypeCode))).map(({ code, value }) => (
                                            <MenuItem key={code} value={code}>{value}</MenuItem>
                                        ))
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid xs={12} item sm={6} md={6} lg={6}>
                        <FormControl fullWidth>
                            <InputLabel id='requestTypeCode'>Request Type *</InputLabel>
                            <Select id='requestTypeCode' label='Request Type *' onChange={(e) => setAdmissionInfo((prev) => ({ ...prev, requestTypeCode: e.target.value }))}
                                value={admissionInfo?.requestTypeCode}
                                color='success'
                                error={(admissionInfo?.requestTypeCode === '' && fieldsErr)}
                                MenuProps={MenuProps}
                                sx={{
                                    '& .MuiGrid-root': {
                                        height: '30px'
                                    },
                                    color: admissionInfo?.requestTypeCode === '' && fieldsErr ? colors?.errorRed : undefined,
                                }}
                            >
                                {
                                    requestTypeCodes.map((code, index) => (
                                        <MenuItem key={index} value={code.code}>{code.value}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>

                    </Grid>



                    <Grid xs={12} item sm={6} md={6} lg={6}>
                        <FormControl fullWidth sx={sharedStyles?.mb4}>
                            <InputLabel id='quantityType'>Quantity Type*</InputLabel>
                            <Select fullWidth onChange={(e) => {
                                    console.log(e.target.value)
                                setAdmissionInfo((prev) => ({ ...prev, quantityTypeCode: e.target.value }))
    
                            }    
                        }
                                value={admissionInfo?.quantityTypeCode}
                                label='Quantity Type *'
                                variant='outlined'
                                color='success'
                                error={admissionInfo?.quantityTypeCode === '' && fieldsErr}
                                MenuProps={MenuProps}
                            >
                                {
                                    quantityTypeCodes.map((code, index) => (
                                        <MenuItem key={index} value={code.code}>{code.value}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>





                </Grid>
            </Box>
            <CommonNextButton handleSubmit={handleSubmit} setSelectedTab={setSelectedTab} onceCompleted={onceCompleted} />
        </Paper>
    )
}

export default DefaultAmerigroupAdmissionInfo
