import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@aws-amplify/ui-react/styles.css';
import { Auth0Provider } from '@auth0/auth0-react';
import { NotificationProvider } from './context/NotificationContext';



const root = ReactDOM.createRoot(document.getElementById('root'));
const apiUrl = process.env.REACT_APP_AUTH0_REDIRECTURI;

root.render(
  // <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: apiUrl,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: 'openid profile email offline_access'
      }}
      cacheLocation="localstorage" 
      useRefreshTokens={true}
    >
      <NotificationProvider>
        <App />
      </NotificationProvider>
    </Auth0Provider>,
  // </React.StrictMode>
);

reportWebVitals();
