
import React, { useContext } from 'react'
import { BcbsIllinoisContextProvider, BcbsIllinoisHsContext } from '../../../../../context/priorAuthorizationForms/BcbsIllinoisContext';
import PatientBcbsIllinoisHsForm from './PatientBcbsIllinoisHsForm';
import DatesInfoBcbsIllinoisHsForm from './DatesInfoBcbsIllinoisHsForm';
import DiagnosesBcbsIllinoisHsForm from './DiagnosesBcbsIllinoisHsForm';
import ProceduresBcbsIllinoisHsForm from './ProceduresBcbsIllinoisHsForm';
import ProviderNotesBcbsIllinoisHsForm from './ProviderNotesBcbsIllinoisHsForm';
import SubscriberBcbsIllinoisHsForm from './SubscriberBcbsIllinoisHsForm';
import ServicesTypeBcbsIllinoisHsForm from './ServicesTypeBcbsIllinoisHsForm';
import ServicesInfoBcbsHsForm from './ServicesInfoBcbsHsForm';
import RenderingProvidersBcbsIllinoisHsForm from './RenderingProvidersBcbsHsIllinoisHsForm';
import RequestingProviderBcbsbIllinoisHsForm from './RequestingProviderBcbsIllinoisHsForm';
import FormDetails from '../../components/FormDetails';

const BcbsIllinoisForms = ({title}) => {
    return (
        <BcbsIllinoisContextProvider>
            <AllForms  title={title}/>
        </BcbsIllinoisContextProvider>
    )
}

export default BcbsIllinoisForms

const AllForms = ({title}) => {
    const {
        selectedTab, setSelectedTab,
        patient,
        datesInfo,
        diagnosesInfo, 
        proceduresInfo,
        subscriber, 
        providerNotes, 
        serviceInfo,
        requestingProvider,
        renderingProviders,
        serviceType
        
    } = useContext(BcbsIllinoisHsContext)
    const forms = [
        <ServicesTypeBcbsIllinoisHsForm/>,
        <PatientBcbsIllinoisHsForm/>,
        <DatesInfoBcbsIllinoisHsForm/>,
        <DiagnosesBcbsIllinoisHsForm/>,
        <ProceduresBcbsIllinoisHsForm/>,
        <ProviderNotesBcbsIllinoisHsForm/>,
        <SubscriberBcbsIllinoisHsForm/>,
        <ServicesInfoBcbsHsForm/>,
        <RenderingProvidersBcbsIllinoisHsForm/>,
        <RequestingProviderBcbsbIllinoisHsForm/>,
        <FormDetails
        data={{
            patient,
            serviceTypeCode:serviceType?.serviceType,
            diagnose:diagnosesInfo,
            procedure:proceduresInfo,
            subscriberInfo:subscriber,
            providerNote:[providerNotes],
            serviceInfo:{...serviceInfo,
                serviceLevel:serviceInfo?.serviceLevel
            },
            requestingProvider,
            renderingProvider:renderingProviders,
            datesInfo,
        }}
        title={title ? `for ${title} (HS)` : ''}
        setSelectedTab={setSelectedTab}
        tabNums={{
            serviceTypeCode:0,
            patient:1,datesInfo:2,diagnosesInfo:3,proceduresInfo:4,providerNotes:5,subscriberInfo:6,serviceInfo:7,renderingProviders:8,requestingProvider:9}}
        onSubmit={()=>console.log('submit')}
        // isLoading={isLoading}
        />,
    ]
    return forms[selectedTab] || null;
};