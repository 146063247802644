import React from 'react';
import {
  Modal,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Grid
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { colors } from '../../utils/colors';
import { truncateText } from '../../utils/reusableFunctions';
import DefaultHoverButton from '../../components/buttons/DefaultHoverButton';
import GreenButton from '../../components/claims/GreenButton';
import DownloadIcon from '@mui/icons-material/Download';
import HorizontalBox from '../../components/shared/HorizontalBox';
import { styles } from './styles';
import { sharedStyles } from '../../sharedStyles';

const ImportedPatientsModal = ({ open, handleClose, data, handleDownload,path }) => {
  const headers = data?.length && data[0];
  const records = data?.length && data.slice(1);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="data-modal"
      aria-describedby="csv-data-view"
    >
      <Box sx={styles?.importedPatientModal}>
        <IconButton
          onClick={() => handleClose()}
          sx={styles?.importedPatientCrossIcon}
        >
          <Close />
        </IconButton>

        <TableContainer
          component={Paper}
          sx={styles?.importedPatientTable}
        >
          <Table stickyHeader sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                {headers?.length && headers?.map((header, index) => (
                  <TableCell
                    key={index}
                    sx={styles?.tableHeadCell}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>


            <TableBody>
              {records?.length && records.map((row, rowIndex) => (
                <TableRow
                  key={rowIndex}
                  sx={{
                    '&:nth-of-type(odd)': { backgroundColor:  row.some(cell => cell && cell.trim() !== '') ? colors?.tableGray : 'inherit' },
                    '&:hover': row.some(cell => cell && cell.trim() !== '')
                      ? { backgroundColor: colors?.tableHoveredGray }
                      : {},
                  }}
                >
                  {row.map((cell, cellIndex) => (
                    <TableCell key={cellIndex} sx={sharedStyles?.bb0}>
                      {cell && cell.trim() !== '' ? (
                        cell.split(' ').length > 20 ? (
                          <Tooltip title={cell} arrow>
                            <span style={styles?.tableToolTip}>
                              {truncateText(cell, 20)}
                            </span>
                          </Tooltip>
                        ) : (
                          cell
                        )
                      ) : (
                        <span>&nbsp;</span>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>

          </Table>
        </TableContainer>
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'flex-end'}
          alignItems={'end'}
          gap={1}
          mt={1}
        >
          <DefaultHoverButton
            variant='outlined'
            onClick={handleDownload}
          >
            <HorizontalBox>
              Download
              <DownloadIcon />
            </HorizontalBox>
          </DefaultHoverButton>
          <GreenButton
            onClick={() => handleClose(path || '')}
          >
            Done

          </GreenButton>
        </Box>
      </Box>
    </Modal>
  );
};
export default ImportedPatientsModal;