import React from 'react'
import AetnaHsForms from '../../../components/eligibility/paForms/hsForms/Aetna/AetnaHsForm'
import SilverSummitHealthPlanHsForms from '../../../components/eligibility/paForms/hsForms/SilverSummitHealthPlan/SilverSummitHealthPlanHsForms';
import SmplyHsForms from '../../../components/eligibility/paForms/hsForms/Smply/SmplyHsForms';
import AnthemGaHsForms from '../../../components/eligibility/paForms/hsForms/AnthemGa/AnthemGaForms';
import BcbsIllinoisForms from '../../../components/eligibility/paForms/hsForms/BcbsIllionois/BcbsIllionoisForm';

const HsForms = ({ selectedPayer }) => {
  const renderForm = () => {
    switch (selectedPayer) {
      case 'AETNA':
      case 'SUTTER HEALTH AETNA':
      case 'TEXAS HEALTH AETNA':
      case 'BANNER AETNA':
      case 'ALLINA HEALTH AETNA':
      case 'INNOVATION HEALTH':
        return <AetnaHsForms title={selectedPayer} />;
      case 'SILVERSUMMIT HEALTHPLAN':
        return <SilverSummitHealthPlanHsForms />;
      
      case "INNOVATION HEALTH":
      case "ANTHEM BCBS INDIANA":
      case "ANTHEM BCBS MISSOURI":
      case "ANTHEM BCBS NEVADA":
      case "ANTHEM BCBS NEW HAMPSHIRE":
      case 'ANTHEM BC NY':
      case "ANTHEM BCBS OHIO":
      case "HEALTHY BLUE SOUTH CAROLINA":
      case 'ANTHEM - GA':
      case "AMERIGROUP COMMUNITY CARE":
      case "ANTHEM BCBS VIRGINIA":
      case "ANTHEM BCBS WISCONSIN":
      case "HEALTHY BLUE MISSOURI":
      case "HEALTHY BLUE NEBRASKA":
      case "HEALTHY BLUE DUAL ADVANTAGE":
      case "HEALTHY BLUE NORTH CAROLINA":
      case "HEALTHY BLUE LOUISIANA":
      case "BLUE MEDICARE ADVANTAGE":
      case "ANTHEM BCBS NY":
      case "ANTHEM MAINEHEALTH":
      case "AMERIGROUP (CLAIMS)":
      case "WELLPOINT MARYLAND (pre 6-15-2024)":
      case "HIGHMARK BLUE CROSS BLUE SHIELD (NY) - MEDICAID AND CHP":
      case "CLEAR HEALTH ALLIANCE":
      case "COLORADO COMMUNITY HEALTH ALLIANCE":
      case "SUMMIT COMMUNITY CARE":
      case "ANTHEM BC CALIFORNIA":
      case "ANTHEM BCBS COLORADO":
      case "ANTHEM BCBS CONNECTICUT":
      case "ANTHEM BCBS KENTUCKY":
      case "ANTHEM BCBS MAINE":
      case "SIMPLY HEALTHCARE NEW":
      case "UNICARE":
        return <AnthemGaHsForms title={selectedPayer} />;
      case 'BCBS ILLINOIS':
      case 'BCBS MONTANA':
      case "BCBS NEW MEXICO":
        return <BcbsIllinoisForms title={selectedPayer} />;
      default:
        return <div>No forms available for the selected payer.</div>;
    }
  };

  return renderForm();
};

export default HsForms;