import axios from "axios";
import toast from "react-hot-toast";
import { failedRequestStr } from "../utils/staticData";
import { clearAllLocalData } from "../utils/localStorageFunctions";
import { logoutRedirection } from "../utils/reusableFunctions";

const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

export const handleLogout = () => {
      clearAllLocalData()
       const logoutUrl = `https://${auth0Domain}/v2/logout?client_id=${clientId}&returnTo=${encodeURIComponent(logoutRedirection())}`;
       window.location.href = logoutUrl;
}


export const userPostAxios = async (url, payload, contentType) => {
  try {
    const token = localStorage.getItem('authToken')
    const response = await axios.post(url, payload, {
      headers: {
        'Content-Type': contentType ?? 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });
    return response
  }
  catch (e) {
    toast.error(failedRequestStr)
    console.log(e)
    if(e?.response?.status === 401){
      handleLogout()
    }
  }
}

export const userPutAxios = async (url, payload) => {
  try {
    const token = localStorage.getItem('authToken')
    const response = await axios.put(url, payload, {
      headers: {
        'Content-Type': 'application/json',
         'Authorization': `Bearer ${token}`
      },
    });
    return response
  }
  catch (e) {
    toast.error(failedRequestStr)
    console.log(e)
    if(e?.response?.status === 401){
      handleLogout()
    }
  }
}

export const userGetAxios = async (url, onlyResponse, params) => {
  try {
    const token = localStorage.getItem('authToken')
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      params
    });
    return onlyResponse ? response : response?.data;
  } catch (error) {
    if(error?.response?.status === 401){
      handleLogout()
    }
    console.log('Error fetching data:', error);
  }
}

export const userDeleteAxios = async (url) => {
  try {
    const token = localStorage.getItem('authToken')
    const response = await axios.delete(url, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response
  } catch (error) {
    if(error?.response?.status === 401){
      handleLogout()
    }
    console.log(error, 'Failed to delete');
  }
}
