import React, { useContext, useState } from 'react'
import DefaultAetnaDiagnosesForm from '../../components/Aetna/DefaultAetnaDiagnosesForm'
import { getAllCDcodes } from '../../../../../services/pVerify'
import CdTable from '../../../../table/CdTable'
import ServiceInfoList from '../../components/ServicesInfoList'
import toast from 'react-hot-toast'
import { bcbsIllinoisHsQualifierCodes, paFormErrors } from '../../../../../utils/staticData'
import dayjs from 'dayjs'
import { BcbsIllinoisHsContext } from '../../../../../context/priorAuthorizationForms/BcbsIllinoisContext'

const DiagnosesBcbsIllinoisHsForm = () => {
    const { diagnosesInfo, setDiagnosesInfo, setSelectedTab, onceCompleted} = useContext(BcbsIllinoisHsContext)
    const [errors, setErrors] = useState([])
    const [defaultMinDate] = useState(dayjs("2002-1-1"))
    const [showAllFields, setShowAllFields] = useState(false);

    const handleSubmit = (submit) => {
        if(diagnosesInfo?.length){
            const inCompleteDiagnoses = diagnosesInfo?.filter(e => !e?.qualifierCode)
            if(inCompleteDiagnoses?.length){
                setErrors(diagnosesInfo?.map(e => {
                    return(
                        {
                            qualifierCode : e?.qualifierCode ? false : true
                        }
                    )
                }))
                toast?.error(paFormErrors?.propInfo)
                return
            }
            submit && onceCompleted ? setSelectedTab(onceCompleted) :    setSelectedTab(prev => prev + 1)
        }
        else{
            toast?.error(paFormErrors?.diagnosesImp)
        }
    }

    return (
            <DefaultAetnaDiagnosesForm
                fetchCodesApi={getAllCDcodes}
                limit={12}
                TableComponent={CdTable}
                ListComponent={(props) => (
                    <ServiceInfoList
                        serviceInfoArray={props.items}
                        onRemove={(id) => props.onRemove(id)}
                        showQualifierCode
                        errors={errors}
                        setServiceInfoArray={setDiagnosesInfo}
                        notReqFields={['date']}
                        externalShowAllFields={showAllFields}
                        setExternalShowAllFields={setShowAllFields}
                        qualifierCodes={bcbsIllinoisHsQualifierCodes}
                    />
                )}
                selectedItems={diagnosesInfo}
                setSelectedItems={setDiagnosesInfo}
                setSelectedTab={setSelectedTab}
                onSubmit={handleSubmit}
                onceCompleted={onceCompleted}
             
            />
    )
}

export default DiagnosesBcbsIllinoisHsForm